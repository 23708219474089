
  import React, { useState, useRef, useEffect } from "react";
  import { Link } from "react-router-dom";
  import { NavLink } from "react-router-dom";
  import "./style.css";

  import Footer from "../components/Footer";
  import recright from "../assets/Home/recright.png";
  import pii from "../assets/Blogs/pi.png";
  import pinkk from "../assets/Blogs/pinkk.png";
  import rectangle from "../assets/Blogs/rectangle.png";
  import rightarrow from "../assets/Blogs/rightarrow.png";
  import white from "../assets/Blogs/white.png";
  import Popup from "../components/popup.js";




  export const Blogs = ({ blogs }) => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const togglePopup = () => {
      setPopupOpen(!isPopupOpen);
    };


    const [email, setEmail] = useState("");

    const [showPopup, setShowPopup] = useState(false);
    const groupedBlogs = {};
    blogs.forEach((blog) => {
      if (!groupedBlogs[blog.blogCategory]) {
        groupedBlogs[blog.blogCategory] = [];
      }
      groupedBlogs[blog.blogCategory].push(blog);
    });


    const categoryOrder = [
      "KMS and RAG",
      "Computer Vision",
      "AI Training and AI Readiness",
      "Others",
    ];


    const sortedCategories = Object.keys(groupedBlogs).sort(
      (a, b) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b)
    );

    return (

      <div className="">
        <div className="flex  flex-col font-display justify-center items-start p-5 md:pt-56 md:pb-8 lg:pt-60 pt-72 bg-bloghome ">
          <div className="flex flex-col items-start md:flex-row md:items-end md:justify-between">
            <div className="md:pb-16  -translate-y-16 md:-translate-y-0 pb-5 lg:pb-28 md:pl-10 pl-1">
              <p className="text-[#666666] text-xs md:text-xs lg:text-xl 2xl:text-2xl font-medium mb-1 mt-0">
                Randomwalk Blogs
              </p>
              <span className="text-[#666666] text-2xl md:text-3xl lg:text-5xl 2xl:text-5xl font-semibold">

                All Of Our Thoughts, In
                <br />
              </span>
              <span className='text-[#666666] text-2xl md:text-3xl lg:text-5xl 2xl:text-5xl font-semibold  pb-10'>
                One Place
              </span>
            </div>
          </div>


          <div className="lg:pb-4 grid grid-cols-2 md:px-0 2xl:px-10 lg:px-0 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full md:gap-3  lg:gap-16 w-auto gap-4">

            {sortedCategories.map((category) => (
              <Link
                key={category}
                to={`/blogcategory/${category}`}

                className=" border font-bold border-gray-700 text-xs 2xl:text-xl text-[#666666] rounded-2xl lg:text-nowrap px-5 py-4 md:px- md:py-4 lg:py-5 lg:px-0 2xl:py-5 z-10 lg:text-base flex items-center justify-center p-3 w-full"

              >
                {category}
              </Link>
            ))}
          </div>
        </div>


        {sortedCategories.map((category) => (
          <div
            key={category}
            className="lg:pt-10 text-[#666666] flex justify-center pb-10 "
          >
            <div className="flex px-5 flex-wrap justify-center">
              <div className="category-container w-full">
                <div className="flex items-center lg:mb-5 mb-1 mt-5">
                  <Link
                    to={`/blogcategory/${category}`}
                    className="flex items-center"
                  >
                    <h2 className="text-sm text-[#666666] lg:text-xl mb-2 font-bold font-display">
                      {category}
                    </h2>
                    <img
                      src={pii}
                      alt="Category Icon"
                      className="ml-2 h-4 w-4 mb-2"
                    />
                  </Link>
                </div>

                <div className="card-grid text-[#666666]">
                  {groupedBlogs[category]
                    .sort((a, b) => new Date(b.blogDate) - new Date(a.blogDate))

                    .slice(0, 4)
                    .map((blog, index) => {
                      let displayIndex = index;
                      if (category === 'Computer Vision') {
                        if (index === 0) displayIndex = 0;
                        else if (index === 1) displayIndex = 2;
                        else if (index === 2) displayIndex = 1;
                        else if (index === 3) displayIndex = 3;
                      } else if (category === 'AI Training and AI Readiness') {
                        if (index === 0) displayIndex = 2;
                        else if (index === 1) displayIndex = 1;
                        else if (index === 2) displayIndex = 0;
                        else if (index === 3) displayIndex = 3;
                      } else if (category === 'Others') {
                        if (index === 0) displayIndex = 0;
                        else if (index === 1) displayIndex = 1;
                        else if (index === 2) displayIndex = 2;
                        else if (index === 3) displayIndex = 3;
                      }
                      return <BlogCard key={blog.id} blog={blog} displayIndex={displayIndex} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="mt-20 ">
          <Popup />
        </div>

      </div>
    );
  };

  const BlogCard = ({ blog, displayIndex }) => {
    const titleRef = useRef();
    const [titleLines, setTitleLines] = useState(1);

    useEffect(() => {
      if (titleRef.current) {
        const lineHeight = parseInt(window.getComputedStyle(titleRef.current).lineHeight);
        const titleHeight = titleRef.current.clientHeight;
        const lines = Math.ceil(titleHeight / lineHeight);
        setTitleLines(lines);
      }
    }, [titleRef.current]);

    const descriptionLines = 7 - titleLines; // Adjust this based on the requirement

    return (
      <div
        key={blog.id}
        className={`relative mb-0 ${displayIndex === 2 || displayIndex === 3 ? 'card-small' : 'card-big'}`}
      >
        <Link
          to={`/blogs/${blog.id}`}
          className='bg-white rounded-xl overflow-hidden drop-shadow-xl lg:drop-shadow-xl w-full max-w-[350px] h-[100%] flex flex-col relative'
        >

          

          <img
            className="object-cover h-56" // Set the height and width here
            src={`${blog.coverImg.url}`}
            alt={blog.blogTitle}

          />

          {(displayIndex === 2 || displayIndex === 3) && (

            <div className="absolute top-0 left-0 bg-black bg-opacity-45 rounded-2xl text-white p-2">
              <h3
                ref={titleRef}
                className="text-sm lg:text-sm font-display font-bold"
              >
                {blog.blogTitle}

              </h3>
            </div>
          )}
          {displayIndex !== 2 && displayIndex !== 3 && (

            <div className="p-4">
              <h3 ref={titleRef} className="text-lg font-display font-bold my-3">
                {blog.blogTitle}

              </h3>
              <p
                className='text-black font-display text-sm my-3 overflow-hidden'
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: descriptionLines,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {blog.blogDesc}
              </p>
            </div>
          )}
          <img
            src={displayIndex === 2 || displayIndex === 3 ? white : rightarrow}
            alt='Right Arrow'
            className='absolute bottom-4 right-4 w-4 h-4'
          />
        </Link>
      </div>
    );
  };
