import React from 'react';
import styles from '../styles/HeroSection.module.css';
import logo from '../assets/logo.svg';
import brandImage from '../assets/brand2.svg';
import brandImageMob from '../assets/hero-banner-mobile.png';
import { isMobile } from '../config.tsx';

// interface HeroSectionProps {
//   onOpenLeadForm: () => void;
// }

const HeroSection = ({ onOpenLeadForm }) => {
  const renderOnMobile = isMobile();
  return (
    <>
      <section className={styles.hero}>
        <img className={styles.resp} src={logo} />
        <div className={styles.content}>
          <img className={styles.logo} src={logo} alt='BrandCut Logo'  />
          <h1>
            Measure Your Brand <br />
            Sponsorship Strategy with <br />{' '}
            <span style={{ color: '#394CA7', fontWeight: 'bold' }}>AI Brand Detection</span>
          </h1>

          <button onClick={onOpenLeadForm} className={styles.button}>
            Give it a Try for FREE
          </button>
        </div>
        <div className={styles.image}>
          <img src={renderOnMobile ? brandImageMob : brandImage} alt='AI Brand Detection' />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
