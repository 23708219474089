
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { functions } from '../lib/appwrite';
import img1 from '../assets/img1.png';
import pinkoval from '../assets/pinkoval.png';
import ballimage from '../assets/ballimage.png';
// import test from "../assets/test.png";
import test from '../assets/test.svg';
// import mobiletest from "../assets/mobiletest.png";

import mobiletest from '../assets/mobiletest.svg';
import home1 from '../assets/home1.png';
import home2 from '../assets/home2.png';
import home3 from '../assets/home3.png';
import home4 from '../assets/home4.png';
import bubbleleft from '../assets/bubbleleft.png';
import bubbleright from '../assets/bubbleright.png';

import { Link as ScrollLink, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import lastpic from '../assets/lastpic.png';
import './style.css';
import icon1 from '../assets/icon1.png';
import icon2 from '../assets/icon2.png';
import icon3 from '../assets/icon3.png';
import icon4 from '../assets/icon4.png';
import icon5 from '../assets/icon5.png';
import icon6 from '../assets/icon6.png';
import icon7 from '../assets/icon7.png';
import icon8 from '../assets/icon8.png';
import next from '../assets/next.png';
import diamonds from '../assets/diamonds.png';
import diamonds2 from '../assets/diamonds2.png';
import diamonds3 from '../assets/diamonds3.png';
import cubetop from '../assets/cubetop.png';
import cubebottom from '../assets/cubebottom.png';
import { loadStripe } from '@stripe/stripe-js';
import bgg from '../assets/bgg.png';
import pink_purple1 from '../assets/pink_purple_left.png';
import cottoncandyy from '../assets/cottoncandy.png';
import bluecandyy from '../assets/bluecandy.png';
import pinkcandyy from '../assets/pinkcandy.png';
import { NavLink } from 'react-router-dom';
import recright from '../assets/doyou.png';
import whitee from '../assets/white.png';
import linkedinn from '../assets/linkedin.png';
import twitterr from '../assets/twitter.png';
import wavess from '../assets/waves.svg';
import SvgComponent from './SvgComponent';
import pink_purple_right from '../assets/pink_purple_right.png';
import pink_blue_right from '../assets/pink_blue_right.png';

import { Link } from 'react-scroll';
import logo from '../assets/logo.png';

import { Client, Databases, ID } from 'appwrite';
import Finall from '../assets/Final.png';
import stage11 from '../assets/stage1.png';
import stage22 from '../assets/stage2.png';
import stage33 from '../assets/stage3.png';
import stage44 from '../assets/stage4.png';
import stage55 from '../assets/stage5.png';
import stage66 from '../assets/stage6.png';
import stage77 from '../assets/stage7.png';
import { get } from 'react-scroll/modules/mixins/scroller';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import bgimg1 from '../assets/bgimg1.png';
import aibg from '../assets/aibg.png';
import formpic from '../assets/formpic.png';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

// const stripePromise = loadStripe(
//   "pk_test_51PZ9FpRsoIc9PRwXHbpzQazwJn4GwYoSF04p9YghsdogBg0uZIpMPwj19JKHeVxwKechjB79EzbeHeGazvztQgrQ00iBDiq6sW"
// );

// const ProductDisplay = () => (
//   <section>
//     {/* <div className="product">
//       <img
//         src="./Mask group.svg"
//         alt="AI Readiness Assessment tool Cover"
//       />
//       <div className="description">
//       <h3>Ai Readiness Assessment Tool</h3>
//       <h5>$500.00</h5>
//       </div>
//     </div> */}
//     <form action="/create-checkout-session" method="POST">
//       <button type="submit">Checkout</button>
//     </form>
//   </section>
// );

const Homepage = () => {
  const [animate, setAnimate] = useState(false);
  const [country, setCountry] = useState('');
  // const res = getipaddress();
  // console.log(res);

  const getIpAddress = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    console.log('getipaddress -> data', data);
    //get country code
    const response2 = await fetch(
      `https://geo.ipify.org/api/v2/country?apiKey=at_5EMDuyXpWbueG9hj9nfzGLi2yDTbC&ipAddress=${data.ip}`
    );
    const data2 = await response2.json();
    console.log('getipaddress -> data2', data2);
    return data2.location.country;
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimate(true);
          observer.disconnect();
        }
      });
    });

    observer.observe(document.querySelector('.animate-section'));

    return () => observer.disconnect();
  }, []);

  // useEffect(() => {
  //   const fetchIpAddress = async () => {
  //     try {
  //       const ipAddress = await getipaddress();
  //       console.log(ipAddress);
  //       setCountry(ipAddress.location.country);
  //     } catch (error) {
  //       console.error("Error fetching IP address:", error);
  //     }
  //   };

  //   fetchIpAddress();
  // }, []);

  useEffect(() => {
    if (animate) {
      const ballRef = document.querySelector('.ball-image');
      const textElements = document.querySelectorAll('.text-animate');
      const pinkOvalRef = document.querySelector('.pink-oval');

      if (ballRef) {
        ballRef.style.animation = 'slide-right 2.5s ease-out forwards';
      }

      if (textElements) {
        textElements.forEach((element, index) => {
          element.style.animation = `slide-up 2.5s ease-out forwards`;
          element.style.animationDelay = `${index * 0}s`; // Adjusted delay for text
        });
      }

      if (pinkOvalRef) {
        pinkOvalRef.style.animationDelay = `${textElements.length * 0.5}s`;
      }
    }
  }, [animate]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');

  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsFormOpen(false);
  };
 
  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
   
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    }
   
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID9,
        ID.unique(),
        {
          name,
          designation,
          companyName,
          phoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );

      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const signupRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #signup
    if (window.location.hash === '#signup') {
      signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  const createCheckoutSession = async () => {
    try {
      const response = await functions.createExecution(
        'CreateStripeCheckoutSession',
        JSON.stringify({
          email: email,
          name: firstName,
          phone: userphoneNumber,
          line1: street,
          city: city,
          state: state,
          zip: zip,
          country: country,
          academyxi: false,
        })
      );
      const redirectUrl = JSON.parse(response.responseBody).redirectUrl;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
    createCheckoutSession();
  };

  const [isFormOpen, setIsFormOpen] = useState(false);

  const [userName, setUserName] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [userphoneNumber, setusersphoneNumber] = useState('');
  const [userDesignation, setUserDesignation] = useState('');
  const [userCompanyName, setUserCompanyName] = useState('');

  const [gstNumber, setGstNumber] = useState('');
  const [street, setstreet] = useState('');

  const [step, setStep] = useState(1);
  const [showStep1Errors, setShowStep1Errors] = useState(false);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  // const [country, setCountry] = useState('');
  const [zip, setzip] = useState('');

  const [showStep2Errors, setShowStep2Errors] = useState(false);
  

  const handleOpenForm = () => {
    setIsFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setStep(1);
  };

  // };
  const validateStep1 = () => {
    let isValid = true;
    const newErrors = {};

    // Validate User Name
    if (userName.trim() === '') {
      newErrors.userName = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(userName)) {
      newErrors.userName = 'Name can only contain alphabetic characters and spaces.';
      isValid = false;
    }

    // Validate Last Name
    if (lastName.trim() === '') {
      newErrors.lastName = 'Required*';
      isValid = false;
    }

    if (userphoneNumber.trim() === '') {
      newErrors.userphoneNumber = 'Required*';
      isValid = false;
    }

    // Validate Designation
    if (userDesignation.trim() === '') {
      newErrors.userDesignation = 'Required*';
      isValid = false;
    }

    // Validate Company Name
    if (userCompanyName.trim() === '') {
      newErrors.userCompanyName = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(userCompanyName)) {
      newErrors.userCompanyName = 'Company Name can only contain alphabetic characters and spaces.';
      isValid = false;
    }

    // Validate Email
    if (email.trim() === '') {
      newErrors.email = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      newErrors.email = 'Email Id is invalid.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateStep2 = () => {
    let isValid = true; // Initialize isValid to true
    const newErrors = {};

    // Validate Street
    if (street.trim() === '') {
      newErrors.street = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z0-9\s]+$/.test(street)) {
      // Assuming street can have alphanumeric characters and spaces
      newErrors.street = 'Street can only contain alphanumeric characters and spaces.';
      isValid = false;
    }

    // Validate City
    if (city.trim() === '') {
      newErrors.city = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(city)) {
      newErrors.city = 'City can only contain alphabetic characters and spaces.';
      isValid = false;
    }

    // Validate State
    if (state.trim() === '') {
      newErrors.state = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(state)) {
      newErrors.state = 'State can only contain alphabetic characters and spaces.';
      isValid = false;
    }

    // Validate Country
    if (country.trim() === '') {
      newErrors.country = 'Required*';
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(country)) {
      newErrors.country = 'Country can only contain alphabetic characters and spaces.';
      isValid = false;
    }

    // Validate ZIP Code
    if (zip.trim() === '') {
      newErrors.zip = 'Required*';
      isValid = false;
    } else if (zip.length < 3) {
      newErrors.zip = 'Enter a valid Postal code';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid; // Return the validation status
  };

  useEffect(() => {
    if (showStep1Errors) {
      validateStep1();
    }
    if (showStep2Errors) {
      validateStep2();
    }
  }, [
    userName, //used as first name for payment page
    lastName, //used as last name for payment page
    userphoneNumber,
    userDesignation,
    userCompanyName,
    email,
    gstNumber,
    street,
    city,
    state,
    country,
    zip,
    showStep1Errors,
    showStep2Errors,
  ]);
  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent form from submitting immediately
  
    setShowStep2Errors(true);
  
    // Validate both steps
    const isStep1Valid = validateStep1();
    const isStep2Valid = validateStep2();
  
    // Only proceed if both steps are valid
    if (isStep1Valid && isStep2Valid) {
      try {
        // Create document in the database
        const document = await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID10, // Collection ID
          ID.unique(), // Unique document ID
          {
            userName,
            lastName,
            userphoneNumber,
            userDesignation,
            userCompanyName,
            email,
            gstNumber,
            street,
            city,
            state,
            country,
            zip,
          }
        );
  
        console.log('Document created successfully:', document);
  
        // Close the form and reset the step
        setIsFormOpen(false);
        setStep(1);
  
        // After document creation, handle the checkout session
        await createCheckoutSession();
      } catch (error) {
        console.error('Error creating document:', error);
      }
    } else {
      console.log('Form validation failed. Please check all fields.');
      // Optionally, you can add a visual feedback here, like showing an error message to the user
    }
  };
  // const handleFormSubmit = async (event) => {
  //   event.preventDefault(); // Prevent form from submitting immediately

  //   setShowStep2Errors(true);
  //   //if form is valid,
  //   try {
  //     // Create document in the database
  //     const document = await databases.createDocument(
  //       process.env.REACT_APP_DATABASE_ID,
  //       process.env.REACT_APP_COLLECTION_ID10, // Collection ID
  //       ID.unique(), // Unique document ID
  //       {
  //         userName,
  //         lastName,
  //         userphoneNumber,
  //         userDesignation,
  //         userCompanyName,
  //         email,
  //         gstNumber,
  //         street,
  //         city,
  //         state,
  //         country,
  //         zip,
  //       }
  //     );

  //     console.log('Document created successfully:', document);

  //     // Close the form and reset the step
  //     setIsFormOpen(false);
  //     setStep(1);

  //     // After document creation, handle the checkout session
  //     await createCheckoutSession();
  //   } catch (error) {
  //     console.error('Error creating document:', error);
  //   }
  // };

  const handleNextClick = () => {
    setShowStep1Errors(true);

    if (validateStep1()) {
      setStep(2);
    }
  };















  
  // Define a function that handles both actions
  const handleClick = async (event) => {
    await handleFormSubmit(event);
  };
  const paragraphs = [
    'Many companies struggle to understand their readiness for AI adoption and their overall digital maturity.',
    'Did you know that over 77% of companies globally are actively exploring how to incorporate AI into their business?',
    "While there's immense enthusiasm for AI, many companies lack a clear understanding of their AI readiness.",
  ];

  const [currentParagraph, setCurrentParagraph] = useState(0);
  const [previousParagraph, setPreviousParagraph] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPreviousParagraph(currentParagraph);
      setCurrentParagraph((prev) => (prev + 1) % paragraphs.length);
    }, 3000); // Adjust timing as needed

    return () => clearInterval(interval);
  }, [currentParagraph]);

  const rectangles = [
    {
      text: 'Does your leadership have a clear vision for AI, and are they committed to its success?',
      imgSrc: icon1,
    },
    {
      text: 'Are your business goals aligned with potential AI applications?',
      imgSrc: icon2,
    },
    {
      text: 'Do you have the necessary data infrastructure and talent pool to support AI initiatives?',
      imgSrc: icon3,
    },
    {
      text: 'Does your company foster a culture of innovation and experimentation?',
      imgSrc: icon4,
    },
    {
      text: 'How digitally mature are your various departments?',
      imgSrc: icon5,
    },
  ];

  let startXRef = useRef(0); // Reference to store initial X position on mouse down
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startScrollLeft, setStartScrollLeft] = useState(0);
  const [autoScrollInterval, setAutoScrollInterval] = useState(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    startXRef.current = e.pageX;
    setStartScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const container = scrollContainerRef.current;
    const distance = e.pageX - startXRef.current;
    const maxScrollDistance = container.scrollWidth - container.clientWidth;

    let newScrollLeft = startScrollLeft - distance;

    // Implement circular scrolling behavior
    if (newScrollLeft < 0) {
      newScrollLeft = maxScrollDistance + newScrollLeft; // Wrap around to the end
    } else if (newScrollLeft > maxScrollDistance) {
      newScrollLeft = newScrollLeft - maxScrollDistance; // Wrap around to the beginning
    }

    container.scrollLeft = newScrollLeft;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [servicesOpen, setServicesOpen] = useState(false);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
    setCompanyOpen(false);
    setResourcesOpen(false);
  };

  const toggleCompany = () => {
    setCompanyOpen(!companyOpen);
    setServicesOpen(false);
    setResourcesOpen(false);
  };

  const toggleResources = () => {
    setResourcesOpen(!resourcesOpen);
    setServicesOpen(false);
    setCompanyOpen(false);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  };
  const startAutoScroll = () => {
    const intervalId = setInterval(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: 400, // Adjust the scroll amount as needed
          behavior: 'smooth',
        });
      }
    }, 3000); // Automatically scroll every 3 seconds
    setAutoScrollInterval(intervalId);
  };
  const stopAutoScroll = () => {
    if (autoScrollInterval) {
      clearInterval(autoScrollInterval);
      setAutoScrollInterval(null);
    }
  };
  const handleScrollRight = () => {
    stopAutoScroll();
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 400,
        behavior: 'smooth',
      });
    }
  };
  const handleScrollLeft = () => {
    stopAutoScroll(); // Stop auto-scrolling when manual scrolling starts
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -400, // Adjust the scroll amount as needed
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const options = {
      threshold: 0.5, // Adjust this value to determine when a section is considered in view
    };

    const observer = new IntersectionObserver(handleScroll, options);

    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  const [isScrollingRight, setIsScrollingRight] = useState(false);
  const [isScrollingLeft, setIsScrollingLeft] = useState(false);
  let scrollIntervalRef = useRef(null);
  let prevScrollY = window.scrollY;

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    const container = scrollContainerRef.current;
    observer.observe(container);

    return () => {
      observer.unobserve(container);
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const currentScrollY = window.scrollY;
      if (entry.isIntersecting) {
        if (currentScrollY > prevScrollY) {
          startAutoScrollRight(scrollContainerRef.current);
        } else {
          startAutoScrollLeft(scrollContainerRef.current);
        }
      }
      prevScrollY = currentScrollY;
    });
  };

  const startAutoScrollRight = (container) => {
    clearInterval(scrollIntervalRef.current);
    const scrollStep = 2; // Adjust scroll step as needed
    const maxScrollDistance = container.scrollWidth - container.clientWidth;

    scrollIntervalRef.current = setInterval(() => {
      if (container.scrollLeft >= maxScrollDistance) {
        clearInterval(scrollIntervalRef.current);
      } else {
        container.scrollLeft += scrollStep;
      }
    }, 10); // Adjust interval as needed
  };

  const startAutoScrollLeft = (container) => {
    clearInterval(scrollIntervalRef.current);
    const scrollStep = 2; // Adjust scroll step as needed

    scrollIntervalRef.current = setInterval(() => {
      if (container.scrollLeft <= 0) {
        clearInterval(scrollIntervalRef.current);
      } else {
        container.scrollLeft -= scrollStep;
      }
    }, 10); // Adjust interval as needed
  };

  const scrollToNext = () => {
    const container = scrollContainerRef.current;
    container.scrollLeft += 360; // Adjust based on your card width
  };

  const scrollToPrevious = () => {
    const container = scrollContainerRef.current;
    container.scrollLeft -= 360; // Adjust based on your card width
  };

  const [activeLink, setActiveLink] = useState('t1'); // Default active link

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Calculate the top offset of each section
      const t1Section = document.getElementById('t1').offsetTop;
      const t2Section = document.getElementById('t2').offsetTop;
      const t3Section = document.getElementById('t3').offsetTop;
      const t4Section = document.getElementById('t4').offsetTop;

      // Determine which section is in view based on scroll position
      if (scrollPosition >= t1Section && scrollPosition < t2Section - 100) {
        setActiveLink('t1');
        setMenuOpen(false);
      } else if (scrollPosition >= t2Section - 100 && scrollPosition < t3Section - 100) {
        setActiveLink('t2');
        setMenuOpen(false);
      } else if (scrollPosition >= t3Section - 100 && scrollPosition < t4Section - 100) {
        setActiveLink('t3');
        setMenuOpen(false);
      } else if (scrollPosition >= t4Section - 100) {
        setActiveLink('t4');
        setMenuOpen(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const currentElement = observerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        // settrigger everytime
        // setIsVisible(entry.isIntersecting);

        // set trigger once
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          observer.unobserve(currentElement); // Stop observing after it has triggered
        }
      },
      {
        root: null, // Observe relative to the viewport
        rootMargin: '0px',
        threshold: 0.3, // Trigger when 10% of the target is visible
      }
    );

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, []);

  return (
    <>
    
      <div className='Main '>
        <div id='t1'>
          <div className='bg-gradient-custom p-4 flex justify-center items-center  '>
            <div className='relative w-screen h-[600px] md:h-[800px] lg:h-screen overflow-hidden '>
              <div className='absolute top-40  md:top-60   lg:top-44 left-4 2xl:left-32 2xl:top-64  md:left-20 lg:left-20  text-container text-white -space-y-0 z-10'>
                <p className='  bg-custom-gradient33 text-thin font-display  text-white text-2xl mt-[-70px] rounded-lg px-2'>
                  AI Readiness & Digital Maturity Assessment Index
                </p>
                <br />
                <p className='top-paragraph text-2xl lg:text-6xl md:text-4xl 2xl:text-7xl  font-display font-bold'>
                  Don't just dream of <br />
                  <span className='gradient-text'>AI transformation,</span> <br />
                  make it a reality.
                </p>
                <br />
                <button
                  className='border border-1 font-display border-[#C77DF0] text-[#C77DF0] rounded-full px-4 py-2 -z-10 mt-4'
                  onClick={() =>
                    document.getElementById('learn-more-section').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }
                >
                  Learn More
                </button>
              </div>
            
              <div>
                <SvgComponent />
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row md:items-center bg-white -mb-4'>
            <div className='flex-1 mb-5 md:mb-0 '>
              <img
                src={img1}
                alt='Description of the image'
                className=' max-w-xs md:max-w-sm lg:max-w-lg 2xl:max-w-2xl h:auto '
              />
            </div>

            <div className='flex-2 md:ml-5 flex flex-col items-center -mt-4'>
              <div
                className='mt-2 flex flex-col items-center space-y-8 max-w-md text-md md:text-md lg:text-lg 2xl:text-2xl sm:mb-20 md:max-w-lg lg:max-w-3xl 2xl:max-w-6xl relative font-display'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <div
                  className='p-8 rounded w-full h-40 relative overflow-hidden '
                  style={{
                    backgroundImage: `url(${pink_blue_right})`,
                    backgroundSize: ' cover',
                    backgroundPosition: '50% 65%',
                  }}
                >
                  {paragraphs.map((paragraph, index) => (
                    <div
                      key={index}
                      className={`transition-all text-[#666666] duration-300 w-full text-center text-md md:text-md lg:max-w-lg lg:text-lg 2xl:max-w-4xl font-display 2xl:text-2xl mt-[6px] font-medium ${
                        index === currentParagraph ? 'animate-slide-up' : 'animationhidden'
                      }`}
                    >
                      {paragraph}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='flex justify-center items-center w-full  bg-white'>
            <div className='mt-0 flex flex-col items-center space-y-8   max-w-sm text-sm md:text-lg lg:text-lg 2xl:text-2xl sm:mb-20 md:max-w-2xl lg:max-w-4xl 2xl:max-w-4xl relative font-display'>
              <div className='text-center text-[#666666]  pb-10 md:pb-0 text-sm max-w-sm md:text-lg md:max-w-2xl lg:max-w-4xl mt-16 md:mt-0  2xl:max-w-8xl lg:text-lg 2xl:text-2xl font-medium leading-relaxed '>
                Without a clear <span className='bg-[#3E59B7]  text-white'>understanding of your current state,</span>{' '}
                setting realistic goals for digital transformation and{' '}
                <span className='bg-[#3E59B7] text-white '>AI adoption becomes a guessing game.</span> This can lead to
                wasted resources, failed implementations, and missed opportunities.
              </div>
            </div>
          </div>
        </div>












        

        <div id='t2'>
          <div className='bg-[#ECEEF8]'>
            <div className='items-center justify-center relative overflow-hidden animate-section'>
              <div
                className='absolute ball-image bg-[#3E59B7] w-20 h-20   md:w-28 md:h-28 lg:w-32 lg:h-32  2xl: rounded-full z-20 top-12 md:top-20 lg:mt-0 2xl:top-32'
                style={{ left: 0, transition: 'none' }}
              ></div>

              <div className='mt-60 md:mt-[340px] lg:mt-[380px] 2xl:mt-[400px] text-center text-animate pb-28'>
                <div className='text-3xl md:text-5xl lg:text-6xl lg:mb-3 md:mb-3 2xl:text-8xl text-[#3E59B7] font-display font-bold'>
                  Don't take a blind leap
                </div>
                <div className='text-base md:text-lg lg:text-2xl 2xl:text-4xl font-display text-center text-[#3E59B7] font-medium'>
                  The Bridge You Need to Turn AI <br />
                  Ambition into Strategy.
                </div>
              </div>

              <img
                src={pinkoval}
                alt='Description'
                className='w-96 h-16 md:w-auto md:h-24 absolute left-0 -ml-10 md:-ml-20 lg:-ml-16 2xl:-ml-20 top-32 md:top-48 lg:top-52 2xl:top-64 z-10 [#FF94CE]-oval'
              />
            </div>
          </div>

          <div className='relative bg-[#FFFFFF] pb-10 overflow-hidden'>
            <div id='learn-more-section'></div>
            <img
              src={bubbleleft}
              alt='Bubble'
              className='w-24 h-auto md:w-28 md:h-auto lg:w-32 lg:h-auto 2xl:w-52 2xl:h-auto absolute left-0 top-40 md:top-28 2xl:top-48 -ml-10  md:-ml-7 lg:-ml-10 2xl:-ml-16 transform'
            />
            {/* <img src={cottoncandyy} alt="Bubble" className="w-24 h-auto" /> */}

            <img
              src={bubbleright}
              alt='Bubble'
              className='hidden md:block md:w-28 md:h-auto lg:w-28 lg:h-auto  2xl:w-52 2xl:h-auto absolute right-0 top-0 md:top-24 2xl:top-44  lg:top-20 md:-mr-12 lg:-mr-12 2xl:-mr-24 transform'
            />

            <div className='flex flex-col items-center justify-center text-center relative z-10'>
              <div className='text-[#230C5A] text-2xl md:text-[45px] lg:text-[60px] 2xl:text-8xl mt-10 md:mt-20 lg:mt-29 2xl:mt-40 font-display font-bold'>
                Discover your AI readiness
              </div>

              <div className='text-lg text-[#3E59B7] md:text-3xl lg:text-4xl 2xl:text-5xl mt-4 md:mt-20 lg:mt-28 font-display'>
                In just <span className='text-[#3E59B7] font-bold'>15 minutes</span> <br /> using our
              </div>

              <div className='text-lg text-[#C62B6D] mt-3 md:text-4xl lg:text-[45px] 2xl:text-7xl font-semibold md:font-medium  md:mt-5 font-display'>
                AI Readiness & Digital Maturity <br />
                Assessment Index
              </div>

              <img
                src={test}
                alt='Image description'
                className=' hidden md:block mt-10  lg:mt-16 2xl:mt-20 md:w-9/12 md:h-9/12 md:px-5'
              />

              <img src={mobiletest} alt='Image description' className=' md:hidden block  w-80 h-auto mt-10' />

              {/* <img src={bluecandyy} alt="Bubble" className="w-24 h-auto" />
        <img src={pinkcandyy} alt="Bubble" className="w-24 h-auto" />
  */}

              <div className='mt-10'>
                {/* <form action="/create-checkout-session" method="POST"> */}
                <button
                  type='submit'
                  className='group bg-[#C62B6D] text-button px-10 lg:px-12 py-4 rounded-xl text-white font-display border font-semibold text-sm lg:text-lg shadow-lg flex items-center justify-center hover:bg-[#FFFFFF] hover:text-black  hover:border-black'
                  onClick={handleButtonClick}
                >
                  Schedule Consultation
                  <span className='ml-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      className='w-6 h-5 group-hover:text-black'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 12h20m-7-7 7 7-7 7' />
                    </svg>
                  </span>
                </button>
                {/* </form> */}
              </div>

              <img
                src={diamonds3}
                alt='Decorative Image'
                className='hidden md:block absolute  lg:w-auto lg:h-auto  md:-right-4 md:-bottom-[64px] '
              />
            </div>
          </div>

          <div className='relative flex flex-col md:flex-row bg-custom-gradient22 w-full text-center overflow-hidden '>
            <img
              src={diamonds}
              alt='Top Left Image'
              className='hidden md:block absolute top-0 md:w-36 md:h-auto md:-left-10 lg:w-44 lg:h-auto lg:-left-12 2xl:w-48 2xl:h-auto 2xl:-left-12'
            />
            <div className='flex z-10 max-md:flex-col w-full lg:justify-end 2xl:justify-end 2xl:pl-0 lg:mt-10'>
              <div className='flex flex-col'>
                <div className='text-white  text-xl max-w-80 ml-8 mt-20 text-left md:text-left md:text-xl lg:text-3xl 2xl:text-4xl lg:mt-24 2xl:mt-20 lg:mr-10 md:max-w-lg lg:max-w-lg 2xl:max-w-xl font-bold font-display'>
                  Our assessment tool addresses this critical gap by helping you answer essential questions
                </div>
                <div className='flex space-x-2 ml-8 mt-5'>
                  <button
                    className='flex items-center justify-center w-10 h-10 bg-[#C62B6D] text-[#3E1D9E] font-bold rounded-full border border-none cursor-pointer'
                    onClick={handleScrollLeft}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='#FFFFFF'
                      className='w-7 h-5'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M22 12H2m7-7-7 7 7 7' />
                    </svg>
                  </button>
                  <button
                    className='flex items-center justify-center w-10 h-10 bg-[#C62B6D] text-[#3E1D9E] font-bold rounded-full border border-none cursor-pointer'
                    onClick={handleScrollRight}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='#FFFFFF'
                      className='w-7 h-5'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 12h20m-7-7 7 7-7 7' />
                    </svg>
                  </button>
                </div>
              </div>
              <div
                className='scroll-container overflow-x-auto whitespace-nowrap flex w-full md:max-w-[700px]  2xl:max-w-[1250px] p-2 md:p-5'
                ref={scrollContainerRef}
              >
                {rectangles.map((rectangle, index) => (
                  <div
                    key={index}
                    className='flex flex-col bg-white p-4 pr-6 pl-4 rounded-2xl shadow-lg m-2 min-w-[350px] md:min-w-[450px] h-44 md:h-[200px] lg:h-[250px] 2xl:h-[300px] 2xl:min-w-[550px] mb-4 md:mb-6'
                  >
                    <img
                      src={rectangle.imgSrc}
                      alt={`Image ${index + 1}`}
                      className='ml-2 mt-4 w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20'
                    />

                    <div className='text-sm ml-2 mt-3 lg:text-lg 2xl:text-2xl text-[#666666] md:ml-4 md:mt-5 text-left font-display font-normal text-wrap min-w-[250px] md:min-w-[350px] lg:min-w-[430px] 2xl:min-w-[500px]'>
                      {rectangle.text}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <img src={cubebottom} alt='Decorative Image' className=' md:hidden block w-20 -mt-16 h-auto ' />
          </div>

          <div className='flex bg-[#CDCBFF] flex-col  md:flex-row space-y-0 lg:space-x-0.5 space-x-0 md:space-y-0 md:space-x-1 w-full md:h-auto md:w-auto md:mt-16 p-0.5 lg:mt-16 2xl:mt-0'>
            <div className='flex flex-col items-start border bg-white border-[#CDCBFF]  p-4 md:p-10 lg:p-12 2xl:p-20 flex-1 z-10'>
              <img src={icon6} alt='Image 1' className='w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20' />
              <div className='mt-3 ml-2 md:mt-2 text-left'>
                <div className=' text-sm md:text-sm lg:text-base 2xl:text-2xl font-normal text-[#666666] font-display'>
                  Identifies gaps between your AI aspirations and your current digital capabilities.
                </div>
              </div>
            </div>

            <div className='flex flex-col items-start border bg-white border-[#CDCBFF] p-4 md:p-10 lg:p-12 2xl:p-20 flex-1 z-10'>
              <img src={icon7} alt='Image 2' className='w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20' />
              <div className='mt-3 ml-2 md:mt-2 text-left'>
                <div className='font-normal text-[#666666] text-sm md:text-sm lg:text-base 2xl:text-2xl font-display'>
                  Provides actionable insights on how to bridge those gaps with a prioritized roadmap.
                </div>
              </div>
            </div>

            <div className='flex flex-col items-start border bg-white border-[#CDCBFF] p-4 md:p-10 lg:p-12 2xl:p-20 flex-1 z-10'>
              <img src={icon8} alt='Image 3' className='w-12 h-12 md:w-12 md:h-12 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20' />
              <div className='mt-3 ml-2 md:mt-2 text-left'>
                <div className='font-normal text-[#666666] text-sm md:text-sm lg:text-base 2xl:text-2xl font-display'>
                  Empowers leaders to make data-driven decisions about AI adoption and digital transformation
                  initiatives.
                </div>
              </div>
            </div>
          </div>

          <div className='bg-none text-[#666666] py-10'>
            <div className='flex flex-col items-center justify-center text-center'>
              <div className='text-custom-text mt-10'>
                <p className='text-xl md:text-3xl font-display font-semibold lg:text-5xl 2xl:text-6xl mb-2 lg:mb-4'>
                  AI Readiness & Digital Maturity
                </p>
                <p className='text-xl md:text-3xl font-display font-semibold lg:text-5xl 2xl:text-6xl'>
                  Assessment Index
                </p>
              </div>

              <div className='mt-10'>
                {/* <form action="/create-checkout-session" method="POST"> */}

                {/* <button type="submit" className="group bg-[#C62B6D] text-white px-10 lg:px-12 py-4 rounded-xl font-display border font-semibold text-sm lg:text-lg shadow-lg flex items-center justify-center hover:text-black hover:border-black hover:bg-white" onClick={handleButtonClick}>
                Get Started
                <span className="ml-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-5 group-hover:text-white"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 12h20m-7-7 7 7-7 7" />
                  </svg>
                </span>
              </button> */}
                <Link to='t4' smooth={true} duration={500}>
                  <button className='group bg-[#C62B6D] text-white px-10 lg:px-12 py-4 rounded-xl font-display border font-semibold text-sm lg:text-lg shadow-lg flex items-center justify-center hover:text-black hover:border-black hover:bg-white'>
                    Get Started{' '}
                    <span className='ml-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        className='w-6 h-5 group-hover:text-black'
                      >
                        <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 12h20m-7-7 7 7-7 7' />
                      </svg>
                    </span>
                  </button>
                </Link>
                {/* </form> */}
              </div>
            </div>
            <div className='text-white mt-6 text-center'>
              <p className='text-lg font-display lg:text-xl 2xl:text-2xl mb-4 justify-center bg-custom-gradient44 rounded-full px-4  inline-block'>
                <span className='font-bold'>$500 USD</span> for an organisation assessment
              </p>
            </div>
            <div className='flex flex-col md:flex-row items-center md:space-x-24 lg:space-x-20  xl:space-x-32 mt-10 px-4 lg:px-0'>
              <div className='flex-none  text-left   items-center  md:text-left mb-10 md:mb-24 md:ml-3 lg:ml-8 xl:ml-32 2xl:ml-96'>
                <p className='text-lg  text-custom-text text-center font-display font-normal lg:text-md mb-2 w-24 border border-custom-border rounded-2xl p-2 '>
                  Part 1
                </p>
                <p className='text-lg text-custom-text font-display font-bold lg:text-3xl 2xl:text-4xl mb-2'>
                  AI Readiness Assessment
                </p>
                <p className='text-lg text-custom-text font-display font-bold lg:text-xl 2xl:text-2xl mb-4'>
                  For Business Leaders
                </p>
                <p className='text-md text-custom-text font-display max-w-sm md:max-w-xs lg:max-w-md 2xl:text-xl font-medium lg:text-lg mb-4'>
                  Complete a self-assessment designed to analyse your organisation's overall readiness for adopting AI
                  technologies across key dimensions.
                </p>
              </div>
              <div className='flex-none  text-left text-center md:mb-24 lg:mb-24 2xl:mb-32'>
                <p className='text-lg text-custom-text font-display font-normal lg:text-md mb-3 w-24 border border-custom-border rounded-2xl p-2 '>
                  Part 2
                </p>
                <p className='text-lg  text-left text-custom-text font-display font-bold lg:text-3xl 2xl:text-4xl mb-2'>
                  Digital Maturity Assessment
                </p>
                <p className='text-lg text-left text-custom-text font-display font-bold lg:text-xl 2xl:text-2xl mb-4'>
                  For Department Heads
                </p>
                <p className='text-md  text-left text-custom-text font-display md:max-w-xs max-w-sm lg:max-w-md 2xl:text-xl font-medium lg:text-lg mb-4'>
                  Department heads complete assessment for their department. Tailored reports for each department,
                  detailing digital maturity.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div ref={observerRef} className='relative max-md:hidden' id='scroll-view'>
          <img src={Finall} alt='Final' className='lg:w-full lg:h-full' />
          <img
            src={stage11}
            alt='stages'
            className={`absolute 2xl:top-[630px] md:top-[230px] top-[70px] w-32 h-auto translate-y-32 lg:w-auto lg:top-[316px] lg:translate-y-9 ${
              isVisible ? 'fadeInDelay1 fadeInFromBottom' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage22}
            alt='stages'
            className={`absolute lg:bottom-[2px] md:bottom-[36px] md:left-[90px] 2xl:bottom-[72px] lg:translate-x-80 lg:left-36 -bottom-[15px] left-12 lg:w-56 translate-x-80 w-20 h-auto ${
              isVisible ? ' fadeInDelay2 fadeInFromTop ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage33}
            alt='stages'
            className={`absolute md:top-[185px] md:left-[180px] lg:top-[208px] 2xl:top-[500px] 2xl:left-[400px] lg:left-64 lg:w-auto w-32 h-auto top-[49px] left-24 ${
              isVisible ? ' fadeInDelay3 fadeInFromBottom ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage44}
            alt='stages'
            className={`absolute md:bottom-[84px] md:left-[300px] lg:bottom-30 lg:left-96 2xl:bottom-[225px] 2xl:left-[650px] lg:w-64 lg:translate-x-40 w-20 h-auto  bottom-[12px] left-36    2xl:ml-44 lg:ml-32${
              isVisible ? ' fadeInDelay4 fadeInFromTop ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage55}
            alt='stages'
            className={`absolute md:top-[98px] md:right-[250px] 2xl:top-[190px] 2xl:right-[550px] lg:top-[90px] lg:right-80 lg:w-auto lg:translate-x-60 top-[36px] right-28 lg:w-56 w-32  h-auto ${
              isVisible ? 'fadeInDelay5 fadeInFromBottom ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage66}
            alt='stages'
            className={`absolute md:bottom-[152px] md:right-[100px]  2xl:bottom-[430px] 2xl:right-[300px] lg:bottom-[200px] lg:right-36 lg:w-56 w-32 h-auto bottom-8 right-8 ${
              isVisible ? ' fadeInDelay6 fadeInFromTop ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
          <img
            src={stage77}
            alt='stages'
            className={`absolute md:-top-[6px] 2xl:-top-[30px] 2xl:-right-[16px] lg:-top-[68px] lg:-right-8 w-32 lg:w-auto h-auto -top-[18px] -right-2 ${
              isVisible ? ' fadeInDelay7 fadeInFromBottom ' : ''
            }`}
            style={{ transform: 'scale(0.65)', visibility: '' }}
          />
        </div>

        <div id='t3'>
          <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4  text-[#666666] p-4 md:p-10 lg:p-10   2xl:p-32 mx-auto'>
            <div className=' rounded-xl text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-display  font-bold p-4 2xl:w-full h-56 md:mx-0 lg:h-64'>
              <p>Benefits</p>
              <p>of the tool</p>
              <div className='mt-10'>
                <button
                  className='bg-[#C62B6D] text-white px-13 lg:px-10 w-3/4 md:w-4/5 2xl:w-1/2 py-4 rounded-full border-2 border-none  font-display font-semibold text-sm lg:text-xl flex items-center justify-center'
                  onClick={() =>
                    document.getElementById('t4').scrollIntoView({
                      behavior: 'smooth',
                    })
                  }
                >
                  Get Started <span className='ml-2'>&#8594;</span>
                </button>
                {/* <Link to="t4" smooth={true} duration={500}>
              <button className="bg-[#C62B6D] text-white px-13 lg:px-10 w-3/4 md:w-4/5 2xl:w-1/2 py-4 rounded-full border-2 border-none font-display font-semibold text-sm lg:text-xl flex items-center justify-center">
                Get Started <span className="ml-2">&#8594;</span>
              </button>
            </Link> */}
              </div>
            </div>
            <div className='border border border-custom rounded-xl p-4 h-56 md:h-72   lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-xl 2xl:text-2xl font-semibold font-display'>
                Develop a Strong AI Vision & Strategy
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display '>
                Align AI initiatives with our long-term business goals.
              </p>
            </div>

            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72  lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-xl 2xl:text-2xl font-semibold font-display'>
                Identify Strengths & Weaknesses
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Uncover areas where you excel and areas needing improvement.
              </p>
            </div>
            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72  lg:w-80 xl:w-96 2xl:w-full  flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-lg 2xl:text-2xl font-semibold font-display '>
                Bridge Skills and Gaps
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Develop or acquire the necessary talent to effectively utilize AI.
              </p>
            </div>
            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72 lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-lg 2xl:text-2xl font-semibold font-display'>
                Make Data-Driven Decisions
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Invest in AI solutions that deliver a strong return on investment (ROI).
              </p>
            </div>

            <div className='border border border-custom rounded-xl p-4 h-56 md:h-72  lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-xl 2xl:text-2xl font-semibold font-display'>
                Optimize Resource Allocation
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                {' '}
                Allocate resources effectively to bridge digital maturity gaps and support AI initiatives.
              </p>
            </div>
            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72  lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-lg 2xl:text-2xl font-semibold font-display'>
                Uncover Departmental Digital Maturity
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Identify areas within their departments that are digitally advanced or lagging.
              </p>
            </div>
            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72 lg:w-80 xl:w-96 2xl:w-full flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-lg 2xl:text-2xl font-semibold font-display'>
                Craft a Transformation Roadmap
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Develop a clear plan for each department to achieve higher digital maturity levels.
              </p>
            </div>
            <div className='border border border-custom rounded-xl p-4  h-56 md:h-72 2xl:w-full  xl:w-96 lg:w-80 flex flex-col items-center justify-center text-lg text-center hover:bg-[#C62B6D] hover:text-white hover:border-2'>
              <p className='mb-5 text-lg md:text-md lg:text-lg 2xl:text-2xl font-semibold font-display'>
                Prepare for AI Integration{' '}
              </p>
              <p className='text-md md:text-sm  lg:text-md 2xl:text-xl font-display  '>
                Ensure their departments have the digital foundation necessary to leverage AI successfully.
              </p>
            </div>
          </div>
        </div>

        <div id='t4'>
          <div className=' hidden md:block bg-custom-color p-4 flex justify-center items-center relative w-full '>
            <div className='relative w-full'>
              <div className='absolute lg:top-10 lg:left-10 m-4 md:-mt-2 md:top-0'>
                <p className=' m-2 lg:text-2xl md:text-sm font-semibold 2xl:text-4xl font-display text-white p-2 rounded'>
                  Gain a comprehensive analysis of
                  <br /> your organization's capabilities and <br /> a roadmap for successful AI <br /> integration
                  across all departments.
                </p>
                {/* <form action="/create-checkout-session" method="POST">
              <button type="submit" className="bg-white text-button border border-black px-10 lg:px-10 md:ml-2 md:w-56 lg:w-1/2 lg:py-4 py-2 rounded-full font-display font-semibold text-sm lg:text-lg shadow-lg flex items-center justify-center md:mb-2 lg:mb-4 hover:bg-hovercolor hover:text-white hover:border-white">
                Get Started 
                <span className="ml-2"> <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-5 group-hover:text-white"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2 12h20m-7-7 7 7-7 7" />
                </svg></span>
              </button>
            </form> */}
                <button
                  className='bg-white text-button border md:-mt-1 text-[#666666] border-black px-10 lg:px-9 md:ml-2 md:w-56 lg:w-1/2 lg:py-4 py-2 rounded-full font-display font-semibold text-sm lg:text-md shadow-lg inline items-center  md:mb-2 lg:mb-4 hover:bg-hovercolor hover:text-black hover:border-white mb-4'
                  // onClick={createCheckoutSession}
                  onClick={handleOpenForm}
                >
                  Take Assessment Now
                </button>

                <p className='bg-custom-gradient44 rounded-full text-white text-xs font-display md:text-md lg:text-lg 2xl:text-2xl mb-2 md:mb-4 ml-2 px-2 py-0 md:px-2 md:py-0 lg:px-2 lg:py-0 inline-block'>
                  <span className='font-bold'>$500 USD</span> for an organisation assessment
                </p>

                <p className=' lg:text-md text-xs  ml-0 font-light md:-mt-2  2xl:text-lg font-display text-white p-2 rounded'>
                  Great for: companies serious about AI adoption and
                  <br /> wanting a data-driven roadmap for success
                </p>
              </div>
              <img src={lastpic} alt='Description of the image' className='md:w-full h-full lg:h-auto' />
              <div className='absolute lg:right-1   md:right-0 md:top-0 lg:top-3.5  w-96 lg:w-6/12 h-auto'>
                <img src={test} alt='Description of the image' className='w-full h-full object-cover' />
              </div>
            </div>
          </div>
          <div className='bg-custom-color p-4 flex flex-col items-center h-[500px] max-md:h-[1100px] md:hidden md:flex-row md:justify-center relative'>
            <div className='relative w-full text-left z-10'>
              <div className='relative w-full text-left'>
                <div className='lg:text-2xl md:text-sm text-md font-semibold font-display text-white p-2 rounded'>
                  Gain a comprehensive analysis of
                  <br /> your organization's capabilities and <br /> a roadmap for successful AI <br /> integration
                  across all departments.
                </div>
                <div className='flex justify-start mt-4'>
                  {/* <form action="/create-checkout-session" method="POST"> */}
                  <button
                    onClick={handleOpenForm}
                    type='submit'
                    className='bg-white text-button px-6 py-2 lg:px-10 lg:py-4 w-full sm:w-48 md:w-56 lg:w-1/2 rounded-full font-display font-semibold text-xs sm:text-sm lg:text-lg shadow-lg flex items-center justify-center'
                  >
                    Take Assessment Now<span className='ml-2'>&#8594;</span>
                  </button>
                  {/* </form> */}
                </div>
                <div className='bg-custom-gradient44 rounded-full text-base font-display text-white lg:text-lg 2xl:text-2xl lg:mb-4 md:mb-2 mb-2 mt-4 text-sm py-1 px-2 text-left'>
                  <span className='font-bold'>$500 USD</span> for an organization assessment
                </div>
                <p className='lg:text-md text-sm font-light font-display text-white mb-12 rounded'>
                  Great for: companies serious about AI adoption and
                  <br /> wanting a data-driven roadmap for success
                </p>
              </div>
              <div className='absolute md:relative w-full flex justify-center mt-4 md:mt-0'>
                <img src={mobiletest} alt='Description of the image' className='' />
              </div>
            </div>
          </div>
        </div>
        {isFormOpen && (
          <div className='fixed md:hidden overflow-auto font-display z-50 py-10 px-20 inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
            <div className='bg-white rounded-lg max-w-4xl w-full max-h-full flex flex-col relative overflow-auto'>
              <button
                className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                onClick={closeModal}
                id='form-close-button'
              >
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='4' d='M6 18L18 6M6 6l12 12'></path>
                </svg>
              </button>
              <div
                className='bg-[#7B53EC] flex flex-col items-center justify-center rounded-t-lg bg-no-repeat bg-cover w-full'
                style={{
                  backgroundImage: `url(${bgimg1})`,
                  backgroundPosition: 'center',
                }}
              >
                <div className='flex flex-col items-center justify-center px-10 pt-10'>
                  <div className='text-sm font-normal pb-2 text-white text-center'>Discover your AI readiness</div>
                  <div className='text-xs font-semibold text-white text-center'>
                    AI Readiness & Digital Maturity Assessment Index
                  </div>
                </div>
                <img src={aibg} alt='Description of image' className='w-10/12 h-auto mt-4' />
              </div>
              <div className='relative p-4 flex-1'>
                <div className='flex justify-center mb-4'>
                  <button className='w-8 h-8 bg-[#040330] text-[#3E1D9E] font-bold rounded-full flex items-center justify-center cursor-default'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='#FFFFFF'
                      className='w-4 h-4'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 3v18m-7-7l7 7 7-7' />
                    </svg>
                  </button>
                </div>

                <div className='text-sm font-normal mb-5  lg:text-xl md:text-sm text-black text-center'>
                  Please fill the form to <br /> proceed payment
                </div>

                <form>
                  {step === 1 && (
                    <>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          First Name{' '}
                          {showStep1Errors && errors.userName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='userName'
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          className='w-full px-3 py-2 border border-gray-300 rounded text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          Last Name
                          {showStep1Errors && errors.lastName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.lastName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='name'
                          value={lastName}
                          onChange={(e) => setlastName(e.target.value)}
                          className='w-full px-3 py-2 border rounded text-black'
                          required
                        />
                      </div>

                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm'>
                          Email Address
                          {showStep1Errors && errors.email && (
                            <span className='text-red-500 text-sm ml-2'>{errors.email}</span>
                          )}
                        </label>
                        <input
                          type='email'
                          name='email'
                          value={email}
                          onChange={(e) => setEmailId(e.target.value)}
                          className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='text-gray-700 text-sm'>
                          Phone Number
                          {showStep1Errors && errors.userphoneNumber && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userphoneNumber}</span>
                          )}
                        </label>
                        <PhoneInput
                          country={'in'}
                          required
                          value={userphoneNumber}
                          onChange={setusersphoneNumber}
                          inputClass='custom-phone-input2'
                          buttonClass='border rounded'
                          dropdownClass='border rounded'
                          className='text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm'>
                          Job Title
                          {showStep1Errors && errors.userDesignation && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userDesignation}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          required
                          name='userDesignation'
                          value={userDesignation}
                          onChange={(e) => setUserDesignation(e.target.value)}
                          className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700 text-sm'>
                          Company Name
                          {showStep1Errors && errors.userCompanyName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userCompanyName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='userCompanyName'
                          value={userCompanyName}
                          onChange={(e) => setUserCompanyName(e.target.value)}
                          className='w-full px-3 py-1 border border-gray-300 rounded text-black'
                        />
                      </div>
                      <div className='flex justify-start '>
                        <button
                          type='button'
                          className='px-4 py-2 text-xs bg-[#19188B] text-white rounded-full'
                          onClick={handleNextClick}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <h1 className='text-gray-700  text-sm mb-2 '>Billing Address:</h1>
                      <div className='mb-2'>
                        <label className='block text-gray-700 text-sm'>
                          Street
                          {showStep2Errors && errors.street && (
                            <span className='text-red-500 text-sm ml-2'>{errors.street}</span>
                          )}
                        </label>
                        <textarea
                          name='street'
                          value={street}
                          onChange={(e) => setstreet(e.target.value)}
                          className='w-full px-3 py-0 border rounded text-black'
                        ></textarea>
                      </div>
                      <div className='flex gap-4 mb-4'>
                        <div className='flex-1'>
                          <label className='block text-gray-700 text-sm '>
                            City
                            {showStep2Errors && errors.city && (
                              <span className='text-red-500 text-sm ml-2'>{errors.city}</span>
                            )}
                          </label>
                          <input
                            type='text'
                            name='city'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className='w-full px-3 py-0 border rounded text-black'
                          />
                        </div>
                        <div className='flex-1'>
                          <label className='block text-gray-700 text-sm'>
                            State
                            {showStep2Errors && errors.state && (
                              <span className='text-red-500 text-sm ml-2'>{errors.state}</span>
                            )}
                          </label>
                          <input
                            type='text'
                            name='state'
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            className='w-full px-3 py-0 border rounded text-black'
                          />
                        </div>
                      </div>

                      <div className='flex gap-4 mb-4'>
                        <div className='flex-1'>
                          <label className='block text-gray-700 text-sm'>Country</label>
                          <select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                          >
                            <option value=''>--Select--</option>
                            <option value='AF'>Afghanistan</option>
                            <option value='AX'>Åland Islands</option>
                            <option value='AL'>Albania</option>
                            <option value='DZ'>Algeria</option>
                            <option value='AS'>American Samoa</option>
                            <option value='AD'>Andorra</option>
                            <option value='AO'>Angola</option>
                            <option value='AI'>Anguilla</option>
                            <option value='AQ'>Antarctica</option>
                            <option value='AG'>Antigua and Barbuda</option>
                            <option value='AR'>Argentina</option>
                            <option value='AM'>Armenia</option>
                            <option value='AW'>Aruba</option>
                            <option value='AU'>Australia</option>
                            <option value='AT'>Austria</option>
                            <option value='AZ'>Azerbaijan</option>
                            <option value='BS'>Bahamas</option>
                            <option value='BH'>Bahrain</option>
                            <option value='BD'>Bangladesh</option>
                            <option value='BB'>Barbados</option>
                            <option value='BY'>Belarus</option>
                            <option value='BE'>Belgium</option>
                            <option value='BZ'>Belize</option>
                            <option value='BJ'>Benin</option>
                            <option value='BM'>Bermuda</option>
                            <option value='BT'>Bhutan</option>
                            <option value='BO'>Bolivia, Plurinational State of</option>
                            <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                            <option value='BA'>Bosnia and Herzegovina</option>
                            <option value='BW'>Botswana</option>
                            <option value='BV'>Bouvet Island</option>
                            <option value='BR'>Brazil</option>
                            <option value='IO'>British Indian Ocean Territory</option>
                            <option value='BN'>Brunei Darussalam</option>
                            <option value='BG'>Bulgaria</option>
                            <option value='BF'>Burkina Faso</option>
                            <option value='BI'>Burundi</option>
                            <option value='KH'>Cambodia</option>
                            <option value='CM'>Cameroon</option>
                            <option value='CA'>Canada</option>
                            <option value='CV'>Cape Verde</option>
                            <option value='KY'>Cayman Islands</option>
                            <option value='CF'>Central African Republic</option>
                            <option value='TD'>Chad</option>
                            <option value='CL'>Chile</option>
                            <option value='CN'>China</option>
                            <option value='CX'>Christmas Island</option>
                            <option value='CC'>Cocos (Keeling) Islands</option>
                            <option value='CO'>Colombia</option>
                            <option value='KM'>Comoros</option>
                            <option value='CG'>Congo</option>
                            <option value='CD'>Congo, the Democratic Republic of the</option>
                            <option value='CK'>Cook Islands</option>
                            <option value='CR'>Costa Rica</option>
                            <option value='CI'>Côte d'Ivoire</option>
                            <option value='HR'>Croatia</option>
                            <option value='CU'>Cuba</option>
                            <option value='CW'>Curaçao</option>
                            <option value='CY'>Cyprus</option>
                            <option value='CZ'>Czech Republic</option>
                            <option value='DK'>Denmark</option>
                            <option value='DJ'>Djibouti</option>
                            <option value='DM'>Dominica</option>
                            <option value='DO'>Dominican Republic</option>
                            <option value='EC'>Ecuador</option>
                            <option value='EG'>Egypt</option>
                            <option value='SV'>El Salvador</option>
                            <option value='GQ'>Equatorial Guinea</option>
                            <option value='ER'>Eritrea</option>
                            <option value='EE'>Estonia</option>
                            <option value='ET'>Ethiopia</option>
                            <option value='FK'>Falkland Islands (Malvinas)</option>
                            <option value='FO'>Faroe Islands</option>
                            <option value='FJ'>Fiji</option>
                            <option value='FI'>Finland</option>
                            <option value='FR'>France</option>
                            <option value='GF'>French Guiana</option>
                            <option value='PF'>French Polynesia</option>
                            <option value='TF'>French Southern Territories</option>
                            <option value='GA'>Gabon</option>
                            <option value='GM'>Gambia</option>
                            <option value='GE'>Georgia</option>
                            <option value='DE'>Germany</option>
                            <option value='GH'>Ghana</option>
                            <option value='GI'>Gibraltar</option>
                            <option value='GR'>Greece</option>
                            <option value='GL'>Greenland</option>
                            <option value='GD'>Grenada</option>
                            <option value='GP'>Guadeloupe</option>
                            <option value='GU'>Guam</option>
                            <option value='GT'>Guatemala</option>
                            <option value='GG'>Guernsey</option>
                            <option value='GN'>Guinea</option>
                            <option value='GW'>Guinea-Bissau</option>
                            <option value='GY'>Guyana</option>
                            <option value='HT'>Haiti</option>
                            <option value='HM'>Heard Island and McDonald Islands</option>
                            <option value='VA'>Holy See (Vatican City State)</option>
                            <option value='HN'>Honduras</option>
                            <option value='HK'>Hong Kong</option>
                            <option value='HU'>Hungary</option>
                            <option value='IS'>Iceland</option>
                            <option value='IN'>India</option>
                            <option value='ID'>Indonesia</option>
                            <option value='IR'>Iran, Islamic Republic of</option>
                            <option value='IQ'>Iraq</option>
                            <option value='IE'>Ireland</option>
                            <option value='IM'>Isle of Man</option>
                            <option value='IL'>Israel</option>
                            <option value='IT'>Italy</option>
                            <option value='JM'>Jamaica</option>
                            <option value='JP'>Japan</option>
                            <option value='JE'>Jersey</option>
                            <option value='JO'>Jordan</option>
                            <option value='KZ'>Kazakhstan</option>
                            <option value='KE'>Kenya</option>
                            <option value='KI'>Kiribati</option>
                            <option value='KP'>Korea, Democratic People's Republic of</option>
                            <option value='KR'>Korea, Republic of</option>
                            <option value='KW'>Kuwait</option>
                            <option value='KG'>Kyrgyzstan</option>
                            <option value='LA'>Lao People's Democratic Republic</option>
                            <option value='LV'>Latvia</option>
                            <option value='LB'>Lebanon</option>
                            <option value='LS'>Lesotho</option>
                            <option value='LR'>Liberia</option>
                            <option value='LY'>Libya</option>
                            <option value='LI'>Liechtenstein</option>
                            <option value='LT'>Lithuania</option>
                            <option value='LU'>Luxembourg</option>
                            <option value='MO'>Macao</option>
                            <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                            <option value='MG'>Madagascar</option>
                            <option value='MW'>Malawi</option>
                            <option value='MY'>Malaysia</option>
                            <option value='MV'>Maldives</option>
                            <option value='ML'>Mali</option>
                            <option value='MT'>Malta</option>
                            <option value='MH'>Marshall Islands</option>
                            <option value='MQ'>Martinique</option>
                            <option value='MR'>Mauritania</option>
                            <option value='MU'>Mauritius</option>
                            <option value='YT'>Mayotte</option>
                            <option value='MX'>Mexico</option>
                            <option value='FM'>Micronesia, Federated States of</option>
                            <option value='MD'>Moldova, Republic of</option>
                            <option value='MC'>Monaco</option>
                            <option value='MN'>Mongolia</option>
                            <option value='ME'>Montenegro</option>
                            <option value='MS'>Montserrat</option>
                            <option value='MA'>Morocco</option>
                            <option value='MZ'>Mozambique</option>
                            <option value='MM'>Myanmar</option>
                            <option value='NA'>Namibia</option>
                            <option value='NR'>Nauru</option>
                            <option value='NP'>Nepal</option>
                            <option value='NL'>Netherlands</option>
                            <option value='NC'>New Caledonia</option>
                            <option value='NZ'>New Zealand</option>
                            <option value='NI'>Nicaragua</option>
                            <option value='NE'>Niger</option>
                            <option value='NG'>Nigeria</option>
                            <option value='NU'>Niue</option>
                            <option value='NF'>Norfolk Island</option>
                            <option value='MP'>Northern Mariana Islands</option>
                            <option value='NO'>Norway</option>
                            <option value='OM'>Oman</option>
                            <option value='PK'>Pakistan</option>
                            <option value='PW'>Palau</option>
                            <option value='PS'>Palestinian Territory, Occupied</option>
                            <option value='PA'>Panama</option>
                            <option value='PG'>Papua New Guinea</option>
                            <option value='PY'>Paraguay</option>
                            <option value='PE'>Peru</option>
                            <option value='PH'>Philippines</option>
                            <option value='PN'>Pitcairn</option>
                            <option value='PL'>Poland</option>
                            <option value='PT'>Portugal</option>
                            <option value='PR'>Puerto Rico</option>
                            <option value='QA'>Qatar</option>
                            <option value='RE'>Réunion</option>
                            <option value='RO'>Romania</option>
                            <option value='RU'>Russian Federation</option>
                            <option value='RW'>Rwanda</option>
                            <option value='BL'>Saint Barthélemy</option>
                            <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value='KN'>Saint Kitts and Nevis</option>
                            <option value='LC'>Saint Lucia</option>
                            <option value='MF'>Saint Martin (French part)</option>
                            <option value='PM'>Saint Pierre and Miquelon</option>
                            <option value='VC'>Saint Vincent and the Grenadines</option>
                            <option value='WS'>Samoa</option>
                            <option value='SM'>San Marino</option>
                            <option value='ST'>Sao Tome and Principe</option>
                            <option value='SA'>Saudi Arabia</option>
                            <option value='SN'>Senegal</option>
                            <option value='RS'>Serbia</option>
                            <option value='SC'>Seychelles</option>
                            <option value='SL'>Sierra Leone</option>
                            <option value='SG'>Singapore</option>
                            <option value='SX'>Sint Maarten (Dutch part)</option>
                            <option value='SK'>Slovakia</option>
                            <option value='SI'>Slovenia</option>
                            <option value='SB'>Solomon Islands</option>
                            <option value='SO'>Somalia</option>
                            <option value='ZA'>South Africa</option>
                            <option value='GS'>South Georgia and the South Sandwich Islands</option>
                            <option value='SS'>South Sudan</option>
                            <option value='ES'>Spain</option>
                            <option value='LK'>Sri Lanka</option>
                            <option value='SD'>Sudan</option>
                            <option value='SR'>Suriname</option>
                            <option value='SJ'>Svalbard and Jan Mayen</option>
                            <option value='SZ'>Swaziland</option>
                            <option value='SE'>Sweden</option>
                            <option value='CH'>Switzerland</option>
                            <option value='SY'>Syrian Arab Republic</option>
                            <option value='TW'>Taiwan, Province of China</option>
                            <option value='TJ'>Tajikistan</option>
                            <option value='TZ'>Tanzania, United Republic of</option>
                            <option value='TH'>Thailand</option>
                            <option value='TL'>Timor-Leste</option>
                            <option value='TG'>Togo</option>
                            <option value='TK'>Tokelau</option>
                            <option value='TO'>Tonga</option>
                            <option value='TT'>Trinidad and Tobago</option>
                            <option value='TN'>Tunisia</option>
                            <option value='TR'>Turkey</option>
                            <option value='TM'>Turkmenistan</option>
                            <option value='TC'>Turks and Caicos Islands</option>
                            <option value='TV'>Tuvalu</option>
                            <option value='UG'>Uganda</option>
                            <option value='UA'>Ukraine</option>
                            <option value='AE'>United Arab Emirates</option>
                            <option value='GB'>United Kingdom</option>
                            <option value='US'>United States</option>
                            <option value='UM'>United States Minor Outlying Islands</option>
                            <option value='UY'>Uruguay</option>
                            <option value='UZ'>Uzbekistan</option>
                            <option value='VU'>Vanuatu</option>
                            <option value='VE'>Venezuela, Bolivarian Republic of</option>
                            <option value='VN'>Viet Nam</option>
                            <option value='VG'>Virgin Islands, British</option>
                            <option value='VI'>Virgin Islands, U.S.</option>
                            <option value='WF'>Wallis and Futuna</option>
                            <option value='EH'>Western Sahara</option>
                            <option value='YE'>Yemen</option>
                            <option value='ZM'>Zambia</option>
                            <option value='ZW'>Zimbabwe</option>
                          </select>
                          {showStep2Errors && errors.country && (
                            <p className='text-red-500 text-sm'>{errors.country}</p>
                          )}
                        </div>
                     
                        <div className='flex-1 mt-[2px]'>
  <label className='block text-gray-700 text-sm'>Postal Code</label>
  <input
    type='text'
    name='zip'
    value={zip}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/\D/g, '');
      setzip(numericValue);
    }}
    className='w-full px-3 py-0 border rounded text-black'
  />
  {showStep2Errors && errors.zip && <p className='text-red-500 text-sm'>{errors.zip}</p>}
</div>
                      </div>

                      <div className='flex pt-2'>
                        <button
                          type='button'
                          className=' mr-4 text-xs px-4 py-2 border border-blue-900 text-black rounded-full'
                          onClick={() => setStep(1)}
                        >
                          Back
                        </button>
                        <button
                          onClick={handleClick}
                          type='submit'
                          className='px-7 py-2 bg-[#19188B] text-xs text-white rounded-full'
                        >
                          Submit & Checkout
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
        <div>
          {isModalOpen && (
            <div className='fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-[#F1F6FF]   p-8 rounded-lg max-h-screen   max-w-md w-full'>
                <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={closeModal}>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <div className='text-lg md:text-xl text-center font-bold mb-4'>
                  Access your
                  <span className='text-[#C62B6D] font-bold'> AI Readiness </span>
                  <br />
                  In Just 15 Minutes!
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.name && <p className='text-red-500 text-sm'>{errors.name}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Email Address</label>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmailId(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Phone Number</label>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass='custom-phone-input2'
                      buttonClass='border rounded'
                      dropdownClass='border rounded'
                      required
                    />
                    {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='block text-gray-700'>Designation</label>
                    <input
                      type='text'
                      required
                      name='designation'
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                    />
                    {errors.designation && <p className='text-red-500 text-sm'>{errors.designation}</p>}
                  </div>

                  <div className='mb-4'>
                    <label className='block text-gray-700'>Company Name</label>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <button type='submit' className='w-full px-4 py-2 bg-[#C62B6D] text-white rounded'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        {isFormOpen && (
          <div className='fixed max-md:hidden font-display z-50 md:p-10 lg:p-32  mt-10 inset-0 bg-black bg-opacity-50 flex items-center justify-center'>
            <div className='bg-white rounded-lg  w-full  flex relative  '>
              <button
                className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                onClick={closeModal}
                id='form-close-button'
              >
                <svg
                  className='w-6 h-6'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='4' d='M6 18L18 6M6 6l12 12'></path>
                </svg>
              </button>
              <div
                className='bg-[#7B53EC] w-full  flex flex-col items-start justify-center rounded-l-lg bg-no-repeat bg-cover  '
                style={{
                  backgroundImage: `url(${bgimg1})`,
                  backgroundPosition: 'center',
                }}
              >
                <div className='flex flex-col items-start justify-center'>
                  <div className='text-sm md:text-sm lg:text-2xl 2xl:pt-10  font-normal ml-10 pb-3 text-white text-center'>
                    Discover your AI readiness
                  </div>
                  <div className='text-sm font-bold md:text-xs lg:text-xl ml-10 pb-3 text-white text-left'>
                    AI Readiness & Digital
                    <br />
                    Maturity Assessment Index
                  </div>
                </div>
                <img src={formpic} alt='Description of image' className='md:w-full  2xl:pb-5  px-5 h-auto' />
              </div>

              <button
                className={`absolute left-1/2 md:top-10 lg:top-24 -translate-x-10 transform flex items-center justify-center md:w-7 md:h-7 lg:w-8 lg:h-8 bg-[#040330] text-[#3E1D9E] font-bold rounded-full cursor-default 
              `}
              >
                {step === 1 ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#FFFFFF'
                    className='md:w-3 md:h-3 lg:w-4 lg:h-4'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 12h20m-7-7 7 7-7 7' />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='#FFFFFF'
                    className='md:w-3 md:h-3 lg:w-4 lg:h-4'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M2 12h20m-7-7 7 7-7 7' />
                  </svg>
                )}
              </button>

              <div className=' p-10 w-full  justify-center items-center'>
                <div className=' font-normal mb-4 lg:text-xl md:text-sm text-black text-left'>
                  Please fill the form to proceed payment
                </div>
                <form>
                  {step === 1 && (
                    <>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          First Name{' '}
                          {showStep1Errors && errors.userName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='userName'
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          className='w-full px-3 py-2 border border-gray-300 rounded text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          Last Name
                          {showStep1Errors && errors.lastName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.lastName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='name'
                          value={lastName}
                          onChange={(e) => setlastName(e.target.value)}
                          className='w-full px-3 py-2 border rounded text-black'
                          required
                        />
                      </div>

                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          Email Address
                          {showStep1Errors && errors.email && (
                            <span className='text-red-500 text-sm ml-2'>{errors.email}</span>
                          )}
                        </label>
                        <input
                          type='email'
                          name='email'
                          value={email}
                          onChange={(e) => setEmailId(e.target.value)}
                          className='w-full px-3 py-2 border rounded border-gray-300 text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='text-gray-700'>
                          Phone Number
                          {showStep1Errors && errors.userphoneNumber && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userphoneNumber}</span>
                          )}
                        </label>
                        <PhoneInput
                          country={'in'}
                          required
                          value={userphoneNumber}
                          onChange={setusersphoneNumber}
                          inputClass='custom-phone-input2'
                          buttonClass='border rounded'
                          dropdownClass='border rounded'
                          className='text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          Job Title
                          {showStep1Errors && errors.userDesignation && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userDesignation}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          required
                          name='userDesignation'
                          value={userDesignation}
                          onChange={(e) => setUserDesignation(e.target.value)}
                          className='w-full px-3 py-2 border rounded border-gray-300 text-black'
                        />
                      </div>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>
                          Company Name
                          {showStep1Errors && errors.userCompanyName && (
                            <span className='text-red-500 text-sm ml-2'>{errors.userCompanyName}</span>
                          )}
                        </label>
                        <input
                          type='text'
                          name='userCompanyName'
                          value={userCompanyName}
                          onChange={(e) => setUserCompanyName(e.target.value)}
                          className='w-full px-3 py-2 border rounded border-gray-300 text-black'
                        />
                      </div>
                      <div className='flex justify-start'>
                        <button
                          type='button'
                          className='px-7 py-2 bg-[#19188B] text-white rounded-full'
                          onClick={handleNextClick}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <h1 className='text-gray-700 text-base '>Billing Address:</h1>
                      <div className='mb-4'>
                        <label className='block text-gray-700'>Street</label>
                        <textarea
                          name='street'
                          value={street}
                          onChange={(e) => setstreet(e.target.value)}
                          className='w-full px-3 py-0 border rounded border-gray-300 text-black'
                        ></textarea>
                        {showStep2Errors && errors.street && <p className='text-red-500 text-sm'>{errors.street}</p>}
                      </div>
                      <div className='flex gap-4 mb-4'>
                        <div className='flex-1'>
                          <label className='block text-gray-700'>City</label>
                          <input
                            type='text'
                            name='city'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                          />
                          {showStep2Errors && errors.city && <p className='text-red-500 text-sm'>{errors.city}</p>}
                        </div>
                        <div className='flex-1'>
                          <label className='block text-gray-700'>State</label>
                          <input
                            type='text'
                            name='state'
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                          />
                          {showStep2Errors && errors.state && <p className='text-red-500 text-sm'>{errors.state}</p>}
                        </div>
                      </div>

                      <div className='flex gap-4 mb-4'>
                        <div className='flex-1 text-black '>
                          <label className='block text-gray-700'>Country</label>
                          <select
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            className='w-full px-3 py-1 border rounded border-gray-300 text-black'
                          >
                            <option value=''>--Select--</option>
                            <option value='AF'>Afghanistan</option>
                            <option value='AX'>Åland Islands</option>
                            <option value='AL'>Albania</option>
                            <option value='DZ'>Algeria</option>
                            <option value='AS'>American Samoa</option>
                            <option value='AD'>Andorra</option>
                            <option value='AO'>Angola</option>
                            <option value='AI'>Anguilla</option>
                            <option value='AQ'>Antarctica</option>
                            <option value='AG'>Antigua and Barbuda</option>
                            <option value='AR'>Argentina</option>
                            <option value='AM'>Armenia</option>
                            <option value='AW'>Aruba</option>
                            <option value='AU'>Australia</option>
                            <option value='AT'>Austria</option>
                            <option value='AZ'>Azerbaijan</option>
                            <option value='BS'>Bahamas</option>
                            <option value='BH'>Bahrain</option>
                            <option value='BD'>Bangladesh</option>
                            <option value='BB'>Barbados</option>
                            <option value='BY'>Belarus</option>
                            <option value='BE'>Belgium</option>
                            <option value='BZ'>Belize</option>
                            <option value='BJ'>Benin</option>
                            <option value='BM'>Bermuda</option>
                            <option value='BT'>Bhutan</option>
                            <option value='BO'>Bolivia, Plurinational State of</option>
                            <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                            <option value='BA'>Bosnia and Herzegovina</option>
                            <option value='BW'>Botswana</option>
                            <option value='BV'>Bouvet Island</option>
                            <option value='BR'>Brazil</option>
                            <option value='IO'>British Indian Ocean Territory</option>
                            <option value='BN'>Brunei Darussalam</option>
                            <option value='BG'>Bulgaria</option>
                            <option value='BF'>Burkina Faso</option>
                            <option value='BI'>Burundi</option>
                            <option value='KH'>Cambodia</option>
                            <option value='CM'>Cameroon</option>
                            <option value='CA'>Canada</option>
                            <option value='CV'>Cape Verde</option>
                            <option value='KY'>Cayman Islands</option>
                            <option value='CF'>Central African Republic</option>
                            <option value='TD'>Chad</option>
                            <option value='CL'>Chile</option>
                            <option value='CN'>China</option>
                            <option value='CX'>Christmas Island</option>
                            <option value='CC'>Cocos (Keeling) Islands</option>
                            <option value='CO'>Colombia</option>
                            <option value='KM'>Comoros</option>
                            <option value='CG'>Congo</option>
                            <option value='CD'>Congo, the Democratic Republic of the</option>
                            <option value='CK'>Cook Islands</option>
                            <option value='CR'>Costa Rica</option>
                            <option value='CI'>Côte d'Ivoire</option>
                            <option value='HR'>Croatia</option>
                            <option value='CU'>Cuba</option>
                            <option value='CW'>Curaçao</option>
                            <option value='CY'>Cyprus</option>
                            <option value='CZ'>Czech Republic</option>
                            <option value='DK'>Denmark</option>
                            <option value='DJ'>Djibouti</option>
                            <option value='DM'>Dominica</option>
                            <option value='DO'>Dominican Republic</option>
                            <option value='EC'>Ecuador</option>
                            <option value='EG'>Egypt</option>
                            <option value='SV'>El Salvador</option>
                            <option value='GQ'>Equatorial Guinea</option>
                            <option value='ER'>Eritrea</option>
                            <option value='EE'>Estonia</option>
                            <option value='ET'>Ethiopia</option>
                            <option value='FK'>Falkland Islands (Malvinas)</option>
                            <option value='FO'>Faroe Islands</option>
                            <option value='FJ'>Fiji</option>
                            <option value='FI'>Finland</option>
                            <option value='FR'>France</option>
                            <option value='GF'>French Guiana</option>
                            <option value='PF'>French Polynesia</option>
                            <option value='TF'>French Southern Territories</option>
                            <option value='GA'>Gabon</option>
                            <option value='GM'>Gambia</option>
                            <option value='GE'>Georgia</option>
                            <option value='DE'>Germany</option>
                            <option value='GH'>Ghana</option>
                            <option value='GI'>Gibraltar</option>
                            <option value='GR'>Greece</option>
                            <option value='GL'>Greenland</option>
                            <option value='GD'>Grenada</option>
                            <option value='GP'>Guadeloupe</option>
                            <option value='GU'>Guam</option>
                            <option value='GT'>Guatemala</option>
                            <option value='GG'>Guernsey</option>
                            <option value='GN'>Guinea</option>
                            <option value='GW'>Guinea-Bissau</option>
                            <option value='GY'>Guyana</option>
                            <option value='HT'>Haiti</option>
                            <option value='HM'>Heard Island and McDonald Islands</option>
                            <option value='VA'>Holy See (Vatican City State)</option>
                            <option value='HN'>Honduras</option>
                            <option value='HK'>Hong Kong</option>
                            <option value='HU'>Hungary</option>
                            <option value='IS'>Iceland</option>
                            <option value='IN'>India</option>
                            <option value='ID'>Indonesia</option>
                            <option value='IR'>Iran, Islamic Republic of</option>
                            <option value='IQ'>Iraq</option>
                            <option value='IE'>Ireland</option>
                            <option value='IM'>Isle of Man</option>
                            <option value='IL'>Israel</option>
                            <option value='IT'>Italy</option>
                            <option value='JM'>Jamaica</option>
                            <option value='JP'>Japan</option>
                            <option value='JE'>Jersey</option>
                            <option value='JO'>Jordan</option>
                            <option value='KZ'>Kazakhstan</option>
                            <option value='KE'>Kenya</option>
                            <option value='KI'>Kiribati</option>
                            <option value='KP'>Korea, Democratic People's Republic of</option>
                            <option value='KR'>Korea, Republic of</option>
                            <option value='KW'>Kuwait</option>
                            <option value='KG'>Kyrgyzstan</option>
                            <option value='LA'>Lao People's Democratic Republic</option>
                            <option value='LV'>Latvia</option>
                            <option value='LB'>Lebanon</option>
                            <option value='LS'>Lesotho</option>
                            <option value='LR'>Liberia</option>
                            <option value='LY'>Libya</option>
                            <option value='LI'>Liechtenstein</option>
                            <option value='LT'>Lithuania</option>
                            <option value='LU'>Luxembourg</option>
                            <option value='MO'>Macao</option>
                            <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                            <option value='MG'>Madagascar</option>
                            <option value='MW'>Malawi</option>
                            <option value='MY'>Malaysia</option>
                            <option value='MV'>Maldives</option>
                            <option value='ML'>Mali</option>
                            <option value='MT'>Malta</option>
                            <option value='MH'>Marshall Islands</option>
                            <option value='MQ'>Martinique</option>
                            <option value='MR'>Mauritania</option>
                            <option value='MU'>Mauritius</option>
                            <option value='YT'>Mayotte</option>
                            <option value='MX'>Mexico</option>
                            <option value='FM'>Micronesia, Federated States of</option>
                            <option value='MD'>Moldova, Republic of</option>
                            <option value='MC'>Monaco</option>
                            <option value='MN'>Mongolia</option>
                            <option value='ME'>Montenegro</option>
                            <option value='MS'>Montserrat</option>
                            <option value='MA'>Morocco</option>
                            <option value='MZ'>Mozambique</option>
                            <option value='MM'>Myanmar</option>
                            <option value='NA'>Namibia</option>
                            <option value='NR'>Nauru</option>
                            <option value='NP'>Nepal</option>
                            <option value='NL'>Netherlands</option>
                            <option value='NC'>New Caledonia</option>
                            <option value='NZ'>New Zealand</option>
                            <option value='NI'>Nicaragua</option>
                            <option value='NE'>Niger</option>
                            <option value='NG'>Nigeria</option>
                            <option value='NU'>Niue</option>
                            <option value='NF'>Norfolk Island</option>
                            <option value='MP'>Northern Mariana Islands</option>
                            <option value='NO'>Norway</option>
                            <option value='OM'>Oman</option>
                            <option value='PK'>Pakistan</option>
                            <option value='PW'>Palau</option>
                            <option value='PS'>Palestinian Territory, Occupied</option>
                            <option value='PA'>Panama</option>
                            <option value='PG'>Papua New Guinea</option>
                            <option value='PY'>Paraguay</option>
                            <option value='PE'>Peru</option>
                            <option value='PH'>Philippines</option>
                            <option value='PN'>Pitcairn</option>
                            <option value='PL'>Poland</option>
                            <option value='PT'>Portugal</option>
                            <option value='PR'>Puerto Rico</option>
                            <option value='QA'>Qatar</option>
                            <option value='RE'>Réunion</option>
                            <option value='RO'>Romania</option>
                            <option value='RU'>Russian Federation</option>
                            <option value='RW'>Rwanda</option>
                            <option value='BL'>Saint Barthélemy</option>
                            <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                            <option value='KN'>Saint Kitts and Nevis</option>
                            <option value='LC'>Saint Lucia</option>
                            <option value='MF'>Saint Martin (French part)</option>
                            <option value='PM'>Saint Pierre and Miquelon</option>
                            <option value='VC'>Saint Vincent and the Grenadines</option>
                            <option value='WS'>Samoa</option>
                            <option value='SM'>San Marino</option>
                            <option value='ST'>Sao Tome and Principe</option>
                            <option value='SA'>Saudi Arabia</option>
                            <option value='SN'>Senegal</option>
                            <option value='RS'>Serbia</option>
                            <option value='SC'>Seychelles</option>
                            <option value='SL'>Sierra Leone</option>
                            <option value='SG'>Singapore</option>
                            <option value='SX'>Sint Maarten (Dutch part)</option>
                            <option value='SK'>Slovakia</option>
                            <option value='SI'>Slovenia</option>
                            <option value='SB'>Solomon Islands</option>
                            <option value='SO'>Somalia</option>
                            <option value='ZA'>South Africa</option>
                            <option value='GS'>South Georgia and the South Sandwich Islands</option>
                            <option value='SS'>South Sudan</option>
                            <option value='ES'>Spain</option>
                            <option value='LK'>Sri Lanka</option>
                            <option value='SD'>Sudan</option>
                            <option value='SR'>Suriname</option>
                            <option value='SJ'>Svalbard and Jan Mayen</option>
                            <option value='SZ'>Swaziland</option>
                            <option value='SE'>Sweden</option>
                            <option value='CH'>Switzerland</option>
                            <option value='SY'>Syrian Arab Republic</option>
                            <option value='TW'>Taiwan, Province of China</option>
                            <option value='TJ'>Tajikistan</option>
                            <option value='TZ'>Tanzania, United Republic of</option>
                            <option value='TH'>Thailand</option>
                            <option value='TL'>Timor-Leste</option>
                            <option value='TG'>Togo</option>
                            <option value='TK'>Tokelau</option>
                            <option value='TO'>Tonga</option>
                            <option value='TT'>Trinidad and Tobago</option>
                            <option value='TN'>Tunisia</option>
                            <option value='TR'>Turkey</option>
                            <option value='TM'>Turkmenistan</option>
                            <option value='TC'>Turks and Caicos Islands</option>
                            <option value='TV'>Tuvalu</option>
                            <option value='UG'>Uganda</option>
                            <option value='UA'>Ukraine</option>
                            <option value='AE'>United Arab Emirates</option>
                            <option value='GB'>United Kingdom</option>
                            <option value='US'>United States</option>
                            <option value='UM'>United States Minor Outlying Islands</option>
                            <option value='UY'>Uruguay</option>
                            <option value='UZ'>Uzbekistan</option>
                            <option value='VU'>Vanuatu</option>
                            <option value='VE'>Venezuela, Bolivarian Republic of</option>
                            <option value='VN'>Viet Nam</option>
                            <option value='VG'>Virgin Islands, British</option>
                            <option value='VI'>Virgin Islands, U.S.</option>
                            <option value='WF'>Wallis and Futuna</option>
                            <option value='EH'>Western Sahara</option>
                            <option value='YE'>Yemen</option>
                            <option value='ZM'>Zambia</option>
                            <option value='ZW'>Zimbabwe</option>
                          </select>
                          {showStep2Errors && errors.country && (
                            <p className='text-red-500 text-sm'>{errors.country}</p>
                          )}
                        </div>
                       
                        <div className='flex-1 mt-1'>
  <label className='block text-gray-700 text-sm'>Postal Code</label>
  <input
    type='text'
    name='zip'
    value={zip}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/\D/g, '');
      setzip(numericValue);
    }}
    className='w-full px-3 py-0 border rounded text-black'
  />
  {showStep2Errors && errors.zip && <p className='text-red-500 text-sm'>{errors.zip}</p>}
</div>
                      </div>

                      <div className='flex justify-end'>
                        <button
                          type='button'
                          className='mr-4 px-7 py-2  border border-blue-900 text-black rounded-full'
                          onClick={() => setStep(1)}
                        >
                          Back
                        </button>
                        <button
                          onClick={handleClick}
                          type='submit'
                          className='px-7 py-2 bg-[#19188B] text-white rounded-full'
                        >
                          Submit & Checkout
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Homepage;
