
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import eventt from "../assets/Events/event.png";
import eventsbg2 from "../assets/Events/eventsbg2.png";
import bubbles from "../assets/Events/bubbles.png";
import homebg7 from "../assets/Home/homebg7.png";
import recright from "../assets/Home/recright.png";
import Footer from "../components/Footer";
import Popup from "../components/popup.js";



export const Events = ({ events, speakers }) => {

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImageModal = (eventIndex, imageIndex) => {
    setSelectedEvent(eventIndex);
    setSelectedImageIndex(imageIndex);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedImageIndex(null);
  };

  const navigateImage = (direction) => {
    if (selectedEvent === null) return;
    
    const currentEvent = events[selectedEvent];
    setSelectedImageIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      const totalImages = currentEvent.eventImg.length;
      if (newIndex < 0) return totalImages - 1;
      if (newIndex >= totalImages) return 0;
      return newIndex;
    });
  };

 
console.log("eventssss:", events)

  const [selectedImage, setSelectedImage] = useState(null);
 
  


  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(5);

  useEffect(() => {
    const updateItemsPerView = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(5);
      }
    };

    updateItemsPerView();


    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
      updateItemsPerView();
    };


    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);

  }, []);

  const slideLeft = () => {
    setSlideIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : speakerList.length - itemsPerView));
  };

  const slideRight = () => {
    setSlideIndex((prevIndex) => (prevIndex < speakerList.length - itemsPerView ? prevIndex + 1 : 0));
  };


  const activeSlideIndex = Math.floor(slideIndex / itemsPerView);

  const eventList = events || [];
  const speakerList = speakers || [];




  const getYouTubeVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i
    );
    return match ? match[1] : null;
  };


  useEffect(() => {

    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  

  return (

    <div className="Main2 md:max-w-full 2xl:max-w-full  font-display text-[#666666] lg:mt-10 2xl:mt-0 ">
      <div className="  bg-gradient_background4">
        <div className="flex flex-wrap items-center">
          <div className=" md:ml-5 lg:ml-20 md:pt-5 pt-16 lg:pt-10 flex items-center py-5 md:py-12">
            <div className="flex flex-row bg-cover bg-no-repeat rounded-2xl pt-10 ">
              <div className="flex  md:flex-row px-3  lg:items-start lg:pl-10 w-full">
                <div className="flex flex-col md:mt-20 md:ml-10  2xl:pt-32">
                  <div className="font-display  md:text-2xl lg:text-4xl 2xl:text-5xl font-semibold pb-2 md:pb-4">

                    Attend a Playlab event
                  </div>
                  <div className='text-xs font-display md:text-sm lg:text-base 2xl:text-xl text-left'>
                    Be part of Random Walk’s AI events, including webinars and in-person gatherings. Whether you like
                    live interaction or prefer on-demand content, you’ll get access to top-notch insights and
                    cutting-edge thought leadership in the AI sector.
                  </div>
                </div>

                <div className="flex justify-end lg:w-full md:-mt-0 lg:-mt-20  lg:h-auto">
                  <img src={bubbles} alt="Description of Image" className="" />

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="text-center  text-lg font-bold md:text-2xl 2xl:text-3xl lg:text-4xl font-display  md:mt-10 py-5">

        Our Event Philosophy
      </div>
      <div className='flex hidden md:hidden lg:block justify-center 2xl:px-96 pb-10 lg:px-10'>
        <div className='w-full mr-12'>
          <div className='w-full mr-12 mt-5'>
            <div className='ml-0 flex flex-col lg:flex-row mt-5 pt-1'>
              <div className='lg:flex lg:items-stretch lg:w-1/2'>
                <div className='lg:hidden'>
                  <img src={eventt} alt='AI Integration' className='w-full md:w-80 md:ml-56 ml-5 mb-5' />
                </div>

                <div className='hidden lg:block lg:w-auto lg:mt-5 lg:mr-5 lg:flex-grow'>
                  <img src={eventt} alt='AI Integration' className='w-full ml-20 rounded-xl  h-full' />
                </div>
              </div>

              <div className='w-full md:ml-12 lg:w-1/2 lg:ml-28 lg:flex lg:flex-col lg:justify-between  lg:mt-5 lg:flex-grow'>
                <div className='text-xs ml-5 font-display lg:text-sm mb-4'>
                  Random Walk’s AI events are more than just gatherings. They are immersive, leaving a lasting impact
                  and surpassing all expectations. Our philosophy for AI events is built on three pillars.
                </div>
                <div className='text-xs ml-5  font-bold lg:font-display lg:text-base lg:pb-2'>
                  01. Unforgettable Impact
                </div>
                <div className='text-xs ml-5  lg:font-display lg:text-sm lg:pb-4'>
                  We are dedicated to crafting AI event experiences that imprint
                  <br />a lasting impression, guaranteeing you walk away not just <br />
                  inspired but genuinely enlightened.
                </div>
                <div className='text-xs ml-5 font-bold lg:font-display lg:text-base lg:pb-2'>
                  02. Community and Collaboration
                </div>
                <div className='text-xs ml-5  lg:font-display lg:text-sm lg:pb-4'>
                  At Random Walk events, we go beyond imparting knowledge; we’re here to cultivate a close community,
                  nurture connections, and foster fruitful collaborations within the AI industry. Join us for AI
                  gatherings that are more than just events – they’re opportunities to build lasting bonds and engage in
                  meaningful partnerships.
                </div>
                <div className='text-xs ml-5  font-bold lg:font-display lg:text-base lg:pb-2'>
                  03. Business Growth Acceleration
                </div>
                <div className='text-xs ml-5 lg:font-display lg:text-sm'>
                  We recognize that AI events should be a worthwhile investment. Our goal is to organize events that not
                  only offer valuable insights but also yield concrete results, propelling your AI initiatives to
                  flourish. Join us at Random Walk Events for a strategic blend of knowledge and actionable outcomes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex  lg:hidden justify-center 2xl:px-96 pb-10 lg:px-10">
        <div className="ml-0 flex flex-col md:flex-row  pt-1">
          <div className="md:flex  md:w-1/2">
            <div className="md:w-auto pb-5 md:pb-0   px-5 md:translate-y-5 object-cover lg:translate-y-0 lg:mt-5 md:pl-5 md:flex-grow">
              <img src={eventt} alt="AI Integration" className=" rounded-xl" />
            </div>
          </div>

          <div className="w-full  md:w-1/2  md:flex md:flex-col   md:mt-5 md:flex-grow">
            <div className="text-xs  px-5  pb-3  md:px-0 font-display md:text-sm md:pb-2  ">
              Random Walk’s AI events are more than just gatherings. They are
              immersive, leaving a lasting impact and surpassing all
              expectations. Our philosophy for AI events is built on three
              pillars.
            </div>
            <div className="   px-10 md:px-0 ">
              <div className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 ">
                {" "}
                01. Unforgettable Impact
              </div>

              <div className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                We are dedicated to crafting AI event experiences that imprint a
                lasting impression, guaranteeing you walk away not just inspired
                but genuinely enlightened.
              </div>
            </div>

            <div className="px-10 md:px-0    ">
              <div className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 ">
                {" "}
                02. Community and Collaboration
              </div>

              <div className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                At Random Walk events, we go beyond imparting knowledge; we’re
                here to cultivate a close community, nurture connections, and
                foster fruitful collaborations within the AI industry. Join us
                for AI gatherings that are more than just events – they’re
                opportunities to build lasting bonds and engage in meaningful
                partnerships.
              </div>
            </div>

            <div className="px-10 md:px-0    ">
              <div className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 ">
                {" "}
                03. Business Growth Acceleration
              </div>

              <div className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                We recognize that AI events should be a worthwhile investment.
                Our goal is to organize events that not only offer valuable
                insights but also yield concrete results, propelling your AI
                initiatives to flourish. Join us at Random Walk Events for a
                strategic blend of knowledge and actionable outcomes.

              </div>
            </div>
          </div>
        </div>
      </div>


   


<div className="w-full px-5">
      <div className="max-w-[1240px] mx-auto md:pt-10">
        <div className="flex flex-col gap-1 px-4 text-[#666666]">
          {events.length > 0 ? (
            events.map((event, eventIndex) => {
              const eventImgs = event.eventImg || [];
              const redirectImgUrl = event.redirectImg?.url;
              const redirectLink = event.redirectLink;
              const videoUrl = event.videoUrl;
              const videoId = videoUrl ? getYouTubeVideoId(videoUrl) : null;

              return (
                <div key={event.id} className="relative md:px-16">
                  <h3 className="md:text-2xl text-xl lg:text-4xl font-display font-bold mb-4 text-center pb-1">
                    {event.eventTitle}
                  </h3>

                  {videoId && (
                    <div className='mt-4 flex justify-center'>
                      <iframe
                        width='1500'
                        height='500'
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                        title='YouTube video player'
                      ></iframe>
                    </div>
                  )}

                  <div className='grid grid-cols-1 pt-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                    {eventImgs.map((image, imageIndex) => (
                      <img
                        key={imageIndex}
                        src={`${image.url}`}
                        alt="Event"
                        className="w-full h-auto cursor-pointer"
                        onClick={() => openImageModal(eventIndex, imageIndex)}
                      />
                    ))}
                  </div>
                  <div className='flex justify-center'>
                    <div className='flex justify-center mt-8 mb-8'>
                      {redirectImgUrl && (
                        <NavLink to={redirectLink} className='mx-auto'>
                          <img
                            src={`${redirectImgUrl}`}
                            alt="Redirect"
                            className="w-full h-auto"
                            style={{ maxWidth: "500px", cursor: "pointer" }}
                          />
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No events available</p>
          )}

          {selectedEvent !== null && selectedImageIndex !== null && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
              <div className="">
                <button
                  className="absolute top-2 right-2 text-white text-5xl font-bold"
                  onClick={closeModal}
                >
                  &times;
                </button>
                
                <button
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-5xl font-bold"
                  onClick={() => navigateImage(-1)}
                >
                  &#8249;
                </button>
                <button
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-5xl font-bold"
                  onClick={() => navigateImage(1)}
                >
                  &#8250;
                </button>
                
                <img
                  src={events[selectedEvent].eventImg[selectedImageIndex].url}
                  alt="Popup"
                  className="object-contain"
                />
              </div>
            </div>
          )}

          <div className="text-center text-[#666666] text-4xl font-bold md:py-1 font-display">
            Past Speakers
          </div>

          <div className="relative flex justify-center font-display pb-10">
            <button
              onClick={slideLeft}
              className="absolute rounded-full hover:bg-blue-400 -left-2 top-1/2 transform -translate-y-16 md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 hover:text-white text-blue-500 p-1 z-10"
              style={{
                width: "3rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="rounded-full hover:bg-blue-400 p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
            </button>

            <div className="carousel-container overflow-hidden w-full lg:max-w-[1200px] 2xl:max-w-[1200px] md:max-w-[1200px]">
              <div
                className="carousel-track flex transition-transform duration-300 ease-in-out"
                style={{
                  transform: `translateX(-${slideIndex * (carouselWidth / itemsPerView)}px)`,
                }}
              >
                {speakers.map((speaker) => (
                  <div
                    key={speaker.id}
                    className="flex-shrink-0"
                    style={{ width: `${carouselWidth / itemsPerView}px` }}
                  >
                    <div className="flex flex-col items-center p-2">
                      <img
                        src={`${speaker.speakerImg.url}`}
                        alt={speaker.speakerName}
                        className="md:w-56 md:h-60 lg:w-72 lg:h-96 object-cover mb-4"
                      />
                      <div className="md:text-base text-xs font-semibold mb-1">
                        {speaker.speakerName}
                      </div>
                      <div className="flex text-xs md:text-xs text-center">
                        {`${speaker.speaker_designation}, ${speaker.speaker_company}`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <button
              onClick={slideRight}
              className="absolute hover:bg-blue-400 rounded-full -right-2 top-1/2 -translate-y-16 transform md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 text-blue-500 hover:text-white p-1 z-10"
              style={{
                width: "3rem",
                height: "3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="rounded-full hover:bg-blue-400 p-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </button>

            <div className="absolute bottom-0 translate-y-4 left-0 right-0 flex justify-center mb-10">
              {Array.from({
                length: Math.ceil(speakers.length / itemsPerView),
              }).map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 mt-5 rounded-full mx-1 ${
                    index === activeSlideIndex ? "bg-black" : "bg-gray-300"
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>


      <div className=" mt-20 ">

        <Popup />
      </div>
    </div>
  );
};
