



// import { BrowserRouter } from "react-router-dom";
// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App.js";
// import "./index.css";



// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );



import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";  // Import BrowserRouter
import App from "./App";
import TagManager from "react-gtm-module";
import "./index.css";
 
// Initialize GTM
const tagManagerArgs = {
  gtmId: "GTM-KP5M82KR", // Replace with your actual GTM ID
};
 
TagManager.initialize(tagManagerArgs);
 
const root = ReactDOM.createRoot(document.getElementById("root"));
 
root.render(
<React.StrictMode>
    {/* Include BrowserRouter to enable routing in the app */}
<BrowserRouter>
<App />
</BrowserRouter>
</React.StrictMode>
);