import React, { useState ,useEffect} from "react";
import { NavLink } from "react-router-dom";
import { Client, Databases, ID } from 'appwrite';

import backgroundImage from "../assets/Workshop/main.png";
import square from "../assets/Workshop/square.png";
import girl from "../assets/Workshop/girl.png";
import paper from "../assets/Workshop/paper.png";
import group from "../assets/Workshop/group.png";
import group2 from "../assets/Workshop/group2.png";
import mission from "../assets/Workshop/mission.png";
import box from "../assets/Workshop/box.png";
import line from "../assets/Workshop/line.png";
import lady from "../assets/Workshop/lady.png";
import icon1 from "../assets/Workshop/work1.png";
import icon2 from "../assets/Workshop/work2.png";
import icon3 from "../assets/Workshop/work3.png";
import icon4 from "../assets/Workshop/work4.png";
import workk10 from "../assets/Workshop/work10.png";
import workk11 from "../assets/Workshop/work11.png";
import workk12 from "../assets/Workshop/work12.png";
import workk13 from "../assets/Workshop/work13.png";
import workk14 from "../assets/Workshop/work14.png";
import workk15 from "../assets/Workshop/work15.png";
import workk16 from "../assets/Workshop/work16.png";
import workk17 from "../assets/Workshop/work17.png";
import workk18 from "../assets/Workshop/work18.png";
import workk19 from "../assets/Workshop/work19.png";
import workk20 from "../assets/Workshop/work20.png";
import workk21 from "../assets/Workshop/work21.png";
import contact from "../assets/Workshop/contact.png";
import iicon1 from "../assets/Workshop/iconn1.PNG";
import iicon2 from "../assets/Workshop/iconn2.PNG";
import iicon3 from "../assets/Workshop/iconn3.PNG";
import recleft from "../assets/Integration/recleft.png";
import recright from "../assets/Integration/recright.png";
import iicon4 from "../assets/Workshop/iconn4.PNG";
import topright from "../assets/Workshop/topright.png";
import bottomright from "../assets/Workshop/bottomright.png";
import blue from "../assets/Workshop/blue.png";
import pink from "../assets/Workshop/pink.png";
import pinkblue from "../assets/Workshop/pinkblue.png";
import click from "../assets/Workshop/click.png";
import connect from "../assets/Workshop/connect.png";
import man from "../assets/Workshop/man .png";
import cap from "../assets/Workshop/cap .png";
import cap2 from "../assets/Workshop/cap2.png";
import arrow from "../assets/Workshop/arrow.png";
import wire from "../assets/Workshop/wire.png";
import wire2 from "../assets/Workshop/wire2.png";
import plus from "../assets/Workshop/plus.png";
import minus from "../assets/Workshop/minus.png";
import line1 from "../assets/Workshop/line1.png";
import blur from "../assets/Workshop/blur.png";
import girl2 from "../assets/Workshop/girl2.png";
import Mission2 from "../assets/Workshop/Mission2.png";
import paper2 from "../assets/Workshop/paper2.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import "./Workshop.css";
export const Workshop = () => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);


  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [businessemail, setEmailId] = useState('');
  const [projectdetails, setprojectdetails] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');

  const [errors, setErrors] = useState({});

  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmitcontact = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID2,
        ID.unique(),
        {
          firstname,
          lastname,
          businessemail,
          companyName,
          phoneNumber,
          projectdetails,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
  
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };





  const [isModalOpenbrochure, setIsModalOpenbrochure] = useState(false);
  const [isSuccessOpenbrochure, setIsSuccessOpenbrochure] = useState(false);

  const [fullnamebrochure, setfullnamebrochure] = useState('');
  const [jobtitlebrochure, setjobtitlebrochure] = useState('');
  const [companynamebrochure, setcompanynamebrochure] = useState('');
  const [countrybrochure, setcountrybrochure] = useState('');
  const [emailbrochure, setemailbrochure] = useState('');
  const [utmSourcebrochure, setUtmSourcebrochure] = useState('');
  const [utmMediumbrochure, setUtmMediumbrochure] = useState('');
  const [utmCampaignbrochure, setUtmCampaignbrochure] = useState('');
  const [utmIdbrochure, setUtmIdbrochure] = useState('');
  const [utmTermbrochure, setUtmTermbrochure] = useState('');
  const [utmContentbrochure, setUtmContentbrochure] = useState('');

  const [errorsbrochure, setErrorsbrochure] = useState({});

  const handleButtonClickbrochure = () => {
    setIsModalOpen(true);
  };

  const closeModalbrochure = () => {
    setIsModalOpen(false);
  };

  const clientbrochure = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesbrochure = new Databases(client);

  const handlePhoneNumberChangebrochure = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSourcebrochure(searchParams.get('utm_source') || '');
    setUtmMediumbrochure(searchParams.get('utm_medium') || '');
    setUtmCampaignbrochure(searchParams.get('utm_campaign') || '');
    setUtmIdbrochure(searchParams.get('utm_id') || '');
    setUtmTermbrochure(searchParams.get('utm_term') || '');
    setUtmContentbrochure(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmitbrochure = async (event) => {
    event.preventDefault();
    

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID3,
        ID.unique(),
        {
          fullnamebrochure,
          jobtitlebrochure,
          companynamebrochure,
          countrybrochure,
          emailbrochure,
         utmSourcebrochure,
          utmMediumbrochure,
          utmCampaignbrochure,
          utmIdbrochure,
          utmTermbrochure,
          utmContentbrochure,

 




        }
      );

      const pdfUrl = "https://randomwalk.ai/wp-content/uploads/2024/01/Random-Walk-Common-AI-Brochure-v3.pdf";
        
      // Create a link element
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('target', '_blank');  // Open in a new tab
      link.setAttribute('download', 'Random-Walk-Common-AI-Brochure.pdf'); // Optional: if you still want to force download
      
      document.body.appendChild(link);  // Append the link to the body
      link.click();  // Programmatically click the link to trigger download/open
      document.body.removeChild(link);  // Clean up by removing the link

      // Close the modal after download
      setIsModalOpen(false);
      
  } catch (error) {
      console.error('Error creating document:', error);
  }
};
  const closeSuccessModalbrochure = () => {
    setIsSuccessOpen(false);
  };











  const [isSuccessOpenconsultation, setIsSuccessOpenconsultation] = useState(false);

  const [fullnameconsultation, setfullnameconsultation] = useState('');

  const [emailconsultation, setemailconsultation] = useState('');

  const [phonenumberconsultation, setphonenumberconsultation] = useState('');

  const [utmSourceconsultation, setUtmSourceconsultation] = useState('');
  const [utmMediumconsultation, setUtmMediumconsultation] = useState('');
  const [utmCampaignconsultation, setUtmCampaignconsultation] = useState('');
  const [utmIdconsultation, setUtmIdconsultation] = useState('');
  const [utmTermconsultation, setUtmTermconsultation] = useState('');
  const [utmContentconsultation, setUtmContentconsultation] = useState('');

  const [errorsconsultation, setErrorsconsultation] = useState({});





  const handleButtonClickconsultation = () => {
    setIsModalOpen(true);
  };

  const closeModalconsultation = () => {
    setIsModalOpen(false);
  };
  
  const clientconsultation = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesconsultation = new Databases(client);

  const handlePhoneNumberChangeconsultation = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSourceconsultation(searchParams.get('utm_source') || '');
    setUtmMediumconsultation(searchParams.get('utm_medium') || '');
    setUtmCampaignconsultation(searchParams.get('utm_campaign') || '');
    setUtmIdconsultation(searchParams.get('utm_id') || '');
    setUtmTermconsultation(searchParams.get('utm_term') || '');
    setUtmContentconsultation(searchParams.get('utm_content') || '');
  }, []);



  const handleSubmitconsultation = async (event) => {
    event.preventDefault();
    

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID4,
        ID.unique(),
        {
          fullnameconsultation,
          phonenumberconsultation,
          emailconsultation,
         utmSourceconsultation,
          utmMediumconsultation,
          utmCampaignconsultation,
          utmIdconsultation,
          utmTermconsultation,
          utmContentconsultation,

 




        }
      );


      
    // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpenconsultation(true);
      
  
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };




const closeSuccessModalconsultation = () => {
  setIsSuccessOpenconsultation(false);
};




















  const [selectedImage, setSelectedImage] = useState(icon1);

  const [lastX, setLastX] = useState(null);

  const handleMouseMove = (e) => {
    const container = document.getElementById("scroll-container");

    if (lastX !== null) {
      const movement = e.clientX - lastX;
      if (movement < 0) {
        container.scrollLeft += Math.abs(movement) * 2;
      }
    }

    setLastX(e.clientX);
  };
  const [buttonText, setButtonText] = useState({
    work1: "",
    work2: "",
    work3: "",
    work4: "",
  });
  const [buttonIcon, setButtonIcon] = useState({
    work1: iicon1,
    work2: iicon2,
    work3: iicon3,
    work4: iicon4,
  });
  const [textVisibility, setTextVisibility] = useState({
    work1: true,
    work2: false,
    work3: false,
    work4: false,
  });
  const [activeWorkshop, setActiveWorkshop] = useState("work1");

  const handleButtonClick = (workshop) => {
    setTextVisibility((prevState) => ({
      ...Object.fromEntries(
        Object.keys(prevState).map((key) => [
          key,
          key === workshop.id ? !prevState[key] : false,
        ])
      ),
    }));

    setActiveWorkshop(workshop.id === activeWorkshop ? null : workshop.id);

    setButtonText((prevState) => ({
      ...prevState,
      [workshop.id]: !prevState[workshop.id] ? workshop.text : "",
    }));

    setSelectedImage(workshop.image);
  };

  const workshops = [
    {
      id: "work1",
      image: icon1,
      buttonText: "Industry Specific",
      textBelow: (
        <div>
          <div className="hidden md:block lg:mt-3 text-left ">
            Strategic implications of AI for your <br />
            specific industry and organization
          </div>
          <div className="block md:hidden lg:mt-3 text-left ">
            Strategic implications of AI for your specific industry and
            organization
          </div>
        </div>
      ),
    },
    {
      id: "work2",
      image: icon2,
      buttonText: "Competitive Advantage",
      textBelow: (
        <div>
          <div className=" hidden md:block lg:mt-3 text-left  ">
            Make informed decisions that drive <br/>competitive advantage
          </div>
          <div className=" block md:hidden lg:mt-3  text-left ">
            Make informed decisions that drive competitive advantage
          </div>
        </div>
      ),
    },
    {
      id: "work3",
      image: icon3,
      buttonText: "AI Driven Initiatives",
      textBelow: (
        <div>
          <div className="hidden md:block lg:mt-3 text-left ">
            Drive impactful AI-driven initiatives within <br />
            your department
          </div>
          <div className="md:hidden block lg:mt-3 text-left ">
            Drive impactful AI-driven initiatives within your department
          </div>
        </div>
      ),
    },
    {
      id: "work4",
      image: icon4,
      buttonText: "AI-Ready Culture",
      textBelow: (
        <div>
          <div className="hidden md:block  lg:mt-3 text-left ">
            Cultivate an AI-ready Culture within your
            <br /> team, ensuring seamless AI integration
          </div>
          <div className="md:hidden block lg:mt-3 text-left ">
            Cultivate an AI-ready Culture within your team, ensuring seamless AI
            integration
          </div>
        </div>
      ),
    },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href =
      "https://randomwalk.ai/wp-content/uploads/2024/01/Random-Walk-Common-AI-Brochure-v3.pdf";
  };
  const Dropdown = ({ question, answer, image }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    return (
      <div className="my-4 relative">
        <button
          className="bg-indigo-100 w-full font-medium text-left font-display text-[#666666] rounded text-sm lg:text-sm 2xl:text-lg text-xs py-4 px-4 hover:bg-indigo-200 hover:text-[#666666] flex justify-between items-center"
          onClick={toggleDropdown}
        >
          <span>{question}</span>
          <img
            src={isOpen ? minus : plus}
            alt="Toggle Icon"
            className="ml-2 w-6 h-6"
          />
        </button>
        {isOpen && (
          <div className="mt-2 p-2 bg-gray-100 py-6 px-4 font-display text-sm lg:text-sm 2xl:text-lg text-xs rounded-md border border-black  text-[#666666]">
            <p>{answer}</p>
          </div>
        )}
      </div>
    );
  };
  const DropdownList = () => {
    const dropdowns = [
      {
        question:
          "What sets Executive AI Workshops apart from other training programs?",
        answer:
          "Our workshops combine cutting-edge expertise from industry leaders with practical, hands-on learning experiences, ensuring that participants not only grasp the concepts of AI but also learn how to apply them effectively in real-world business scenarios.",
        image: "arrowdown.png",
      },
      {
        question:
          "Are the workshops tailored to specific industries or sectors?",
        answer:
          "Yes, our workshops are designed to be adaptable to various industries and sectors. We provide customized insights and case studies relevant to participants’ specific domains to ensure maximum relevance and applicability.",
        image: "arrowdown.png",
      },
      {
        question:
          "Is technical knowledge required to participate in the workshops?",
        answer:
          "No, technical knowledge is not a prerequisite. Our workshops focus on showcasing the business value of AI and exploring AI tools without the need for coding.",
        image: "arrowdown.png",
      },
      {
        question: "Are the AI workshops offered globally?",
        answer:
          "Yes, our workshops are available globally. We offer both online and on-site options to accommodate participants from around the world.",
        image: "arrowdown.png",
      },
      {
        question: "Can the AI training be conducted both online and on-site?",
        answer:
          "Yes, our workshops are flexible and can be conducted either online or on-site, based on your organization’s preferences and requirements.",
        image: "arrowdown.png",
      },
      {
        question: "Will I get hands-on experience with AI tools?",
        answer:
          "Yes, our Applied AI program includes guided exercises using real-world AI tools and platforms.",
        image: "arrowdown.png",
      },
      {
        question:
          "What is the minimum number of participants required for the AI workshop?",
        answer:
          "We require a minimum of 10 participants from organizations to conduct our workshops effectively.",
        image: "arrowdown.png",
      },
    ];

    return (
      <div>
        {dropdowns.map((dropdown, index) => (
          <Dropdown
            key={index}
            question={dropdown.question}
            answer={dropdown.answer}
            image={dropdown.image}
          />
        ))}
      </div>
    );
  };
  return (
    <div>
       
      <div
        className="relative h-64 md:h-screen flex justify-center items-center bg-cover bg-center mt-16 md:mt-0"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="absolute inset-0 bg-[#3E59B7] opacity-50"></div>
        <div className="relative z-10 text-center text-white mt-0 md:mt-0 lg:mt-0 ">
          <h1 className="text-xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl mt-0 md:mt-10 font-bold font-display text-white lg:mb-4">
            Corporate Executive AI Workshops
          </h1>
          <span className=" md:mt-5 text-[10px] md:text-[20px] lg:text-[30px] font-display">
            Bridge the gap between AI Hype and business impact.
          </span>
        </div>
      </div>

      <div className=" p-4 md:p-8  md:px-20 2xl:px-32 bg-white  text-left  ">
        <p className="text-xs md:text-sm lg:text-base font-display 2xl:text-lg 2xl:font-normal text-[#666666]">
          In today's dynamic business environment, ignoring AI is not an option.
          AI is transforming every industry. You see the headlines boasting AI's
          potential, but translating that hype into tangible benefits for your
          company can feel daunting. You might be asking yourself:
        </p>
        <p className="mt-2 md:mt-4 text-xs md:text-sm 2xl:text-base font-display text-customGray lg:text-base 2xl:text-lg 2xl:font-normal text-[#666666]">
          Where do I even begin? How do I navigate the complex world of AI
          technologies?{" "}
        </p>

        <p className="mt-2 md:mt-4 text-xs md:text-sm lg:text-base xl:text-base 2xl:text-lg 2xl:font-normal font-display font-semibold text-[#C62B6D]  lg:text-base">
          You're not alone! Many business leaders face the same challenges.
          That's where we come in: AI Training For Executives.
        </p>
      </div>

      <div className="relative w-full max-w-screen-lg mt-10 lg:ml-2 2xl:ml-56 pb-20">
        <div className="flex justify-center items-start ">
          <div className="flex flex-col items-center relative z-10 ">
            {workshops.map((workshop) => (
              <button
                key={workshop.id}
                className={`lg:ml-0 md:ml-16 2xl:ml-80 font-display z-20 text-[#666666] text-xs  md:py-8 lg:py-10 py-3 pr-6  md:pr-10 lg:pr-10 px-2 md:px-10 lg:px-12 md:mt-4 lg:mt-2 mt-2  rounded flex text-left md:text-center md:items-center border-2 border-white transition-transform duration-600 ease-in-out ${
                  activeWorkshop === workshop.id
                    ? ` bg-[#FFFFFF] scale-105 shadow-xl w-7/12 md:w-11/12 opacity-70 border-2 border-white z-10 mr-32 md:mr-0` // Increased border width
                    : `bg-transparent shadow md:w-3/4 w-1/2 md:mr-32  mr-44 border-2 border-white   z-10`
                }`}
                onClick={() => handleButtonClick(workshop)}
              >
                <div className="flex items-center font-display flex-col">
                  <div className="flex items-center">
                    <img
                      src={buttonIcon[workshop.id]}
                      alt={`Icon ${workshop.id}`}
                      className="w-7 h-7 md:mr-2  "
                    />
                    <span className="text-pink-600 font-bold lg:text-sm text-xs md:mr-18 2xl:text-base">
                      {workshop.buttonText}
                    </span>
                  </div>
                  {textVisibility[workshop.id] && (
                    <span className="text-[#666666] lg:text-sm text-xs font-normal 2xl:text-base  ">
                      {workshop.textBelow}
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
          <div
            className="absolute top-0 right-0 lg:relative md:static"
            style={{ marginLeft: "-50px" }}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected Image"
                className="w-44 h-72  md:w-auto md:h-auto 2xl:ml-40 z-50 object-cover"
              />
            )}
          </div>
        </div>

        <img
          src={blur}
          alt="Corner Image"
          className="absolute -bottom-44 -left-32 w-auto h-16 md:w-24 md:h-24 lg:w-96 lg:h-96"
        />
      </div>

      <div className=" hidden md:block relative lg:h-screen md:h-full 2xl:h-fit flex flex-col justify-start bg-custom-gradient-workshop1 bg-cover bg-center  lg:px-10">
        <div className="pt-16 md:px-8 2xl:px-16 2xl:text-center  md:text-left text-white">
          <h1 className="text-md lg:text-xl 2xl:text-2xl font-medium font-display text-white">
            We understand navigating AI can feel complex. That’s why our
            corporate AI trainings apply the following first principles:
          </h1>
        </div>
        <div className="mt-3 2xl:mt-20 md:px-4 lg:px-8 flex  justify-between gap-2 2xl:pb-20">
          <div className="md:w-44 lg:w-1/4   h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full  md:h-20 2xl:h-72 lg:h-40  ">
              {/* <img
                src={girl2}
                alt="Example"
                className="absolute top-7 right-6  md:w-32 lg:w-56 2xl:w-96 object-cover"
              /> */}
                    <div className="flex justify-center items-center">
  <img
    src={girl2}
    alt="Example"
    className="md:w-32 lg:w-56 2xl:w-80 object-cover"
  />
</div>
            </div>
            <h2 className="md:text-md lg:text-lg font-medium  text-left font-display mt-10 2xl:text-xl 2xl:mt-0  2xl:text-left mr-16">
              Industry Experts
            </h2>
            <p className="font-display md:text-md lg:text-lg 2xl:text-xl">
              Led by industry experts who speak your language and understand
              your challenges.
            </p>
          </div>

          <div className="md:w-44  lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              {/* <img
                src={paper2}
                alt="Example"
                className="absolute top-7 right-6  md:w-32 lg:w-56 2xl:w-96 object-cover"
              /> */}
               <div className="flex justify-center items-center">
              <img
                src={paper2}
                alt="Example"
                className="  md:w-32 lg:w-56 2xl:w-80 object-cover"
              />
              </div>
            </div>
            <h2 className="md:text-md lg:text-lg font-medium font-display mt-10 mr-24 text-left 2xl:text-left 2xl:mt-0 2xl:text-xl">
              Engagement
            </h2>
            <p className="md:text-md  font-display lg:text-lg 2xl:text-xl">
              Highly interactive and engaging, fostering collaboration and
              knowledge sharing.
            </p>
          </div>
          <div className="md:w-44 lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              {/* <img
                src={group2}
                alt="Example"
                className="absolute top-7 right-6  md:w-32 lg:w-56 2xl:w-96 object-cover"
              /> */}
               <div className="flex justify-center items-center">
              <img
                src={group2}
                alt="Example"
                className=" md:w-32 lg:w-56 2xl:w-80 object-cover"
              />
              </div>
            </div>
            <h2 className="lg:text-lg md:text-md font-medium 2xl:text-xl font-display mt-10 text-left 2xl:mt-0  2xl:text-left lg:mr-1 md:mr-0">
              Real-world Application
            </h2>
            <p className="md:text-md font-display 2xl:text-xl lg:text-lg">
              Designed for real world application, with actionable insights you
              can implement immediately.
            </p>
          </div>
          <div className="md:w-44 lg:w-1/4 h-10/12 bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg relative flex-shrink-0">
            <div className="w-full md:h-20 2xl:h-72 lg:h-40  ">
              {/* <img
                src={Mission2}
                alt="Example"
                className="absolute top-7 right-6  md:w-32 lg:w-56  2xl:w-96 object-cover"
              /> */}
               <div className="flex justify-center items-center">
              <img
                src={Mission2}
                alt="Example"
                className="md:w-32 lg:w-56  2xl:w-80 object-cover"
              />
              </div>  
            </div>
            <h2 className="md:text-md lg:text-lg font-medium font-display text-left 2xl:text-left mt-10 2xl:mt-0 mr-10 2xl:text-xl">
              Strategic Guidance
            </h2>
            <p className="md:text-md font-display lg:text-lg 2xl:text-xl">
              Our workshop helps business leaders align AI initiatives with
              business objectives.
            </p>
          </div>
        </div>
      </div>

      <div className="block md:hidden relative h-5/6  flex flex-col justify-start bg-custom-gradient-workshop1  bg-cover bg-center">
        <div className="pt-16 px-4 md:px-8 text-center text-white">
          <h1 className="text-sm lg:text-lg font-semibold font-display text-white ">
            We understand navigating AI can feel complex. That’s why our
            corporate AI trainings apply the following first principles:
          </h1>
        </div>
        <div
          id="scroll-container"
          className="mt-10 px-8 flex  gap-4 overflow-x-auto scroll-smooth pb-32"
          onMouseMove={handleMouseMove}
        >
          <div className="  w-7/12  bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <div className="  ">
              <img
                src={girl2}
                alt="Girl"
                className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
              />
            </div>

            <h2 className="text-sm font-medium  text-left font-display mt-10 ">
              Industry Experts
            </h2>
            <p className="font-display text-sm">
              Led by industry experts who speak your language and understand
              your challenges.
            </p>
          </div>
          <div className="w-7/12     bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <div className="hidden static">
              <img
                src={square}
                alt="Square"
                className="absolute top-44 right-2 w-10 h-10 object-cover"
              />
            </div>
            <img
              src={paper2}
              alt="Paper"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 text-left">
              Engagement
            </h2>
            <p className="font-display text-sm mb-5">
              Highly interactive and engaging, fostering collaboration and
              knowledge sharing.
            </p>
          </div>
          <div className="w-7/12   bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <img
              src={group2}
              alt="Group"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 text-left ">
              Real-world Application
            </h2>
            <p className="font-display text-sm">
              Designed for real-world application, with actionable insights you
              can implement immediately.
            </p>
          </div>
          <div className="w-7/12     bg-[#2C43A3] text-white p-6 rounded-lg shadow-lg  flex-shrink-0">
            <img
              src={Mission2}
              alt="Mission"
              className="static  w-40 md:top-7 md:right-5 ml-2 mt-2 md:w-60 object-cover z-10"
            />

            <h2 className="text-sm font-medium font-display mt-10 mr-3 text-left">
              Strategic Guidance
            </h2>
            <p className="font-display text-sm mb-6">
              Our workshop helps business leaders align AI initiatives with
              business objectives.
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col md:flex-row bg-white ">
        <div className="flex-1 flex items-center justify-left  md:mb-44 lg:mb-64">
          <img
            src={box}
            alt="Description"
            className="md:w-72 h-auto  w-44 object-cover"
          />
          <img
            src={lady}
            alt="Overlay"
            className="hidden md:block absolute  w-9/12 md:w-8/12 lg:w-7/12 2xl:w-5/12  md:mt-80 lg:mt-96 lg:pt-28 lg:ml-16 "
          />
          <img
            src={lady}
            alt="Overlay"
            className="block md:hidden absolute  mt-32 lg:mt-96 lg:pt-28  pl-20  "
          />
          <div className="absolute   bg-[#5D5DFF] pt-3 pr-8 md:pr-8 pl-2 pb-3 md:ml-12 lg:ml-20 mb-32">
            <p className="text-sm  lg:text-2xl text-left font-bold font-display  text-white ">
              WHO IS THIS PROGRAM <br />
              TAILORED FOR?
            </p>
          </div>
        </div>

        <div className="flex-1  items-start   md:mt-32  lg:mt-24 2xl:mt-16  lg:mr-16 2xl:mr-80 px-4 md:px-0 mt-10">
          <h1 className="md:text-sm lg:text-[15.55px] font-bold 2xl:text-lg  font-display text-[#C62B6D] ">
            C-Suite Executives
          </h1>
          <p className="md:text-[12px] lg:text-[15.55px] font-display 2xl:text-base  text-[#666666] mt-2 leading-tight text-xs">
            Chart a clear course for your organization's AI journey, make
            informed strategic decisions, and position your company at the
            forefront of innovation. By understanding the business value of AI
            and how it aligns with your goals, you'll be better equipped to
            drive growth and maintain a competitive edge.
            <br />
            <br /> Includes Chief Executive Officer, President, Managing
            Director, Chief Financial Officer, Chief Operating Officer, Chief
            Technology Officer, Chief Human Resources Officer.
          </p>

          <div className="w-auto mt-8 mx-auto relative">
            <img src={line1} alt="Line Image" className="w-auto h-0.5" />
          </div>
          <h1 className="md:text-sm lg:text-[15.55px] font-bold mt-8 font-display  2xl:text-lg  text-[#C62B6D]  ">
            Business Function Heads
          </h1>
          <p className=" md:text-[12px] lg:text-[15.55px] font-display  2xl:text-base  text-[#666666] mt-2 leading-tight text-xs">
            Whether you're in marketing, sales, operations, HR, or IT, our
            workshops equip you with the insights and tools needed to leverage
            AI for maximum impact within your department. From optimizing
            processes to enhancing customer experiences, we've got you covered.
            <br />
            <br /> Vice President, Director, Heads of Departments.
          </p>
          <div className="w-auto mt-8 mx-auto relative">
            <img src={line1} alt="Line Image" className="w-auto h-0.5" />
          </div>

          <h1 className="md:text-sm lg:text-[15.55px] font-bold mt-6  2xl:text-lg font-display text-[#C62B6D] ">
            Senior Managers
          </h1>
          <p className="md:text-[12px] lg:text-[15.55px] font-display  2xl:text-base text-[#666666] mt-3 leading-tight text-xs mb-20 md:mb-0 ">
            Drive organizational change from within. Our AI training for
            managers will provide you with the knowledge and resources to
            spearhead AI initiatives, foster a culture of innovation, and
            empower your team to embrace the future with confidence.
          </p>
        </div>
      </div>
      <div className="hidden md:block relative h-full flex flex-col justify-start bg-[#E6EBF8] ">
        <div className="pt-8 lg:px-0 md:text-left  2xl:ml-56 items-start ml-10 ">
          <h1 className="md:text-2xl lg:text-5xl font-bold font-display text-[#666666]">
            Choose Your Program
          </h1>
        </div>
        <div className="lg:justify-center flex flex-col  md:flex-row md:px-2 lg:px-0">
          <div className="bg-white md:w-64 lg:w-96 2xl:w-3/12 h-5/6 mt-10 m-6   rounded-2xl shadow-lg   ">
            <img src={workk10} alt="AI Integration" className="  mt-[-10px]" />
            <div className="overflow-auto h-72  mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm lg:mt-10  pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold ">Duration:</span>{" "}
                  <span className="text-[#666666]">3 hours</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">General AI awareness</span>
                  <br />
                  <br />
                </span>
                <span className="lg:font-display text-[15.55px] text-[#666666] leading-tight">
                  In just three hours, our AI awareness workshop will provide
                  your team with a comprehensive introduction to AI, creating a
                  solid foundation for further exploration. Perfect for busy
                  professionals seeking a quick yet impactful learning
                  experience.
                </span>
              </p>
            </div>

            <div className="flex justify-center w-full  ">
              <button className="lg:mt-20 mt-2 mb-12 lg:mb-14 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg " onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>

          <div className="bg-white md:w-64 lg:w-96 h-5/6 2xl:w-3/12  mt-10  lg:mr-5 mb-4 lg:mb-4  rounded-2xl shadow-lg ">
            <img src={workk11} alt="AI Integration" className=" mt-[-10px]  " />
            <div className="overflow-auto h-72  mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm mt-3 pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">1 day or 2 half days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Build a solid foundational understanding of AI’s concepts
                    and applications.
                  </span>
                  <br />
                  <br />
                </span>
                <span className="lg:font-display text-[#666666] lg:text-[15.55px] leading-tight">
                  The AI Fundamentals workshop covers AI basics, its
                  transformative power, real-world applications, hands-on
                  experiences, ethical considerations, and organizational
                  opportunities.
                  <br />
                  Through this workshop, participants gain insight into AI’s
                  impact on their roles, fostering a culture of continuous
                  learning, innovation, and cross-functional collaboration. By
                  empowering staff with AI knowledge, the workshop lays a solid
                  foundation for future AI-driven initiatives, contributing to
                  organizational growth and adaptation in the rapidly evolving
                  AI landscape.
                  <br />
                </span>
              </p>
            </div>

            <div className="flex justify-center w-full">
              <button className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"  onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>

          <div className="bg-white  md:w-64 lg:w-96 h-5/6 2xl:w-3/12  mt-10  lg:mr-5 mb-4 lg:mb-4  ml-4 rounded-2xl shadow-lg">
            <img src={workk12} alt="AI Integration" className=" mt-[-10px]" />
            <div className="overflow-auto h-72 mt-5 custom-scrollbar">
              <p className="lg:ml-5 ml-5 text-sm mt-3 pb-5 p-4">
                <span className="lg:font-display lg:text-base ">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">2 days </span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    {" "}
                    Strategic application of AI in business
                  </span>
                  <br />
                  <br />
                </span>
                <span className="lg:font-display lg:text-[15.55px] ">
                  <span className="font-bold text-[#666666]">
                    Day 1 focuses on laying the groundwork for understanding AI
                    in the context of business.
                    <br />
                    <br />{" "}
                  </span>
                  <span className="text-[#666666] leading-tight">
                    This workshop equips business leaders with a fundamental
                    understanding of AI, its real-world applications, and its
                    impact across industries. You’ll explore machine learning
                    basics, discover how AI optimizes decision-making, enhances
                    experiences, and boosts operational efficiency. Gain
                    insights into data quality’s crucial role and ethical
                    considerations surrounding AI adoption.
                  </span>{" "}
                  <br />
                  <br />
                  <span className="font-bold text-[#666666]">
                    Day 2 shifts the focus to aligning AI initiatives with
                    business goals. <br />
                    <br />
                  </span>{" "}
                  <span className="text-[#666666] leading-tight">
                    Learn how to align AI initiatives with strategic objectives,
                    assess organizational readiness, and foster an
                    innovation-driven culture. Explore AI tools and gain
                    hands-on experience through interactive sessions and leave
                    with a concrete framework for scaling AI within your
                    organization
                  </span>{" "}
                </span>
              </p>
            </div>

            <div className="flex justify-center w-full">
              <button className="lg:mt-20 mt-2 mb-12 pt-5 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg" onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="block md:hidden relative h-full flex flex-col justify-start bg-[#E6EBF8]">
        <div className="pt-8 text-left ml-4  ">
          <h1 className="text-2xl lg:text-5xl font-semibold font-display">
            Choose Your Program
          </h1>
        </div>

        <div className="flex flex-row overflow-x-auto px-4 mt-0  space-x-4 pb-20">
          <div className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img src={workk10} alt="AI Integration" className="mt-[-10px]" />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-5 pb-5">
                <span className="lg:font-display text-xs md:text-base">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">3 hours</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">General AI awareness</span>
                  <br />
                </span>
                <span className="lg:font-display text-xs md:text-[15.55px] text-[#666666] leading-tight">
                  In just three hours, our AI awareness workshop will provide
                  your team with a comprehensive introduction to AI, creating a
                  solid foundation for further exploration. Perfect for busy
                  professionals seeking a quick yet impactful learning
                  experience.
                </span>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button className="lg:mt-16 mt-4 mb-10 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"  onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>

          <div className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img src={workk11} alt="AI Integration" className="mt-[-10px]" />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-3 pb-5">
                <span className="lg:font-display md:text-base text-xs">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">1 day or 2 half days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Build a solid foundational understanding of AI’s concepts
                    and applications.
                  </span>
                  <br />
                </span>
                <span className="lg:font-display text-xs text-[#666666] md:text-[15.55px] leading-tight">
                  The AI Fundamentals workshop covers AI basics, its
                  transformative power, real-world applications, hands-on
                  experiences, ethical considerations, and organizational
                  opportunities.
                  <br />
                  Through this workshop, participants gain insight into AI’s
                  impact on their roles, fostering a culture of continuous
                  learning, innovation, and cross-functional collaboration. By
                  empowering staff with AI knowledge, the workshop lays a solid
                  foundation for future AI-driven initiatives, contributing to
                  organizational growth and adaptation in the rapidly evolving
                  AI landscape.
                  <br />
                </span>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg" onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>

          <div className="flex-shrink-0 bg-white w-72 lg:w-96 h-full mt-10 rounded-2xl shadow-lg">
            <img src={workk12} alt="AI Integration" className="mt-[-10px]" />
            <div className="overflow-y-auto h-64 mt-5 p-4">
              <p className="ml-5 text-sm mt-3 pb-5">
                <span className="lg:font-display md:text-base text-xs">
                  <span className="text-pink-600 font-bold">Duration:</span>{" "}
                  <span className="text-[#666666]">2 days</span>
                  <br />
                  <span className="text-pink-600 font-bold">
                    Location:
                  </span>{" "}
                  <span className="text-[#666666]">Online | On-Site</span>
                  <br />
                  <span className="text-pink-600 font-bold">Focus:</span>{" "}
                  <span className="text-[#666666]">
                    Strategic application of AI in business
                  </span>
                  <br />
                </span>
                <span className="lg:font-display md:text-[15.55px] text-xs ">
                  <span className="font-bold text-[#666666]">
                    Day 1 focuses on laying the groundwork for understanding AI
                    in the context of business.
                    <br />
                    <br />{" "}
                  </span>
                  <span className="text-[#666666] leading-tight">
                    This workshop equips business leaders with a fundamental
                    understanding of AI, its real-world applications, and its
                    impact across industries. You’ll explore machine learning
                    basics, discover how AI optimizes decision-making, enhances
                    experiences, and boosts operational efficiency. Gain
                    insights into data quality’s crucial role and ethical
                    considerations surrounding AI adoption.
                  </span>{" "}
                  <br />
                  <br />
                  <span className="font-bold text-[#666666]">
                    Day 2 shifts the focus to aligning AI initiatives with
                    business goals. <br />
                    <br />
                  </span>{" "}
                  <span className="text-[#666666] leading-tight">
                    Learn how to align AI initiatives with strategic objectives,
                    assess organizational readiness, and foster an
                    innovation-driven culture. Explore AI tools and gain
                    hands-on experience through interactive sessions and leave
                    with a concrete framework for scaling AI within your
                    organization
                  </span>{" "}
                </span>
              </p>
            </div>
            <div className="flex justify-center w-full">
              <button className="lg:mt-20 mt-2 mb-12 bg-[#C62B6D] border border-none text-white font-bold py-4 px-10 rounded-lg"  onClick={handleButtonClickcontact}>
                Enroll Now
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white h-full lg:h-auto">
  <div className="justify-center items-center flex flex-col md:flex-row lg:pr-12 lg:pl-0 mt-16 2xl:mb-10">
    <img
      src={workk13}
      alt="AI Integration"
      className="w-96 h-5/6 2xl:w-3/12 lg:ml-32 2xl:ml-72 2xl:pt-10 lg:pt-16"
    />

    <div className="container 2xl:mr-56 lg:mb-16 mt-6 lg:mt-12 px-4 lg:px-0">
      <form onSubmit={handleSubmitbrochure} className="max-w-xl mx-auto">
        <p className="text-3xl lg:text-5xl lg:pb-5 font-display text-[#C62B6D] font-bold">
          Download Brochure
        </p>

        <div className="mb-6 mt-6">
          <label className="block font-display font-medium text-[#666666] text-base mb-2">
            Full Name
          </label>
          <input
            type="text"
            name="fullnamebrochure"
            value={fullnamebrochure}
            onChange={(e) => setfullnamebrochure(e.target.value)}
            className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
            placeholder="Enter your name"
            required
          />
          {errorsbrochure.fullnamebrochure && (
            <p className="text-red-500 text-sm">{errorsbrochure.fullnamebrochure}</p>
          )}
        </div>

        <div className="flex -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block font-display font-medium text-[#666666] text-base mb-2">
              Email
            </label>
            <input
              type="email"
              name="emailbrochure"
              value={emailbrochure}
              onChange={(e) => setemailbrochure(e.target.value)}
              className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
              placeholder="Email"
              required
            />
            {errorsbrochure.emailbrochure && (
              <p className="text-red-500 text-sm">{errorsbrochure.emailbrochure}</p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block font-display font-medium text-[#666666] text-base mb-2">
              Job Title
            </label>
            <input
              type="text"
              name="jobtitlebrochure"
              value={jobtitlebrochure}
              onChange={(e) => setjobtitlebrochure(e.target.value)}
              className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
              placeholder="Job Title"
              required
            />
            {errorsbrochure.jobtitlebrochure && (
              <p className="text-red-500 text-sm">{errorsbrochure.jobtitlebrochure}</p>
            )}
          </div>
        </div>

        <div className="flex -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block font-display font-medium text-[#666666] text-base mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companynamebrochure"
              value={companynamebrochure}
              onChange={(e) => setcompanynamebrochure(e.target.value)}
              className="w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
              placeholder="Company Name"
              required
            />
            {errorsbrochure.companynamebrochure && (
              <p className="text-red-500 text-sm">{errorsbrochure.companynamebrochure}</p>
            )}
          </div>

        
          <div className="w-full md:w-1/2 px-3">
  <label className="block font-display font-medium text-[#666666] text-base mb-2">
    Country
  </label>
  <input
    type="text"
    name="countrybrochure"
  
    value={countrybrochure}
    onChange={(e) => {
      const { value } = e.target;
      // Allow only alphabets (a-z, A-Z)
      if (/^[A-Za-z]*$/.test(value)) {
        setcountrybrochure(value); // Update state if valid
      }
    }}
    className="rounded w-full py-4 px-3 bg-[#FFEAEA] text-gray-700"
    placeholder="Country"
    required
  />
  {errorsbrochure.countrybrochure && (
    <p className="text-red-500 text-sm">{errorsbrochure.countrybrochure}</p>
  )}
</div>

        </div>

        <div className="flex items-center justify-between lg:mt-12 mb-12 lg:mb-0">
          <button
            type="submit"
            className="bg-[#3E59B7] hover:bg-white hover:border-2 hover:text-[#666666] rounded-lg text-white w-44 font-bold py-3 px-4 font-display focus:outline-none focus:shadow-outline"
          >
            Download
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


     





      <div className="flex justify-center relative bg-[#FCF6F9]">
        <div className="w-full max-w-screen-lg 2xl:max-w-screen-xl md:p-10 2xl:px-10  p-4 pt-7 relative">
          <p className="font-semibold text-[#666666] font-display md:text-2xl lg:text-4xl relative z-10">
            A Power House Team Of Renowned Industry Experts With Exceptional
            Real-World Experience.
          </p>

          <div className="flex  flex-row relative z-10">
            <img
              src={workk14}
              alt="AI Integration"
              className="lg:mt-10 mt-12 md:w-full lg:w-full w-40   md:max-w-xs  object-contain   lg:mr-5 "
            />
            <img
              src={line}
              alt="Line"
              className="md:h-20 h-10    mt-12  lg:ml-0  md:mt-10"
            />
            <img
              src={workk15}
              alt="AI Integration"
              className="lg:mt-10 lg:w-60 md:w-64 lg:h-auto object-contain w-32  mt-12"
            />
          </div>

          <div className=" pt-5 relative z-10">
            <span className=" md:text-base 2xl:text-lg text-[#666666] font-display leading-tight text-sm">
              Academy Xi- Random Walk’s official training delivery partner.
              Headquartered in Sydney, Australia, Academy Xi boasts an elite
              team of industry experts renowned for their profound domain
              knowledge and practical insights.
              <br />
              <br />
            </span>
          </div>

          <div className=" relative z-10">
            <span className="text-[#666666] 2xl:text-lg lg:text-base font-display text-sm">
              Together, we bridge the gap between theory and real-world
              application, ensuring our workshops deliver actionable insights
              that drive business impact.
            </span>
          </div>

          <div className="flex pt-10 flex-col md:flex-row  relative z-10">
            <div className="flex items-center">
              <img
                src={workk16}
                alt="AI Integration"
                className="mt-4 w-10 md:w-16 lg:h-auto"
              />
              <p className="ml-4 pr-10  text-sm md:text-base 2xl:text-lg text-[#666666] font-display">
                Academy Xi is a proud member of the Tech Council of Australia.
              </p>
            </div>

            <div className="flex items-center  lg:ml-0 lg:mt-0">
              <img
                src={workk17}
                alt="AI Integration"
                className="mt-4 w-10 md:w-16 lg:h-auto"
              />
              <p className="ml-2  text-sm md:text-base  2xl:text-lg text-[#666666] font-display mt-5">
                Academy Xi is a network partner of Australian Cyber Security
                Center.
              </p>
            </div>
          </div>
        </div>
        <img
          src={topright}
          alt="Top Right Image"
          className="absolute top-0 right-0 w-44 h-auto mt-5  z-0"
        />
        <img
          src={blue}
          alt="Top Right Image"
          className="absolute  top-0 right-0 w-44 h-auto mt-5  z-0"
        />
        <img
          src={bottomright}
          alt="Top Right Image"
          className="absolute bottom-0  right-0 w-44 h-auto  z-0"
        />
      </div>

      <div className="flex justify-center relative mt-10">
        <div className="w-full max-w-screen-lg mx-4 lg:mx-0">
          <div className="w-full lg:w-auto">
            <p className="font-bold text-[#666666] font-display md:text-4xl lg:ml-32 mb-5 text-left">
              Meet Your Instructors
            </p>
            <img
              src={pink}
              alt="Top Right Image"
              className="absolute top-0 right-0 w-1/3 h-auto z-0"
            />

            <div className="flex flex-col lg:flex-col">
              <div className="flex lg:flex-row md:items-center md:justify-center">
                <img
                  src={workk18}
                  alt="AI Integration"
                  className="mt-4 md:ml-12 md:mt-0  md:w-auto md:h-auto hidden md:block"
                />
                <img
                  src={workk18}
                  alt="AI Integration"
                  className=" w-32  h-full md:hidden"
                />

                <div className="bg-white w-auto h-auto md:p-5 md:py-20  p-2 shadow-2xl z-10 md:px-4 lg:px-10  2xl:px-20 mt-10 md:mt-0">
                  <p className="text-sm font-display  text-[#666666] ">
                    <span className="font-bold text-[#666666]  justify-center text-center lg:text-lg 2xl:text-xl">
                      Amir Ansari
                    </span>
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666]">
                      CXO and Head of Applied AI at Academy Xi, Australia
                    </span>
                    <br />
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] hidden md:block">
                      With over 25+ years of industry <br />
                      experience, he’s a seasoned strategist
                      <br /> in AI, serving as a university advisor,
                      <br /> international keynote speaker, and <br />
                      mentor for corporates and founders.
                      <br />
                      Former Global Head of Product Design <br />& user
                      experience, he specializes in <br />
                      crafting growth plans for organizations <br />
                      worldwide.
                    </span>
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] block md:hidden">
                      With over 25+ years of industry 
                      experience, he’s a seasoned strategist
                       in AI, serving as a university advisor,
                       international keynote speaker, and 
                      mentor for corporates and founders.
                      
                      Former Global Head of Product Design & user
                      experience, he specializes in 
                      crafting growth plans for organizations 
                      worldwide.
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex flex-row md:flex-row md:items-center  justify-center mt-4 lg:mt-8 mb-20 md:mb-0 ">
                <img
                  src={pinkblue}
                  alt="Top Right Image"
                  className="absolute left-0 bottom-0 w-1/3 h-auto z-0"
                />

                <div className="bg-white  lg:w-auto h-auto md:p-5 md:py-20 shadow-2xl z-10 md:px-4 lg:px-12 p-2  2xl:px-20  md:mt-0 mt-10">
                  <p className="text-sm font-display text-[#666666]">
                    <span className="font-bold md:text-lg text-[#666666] :text-xl">
                      Steven HK Ma
                    </span>
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666]">
                      Executive Business Mentor
                    </span>
                    <br />
                    <br />
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] hidden md:block">
                      Steven H.K. brings extensive industry <br />
                      experience, having led as CEO in the <br />
                      Fast 50 Rising star and served as
                      <br /> Director of Agility in sectors including
                      <br /> finance, transportation, aviation, and <br />
                      defense. Executive business mentor with deep
                      <br /> expertise in AI.
                    </span>
                    <span className="md:text-sm text-xs 2xl:text-lg text-[#666666] block md:hidden">
                      Steven H.K. brings extensive industry 
                      experience, having led as CEO in the 
                      Fast 50 Rising star and served as
                       Director of Agility in sectors including
                       finance, transportation, aviation, and 
                      defense. Executive business mentor with deep
                       expertise in AI.
                    </span>
                  </p>
                </div>
                <img
                  src={workk19}
                  alt="AI Integration"
                  className="w-32 h-full  md:hidden"
                />
                <img
                  src={workk19}
                  alt="AI Integration"
                  className="mt-4 lg:mt-0 mb-10 lg:w-auto lg:h-auto hidden md:block z-10"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

 
        <div className=" bg-no-repeat  bg-cover lg:px-24 md:px-10 rounded-2xl 2xl:w-auto 2xl:mx-40 ">
        <div className="container mx-auto p-4">
          <div className="text-center  md:text-2xl lg:text-2xl font-grayy lg:my-2 lg:pb-3 font-bold font-display  text-[#C62B6D]  2xl:text-3xl ">
            Frequently Asked Questions
          </div>
          <DropdownList />
        </div>
      </div>



      



      
      <div
        className="hidden md:block lg:hidden relative h-96 flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <div className="absolute inset-0 bg-[#3E59B7] opacity-30"></div>

        <img
          src={workk21}
          alt="Overlay"
          className="absolute top-0 right-0 lg:w-3/5 w-10/12 h-96"
        />

        <div className="absolute right-0 top-20 transform -translate-y-1/2 z-20  p-8 lg:w-1/2">
          <p className="text-gray-700 mb-4 md:ml-3 mt-3 lg:pt-10 text-left pl-56 md:text-left ">
            <span className="font-display text-white font-bold max-w-xs lg:text-xl  ">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display md:text-sm  text-xs text-white">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today.
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white">
              Schedule a free consultation with us to discuss your unique needs
              and discover how our Executive AI Workshops can empower your
              organization to drive growth.
            </span>
          </p>

          <form
            onSubmit={handleSubmitconsultation}
            className="absolute lg:right-0 md:right-10 mx-auto mt-0 lg:mt-10"
          >
            <div className="mb-0 ">
              <label
        
                className="block text-white font-display font-semibold text-sm mb-2 "
              >
                Full Name
              </label>
              <input
                type="text"
              required
                name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
               {errorsconsultation.fullnameconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.fullnameconsultation}</p>}
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  htmlFor="email"
                  className="block text-white  font-display font-semibold text-sm mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                  {errorsconsultation.emailconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.emailconsultation}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="number"
                  className="block text-white font-display font-semibold text-sm mb-2"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
        
                  name="phonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(e) => setphonenumberconsultation(e.target.value)}
                  pattern="[0-9]{10}"
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                  {errorsconsultation.phonenumberconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.phonenumberconsultation}</p>}
              </div>
            </div>

            <div className="flex items-center justify-left">
              <button
                type="submit"
                className="bg-pink-600 mb-10  lg:text-md hover:bg-blue-700 rounded-md  text-white font-bold py-4 px-4 focus:outline-none focus:shadow-outline"
              >
                Schedule Consultation
              </button>
            </div>
          </form>
          
        </div>


        {isSuccessOpenconsultation && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModalconsultation}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModalconsultation}>
                  Close
                </button>
              </div>
            </div>
          )}
      </div>
      <div
        className="hidden md:hidden lg:block relative h-screen flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <div className="absolute inset-0 bg-[#3E59B7] opacity-30"></div>

        <img
          src={workk21}
          alt="Overlay"
          className="absolute top-0 right-0 w-3/5 h-screen"
        />

        <div className="absolute right-0 top-44 transform -translate-y-1/2 z-20 p-8 lg:w-1/2">
          <p className="text-gray-700 mb-4 ml-3 mt-3 lg:pt-10">
            <span className="font-display text-white font-bold text-xl lg:text-xl 2xl:text-2xl ">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display text-sm text-white 2xl:text-lg  ">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today.

              Schedule a free consultation with us to discuss your unique needs
              and discover how our Executive AI Workshops can empower your
              organization to drive growth.
            </span>
          </p>

          <form onSubmit={handleSubmitconsultation} className="absolute   mx-auto mt-10">
            <div className="mb-4 ">
              <label
                htmlFor="name"
                className="block text-white font-display font-semibold text-sm mb-2 2xl:text-lg "
              >
                Full Name
              </label>
              <input
                type="text"
                required
                name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded w-full lg:py-2 lg:px-3 2xl:py-4 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
               {errorsconsultation.fullnameconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.fullnameconsultation}</p>}
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  htmlFor="email"
                  className="block text-white  font-display font-semibold text-sm 2xl:text-lg mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full lg:py-2 lg:px-3 2xl:py-4 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorsconsultation.emailconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.emailconsultation}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
         
                  className="block text-white font-display font-semibold text-sm 2xl:text-lg mb-2"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
              
                  name="phonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(e) => setphonenumberconsultation(e.target.value)}
                  pattern="[0-9]{10}"
                  className="shadow appearance-none border rounded w-full lg:py-2 lg:px-3 2xl:py-4 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                {errorsconsultation.phonenumberconsultation&& <p className='text-red-500 text-sm'>{errorsconsultation.phonenumberconsultation}</p>}
              </div>
            </div>

            <div className="flex items-center justify-left mt-8">
              <button
                type="submit"
                className="bg-pink-600 mb-10 lg:pr-20 lg:pl-20 2xl:pr-24 2xl:pl-24 lg:text-md 2xl:text-lg hover:bg-blue-700 rounded-md  text-white font-bold py-4 px-4 focus:outline-none focus:shadow-outline"
              >
                Schedule Consultation
              </button>
            </div>
          </form>
        </div>

        {isSuccessOpenconsultation && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModalconsultation}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModalconsultation}>
                  Close
                </button>
              </div>
            </div>
          )}
      </div>

      <div
        className="block md:hidden relative h-96 flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url(${workk20})` }}
      >
        <div className="absolute inset-0 bg-[#3E59B7] opacity-30"></div>

        <img
          src={workk21}
          alt="Overlay"
          className="absolute top-0 right-0 lg:w-3/5 w-10/12 h-96"
        />

        <div className="absolute right-0 top-44  transform -translate-y-1/2 z-20 p-8 lg:w-2/5 text-left mt-24 pb-2">
          <p className="text-gray-700 mb-4 pl-28">
            <span className="font-display text-white font-bold lg:text-xl text-sm  text-left block">
              Ready To Start? Schedule A Personal Consultation
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white block">
              Don’t wait until your competitors gain the AI advantage. Take the
              first step today.
              <br />
            </span>
            <span className="font-display md:text-sm text-xs text-white block">
              Schedule a free consultation with us to discuss your unique needs
              and discover how our Executive AI Workshops can empower your
              organization to drive growth.
            </span>
          </p>

          <form onSubmit={handleSubmitconsultation} className=" ">
            <div className=" pl-24">
              <label
                htmlFor="name"
                className="block text-white font-display font-light text-sm mb-2"
              >
                Full Name
              </label>
              <input
                type="text"
                required
               name="fullnameconsultation"
                value={fullnameconsultation}
                onChange={(e) => setfullnameconsultation(e.target.value)}
                className="shadow appearance-none border rounded  py-1 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
               {errorsconsultation.fullnameconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.fullnameconsultation}</p>}
            </div>

            <div className="flex flex-row mb-6 pl-20">
              <div className="w-full md:w-1/2 px-3  md:mb-0 ">
                <label
                  htmlFor="email"
                  className="block text-white font-display font-light text-sm mb-0"
                >
                  Email
                </label>
                <input
                  type="email"
                  required
                  name="emailconsultation"
                  value={emailconsultation}
                  onChange={(e) => setemailconsultation(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-1 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                   {errorsconsultation.emailconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.emailconsultation}</p>}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  htmlFor="number"
                  className="block text-white font-display font-light text-sm mb-0"
                >
                  Number
                </label>
                <input
                  type="tel"
 
                  name="nhonenumberconsultation"
                  value={phonenumberconsultation}
                  onChange={(e) => setphonenumberconsultation(e.target.value)}
                  pattern="[0-9]{10}"
                  className="shadow appearance-none border rounded w-full py-1 px-3 bg-red-50 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  required
                />
                  {errorsconsultation.phonenumberconsultation && <p className='text-red-500 text-sm'>{errorsconsultation.phonenumberconsultation}</p>}
              </div>
            </div>

            <div className="flex items-center justify-center  mb-44 ml-12 ">
              <button
                type="submit"
                className="bg-pink-600 text-sm hover:bg-blue-700 rounded-md text-white font-bold py-2 px-2 focus:outline-none focus:shadow-outline"
              >
                Schedule Consultation
              </button>
            </div>
          </form>
        </div>
        {isSuccessOpenconsultation && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModalconsultation}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModalconsultation}>
                  Close
                </button>
              </div>
            </div>
          )}
      </div>



      

      <div>
          {isModalOpen && (
            <div className='fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-[#F1F6FF]   p-8 rounded-lg max-h-screen   max-w-md w-full'>
                <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={closeModal}>
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                {/* <div className='text-lg md:text-xl text-center font-bold lg:mb-4'>
                  Experience the Power of Data <br />
                  With
                  <span className='text-[#C62B6D] font-bold'> AI Fortune </span>
                  Cookie
                </div> */}
                 <form onSubmit={handleSubmitcontact}>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>First Name</label>
                    <input
                      type='text'
                      name='firstname'
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.firstname && <p className='text-red-500 text-sm'>{errors.firstname}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Last Name</label>
                    <input
                      type='text'
                      name='lastname'
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.lastname && <p className='text-red-500 text-sm'>{errors.lastname}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Phone Number</label>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass='custom-phone-input2'
                      buttonClass='border rounded'
                      dropdownClass='border rounded'
                    />
                    {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='text-gray-700'>Company Name</label>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Business Email</label>
                    <input
                      type='email'
                      name='businessemail'
                      value={businessemail}
                      onChange={(e) => setEmailId(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.businessemail && <p className='text-red-500 text-sm'>{errors.businessemail}</p>}
                  </div>
                
              

                  <div className='mb-4'>
                    <label className=' text-gray-700'>Project Details</label>
                    <input
                      type='text'
                      name='projectdetails'
                      value={projectdetails}
                      onChange={(e) => setprojectdetails(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <button type='submit' className='w-full px-4 py-2 bg-[#C62B6D] text-white rounded'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>


















        
    </div>
  );
};

export default Workshop;
