import React, { useEffect, useMemo, useState, useRef } from "react";
import { Client, Databases, ID } from "appwrite";
import styles from "../styles/ContactSection.module.css";

import { useNavigate } from "react-router";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const ContactSection = ({ visible, onClose, onSubmit }) => {
 


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');

  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
   
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    }
   
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID7,
        ID.unique(),
        {
          name,
          designation,
          companyName,
          phoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      // Navigate to a FC App in new tab with query params email and name
      // and set cookie access-token with eY....
      // document.cookie = `access-token=${process.env.REACT_APP_JWT_TOKEN}`;
      // const redirectUrl = `${process.env.REACT_APP_FORM_REDIRECT_URL}/?name=${name}&email=${email}`;
      // window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };























  const signupRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #signup
    if (window.location.hash === '#signup') {
      signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);





  return (
    <section className={styles.contactSection}>
      <div className={styles.content}>
        <h2 className={styles.heading}>
          Unlock the Power of AI <br />
          Talk to our experts
        </h2>
        <p className={styles.description}>
          Schedule your Free Demo and <br />
          Experience the power of BrandCut AI
        </p>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles["input-container"]}>
                    <label htmlFor="name" className={styles.placeholder}>Name</label>
                    <input
                      type='text'
                      name='name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className={styles.input}
                      required
                    />
                          {errors.name && <p className={styles.error}>{errors.name}</p>}
                  </div>
                  <div className={styles["input-container"]}>
                    <label htmlFor="email" className={styles.placeholder}>Email Address</label>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(e) => setEmailId(e.target.value)}
                      className={styles.input}
                      required
                    />
                    {errors.email && <p className={styles.error}>{errors.email}</p>}
                  </div>
                  <div className={styles["number-container"]}>
                    <label htmlFor="phonenumber" className={styles.placeholder}>Phone Number</label>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputStyle={{
                        width: "100.4%",
                        borderRadius: "5px",
                        border: "none",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                        fontWeight: "500",
                        fontFamily: "inherit",
                      
                      }}
                      buttonStyle={{
                        border: "none",
                        borderRadius: "5px",
                        height: "100%",
                        backgroundColor: "transparent",
                        color: "black",
                      }}
                      containerStyle={{ width: "100%" }}
                      inputProps={{
                        name: "phoneNumber",
                        required: true,
                 
                      }}
                  
                    />
                    {errors.phoneNumber && <p className={styles.error}>{errors.phoneNumber}</p>}
                  </div>
                  <div className={styles["input-container"]}>
                    <label className={styles.placeholder}>Designation</label>
                    <input
                      type='text'
                      name='designation'
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className={styles.input}
                      required
                    />
                    {errors.designation && <p className={styles.error}>{errors.designation}</p>}
                  </div>

                  <div className={styles["input-container"]}>
                    <label className={styles.placeholder}>Company Name</label>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className={styles.input}
                      required
                    />
                    {errors.companyName && <p className={styles.error}>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4 mt-6'>
                    <button type='submit' className='w-full px-32 py-2 bg-[#C62B6D] text-white rounded'>
                      Submit
                    </button>
                  </div>
                </form>

                {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4 text-black'>Success!</h2>
                <p className='mb-4 text-black'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
      {/* <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles["input-container"]}>
          <label htmlFor="name" className={styles.placeholder}>
            Name
          </label>
          <input
            type="text"
            id="name"
            className={styles.input}
            value={name}
            onChange={handleNameChange}
            ref={nameRef}
          />
        </div>
        {errors.name && <p className={styles.error}>{errors.name}</p>}

        <div className={styles["input-container"]}>
          <label htmlFor="companyName" className={styles.placeholder}>
            Company Name
          </label>
          <input
            type="text"
            id="companyName"
            className={styles.input}
            value={companyName}
            onChange={handleCompanyNameChange}
            ref={companyNameRef}
          />
        </div>
        {errors.companyName && (
          <p className={styles.error}>{errors.companyName}</p>
        )}

        <div className={styles["number-container"]}>
          <label htmlFor="phoneNumber" className={styles.placeholder}>
            Mobile Number
          </label>
          <PhoneInput
            country={"in"}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputStyle={{
              width: "106.4%",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              fontWeight: "500",
              fontFamily: "inherit",
              color: "white",
            }}
            buttonStyle={{
              border: "none",
              borderRadius: "5px",
              height: "100%",
              backgroundColor: "transparent",
              color: "white",
            }}
            containerStyle={{ width: "100%" }}
            inputProps={{
              name: "phoneNumber",
              required: true,
              autoFocus: true,
            }}
            ref={phoneNumberRef}
          />
        </div>
        {errors.phoneNumber && <p className={styles.error}>{errors.phoneNumber}</p>}

        <div className={styles["input-container"]}>
          <label htmlFor="email" className={styles.placeholder}>
            Email Id
          </label>
          <input
            type="email"
            id="email"
            className={styles.input}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            ref={emailRef}
          />
        </div>
        {errors.email && <p className={styles.error}>{errors.email}</p>}

        <div className={styles["checkbox-container"]}>
          <input
            type="checkbox"
            id="consent"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="consent" className={styles.checkboxLabel}>
            I agree to the <a href="#">terms and conditions</a>
          </label>
        </div>

        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form> */}
      
    </section>
  );
};

export default ContactSection;
