import React, { useState, useEffect } from "react";
import { Client, Databases, ID } from "appwrite";
import styles from "../styles/LeadForm.module.css";
import close from "../assets/close.svg";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const LeadForm = ({ visible, onClose, onSubmit }) => {
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmailId] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');
  const [errors, setErrors] = useState({});

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);
  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
   
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    }
   
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID7,
        ID.unique(),
        {
          name,
          designation,
          companyName,
          phoneNumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      
      onClose(); // Close the form
      setIsSuccessOpen(true); // Show success modal
      onSubmit();  
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const resetForm = () => {
    setName('');
    setDesignation('');
    setCompanyName('');
    setPhoneNumber('');
    setEmailId('');
    setErrors({});
  };

  const closeForm = () => {
    resetForm();  // Clear form data
    onClose();    // Close the form modal
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  if (!visible && !isSuccessOpen) return null;

  return (
    <div>
      {visible && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={closeForm}>
              <img className={styles.closeIcon} src={close} alt="Close" />
            </button>
            <h2>
              Unlock Your Demo
              <br />
              <span className={styles.highlight}>in Just 15 Seconds!</span>
            </h2>

            <form onSubmit={handleSubmit}>
              {/* Form Fields */}
              <div className="mb-4">
                <label className="text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              </div>
              <div className="mb-4">
                <label className="text-gray-700">Email Address</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label className="text-gray-700">Phone Number</label>
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  inputClass="custom-phone-input2"
                  buttonClass="border rounded"
                  dropdownClass="border rounded"
                />
                {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
              </div>
              <div className="mb-4">
                <label className="text-gray-700">Designation</label>
                <input
                  type="text"
                  name="designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.designation && <p className="text-red-500 text-sm">{errors.designation}</p>}
              </div>
              <div className="mb-4">
                <label className="text-gray-700">Company Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                {errors.companyName && <p className="text-red-500 text-sm">{errors.companyName}</p>}
              </div>
              <div className="mb-4">
                <button type="submit" className="w-full px-4 py-2 bg-[#C62B6D] text-white rounded">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isSuccessOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
            <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={closeSuccessModal}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4 text-black">Success!</h2>
            <p className="mb-4 text-black">Your request has been sent successfully.</p>
            <button className="px-4 py-2 bg-[#C62B6D] text-white rounded" onClick={closeSuccessModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadForm;
