
import React from "react";
import { useParams, Link } from "react-router-dom";

import "./style_kms.css";

import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import recright from "../assets/Home/recright.png";

import rightarrow from "../assets/Blogs/rightarrow.png";

import kms from "../assets/BlogCategory/kmsheader.png";
import rectangle from "../assets/BlogCategory/rectangle.png";
import cv from "../assets/BlogCategory/cvheader.png";
import ai from "../assets/BlogCategory/aiheader.png";
import other from "../assets/BlogCategory/otherheader.png";
// import rightarrow from "../assets/BlogCategory/rightarrow.png";
import Popup from "../components/popup.js";
import white from "../assets/Blogs/white.png";



const isCardSmall = (index) => {
  const normalizedIndex = index % 9;
  return (
    normalizedIndex === 3 || normalizedIndex === 7 || normalizedIndex === 8
  );
};


export const BlogCategory = ({ blogs }) => {
  const { category } = useParams();

  // Filter blogs by category

  const filteredBlogs = blogs.filter((blog) => blog.blogCategory === category);

  // Sort filtered blogs by date to get the latest blog
  const sortedBlogs = filteredBlogs.sort(
    (a, b) => new Date(b.blogDate) - new Date(a.blogDate)
  );


  // Separate the latest blog from the rest of the blogs
  const latestBlog = sortedBlogs.length > 0 ? sortedBlogs[0] : null;
  const otherBlogs = sortedBlogs.slice(1);

  // Group blogs by category
  const groupedBlogs = {};

  blogs.forEach((blog) => {
    if (!groupedBlogs[blog.blogCategory]) {
      groupedBlogs[blog.blogCategory] = [];
    }
    groupedBlogs[blog.blogCategory].push(blog);
  });



  const renderKMSHeader = () => (
    <div className="flex flex-col lg:pb-10 2xl:py-12 pt-24  md:pt-24 lg:pt-28 2xl:pt-32 font-display justify-center items-center p-5 bg-gradient_background">
      <div className="flex  flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
        <div className="text-container ">
          <p className="text-white text-lg lg:text-xl mb-1">Blog</p>
          <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
            KMS and RAG
          </span>
          <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
            Read blogs on KMS and RAG to gain insights on latest strategies,
            best practices, and innovations that drive efficient knowledge
            sharing to transform your organization's data management and
            decision-making processes.
          </span>
        </div>

        <div className=" ">
          <img
            src={kms}
            alt="Descriptive Alt Text"
            className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "

          />
        </div>
      </div>


      <div className="  2xl:px-10 md:pt-5 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full  md:gap-3 lg:gap-16 w-auto gap-5 ">
        <Link
          to="/blogs"
          className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"

        >
          Home Page
        </Link>
        {Object.keys(groupedBlogs)
          .filter((cat) => cat !== category)
          .map((cat) => (
            <Link
              key={cat}
              to={`/blogcategory/${cat}`}

              className="bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5  md:px-6 md:py-4 lg:py-5 lg:px-5 2xl:py-5 2xl:px-2 w-full "

            >
              {cat}
            </Link>
          ))}
      </div>
    </div>
  );

  const renderComputerVisionHeader = () => (

    <div className="flex flex-col lg:pb-10 2xl:py-12 pt-24  md:pt-24 lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background2">
      <div className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
        <div className="text-container ">
          <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
          <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
            Computer Vision
          </span>
          <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
            Read blogs on computer vision to learn about the latest advancements
            and applications in visual AI services. Explore how deep learning,
            image recognition and object detection are enhancing automation and
            driving efficiency in various sectors
          </span>
        </div>

        <div className=" ">
          <img
            src={cv}
            alt="Descriptive Alt Text"
            className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "

          />
        </div>
      </div>

      <div className='  2xl:px-10 md:pt-1 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16 md:gap-3 w-auto gap-5 '>
        <Link
          to='/blogs'
          className='bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full'
        >
          Home Page
        </Link>
        {Object.keys(groupedBlogs)
          .filter((cat) => cat !== category)
          .map((cat) => (
            <Link
              key={cat}
              to={`/blogcategory/${cat}`}
              className='bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5 md:px-6 md:py-4  lg:py-5 lg:px-3 2xl:py-5 2xl:px-2 w-full '
            >
              {cat}
            </Link>
          ))}
      </div>
    </div>
  );

  const renderAITrainingHeader = () => (

    <div className="flex flex-col pt-24 lg:pb-10 2xl:py-12 md:pt-24 lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background">
      <div className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
        <div className="text-container ">
          <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
          <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
            AI Training and AI Readiness
          </span>
          <span className="text-white text-xs md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
            Read blogs on AI training for insights into organizational AI
            readiness, executive training, and corporate AI training programs.
            Learn how these initiatives prepare leaders and equip your team with
            the latest AI skills.
          </span>
        </div>

        <div className=" ">
          <img
            src={ai}
            alt="Descriptive Alt Text"
            className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-56 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "

          />
        </div>
      </div>


      <div className="  2xl:px-10 md:pt-4 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16 w-auto gap-5 gap-x-8">
        <Link
          to="/blogs"
          className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"

        >
          Home Page
        </Link>
        {Object.keys(groupedBlogs)
          .filter((cat) => cat !== category)
          .map((cat) => (
            <Link
              key={cat}
              to={`/blogcategory/${cat}`}

              className="bg-white bg-opacity-25  bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-8  md:px-6 md:py-4 lg:py-5 lg:px-8 2xl:py-5 2xl:px-2 w-full "

            >
              {cat}
            </Link>
          ))}
      </div>
    </div>
  );

  const renderOthersHeader = () => (

    <div className="flex flex-col lg:pb-10 2xl:py-12 md:pt-24 pt-20  lg:pt-28 2xl:pt-32  font-display justify-center items-center p-5 bg-gradient_background2">
      <div className="flex flex-col md:px-20 lg:px-0  space-x-32 items-start md:flex-row md:items-end md:justify-between">
        <div className="text-container ">
          <p className="text-white text-xl lg:text-xl mb-1">Blog</p>
          <span className="text-white text-xl md:text-3xl lg:text-3xl font-semibold block md:mb-2">
            Others
          </span>
          <span className="text-white text-xs  md:text-base  md:pb-5 lg:pb-0 lg:text-lg md:block max-w-[400px] lg:block hidden">
            Explore our blogs for a comprehensive look into the latest trends,
            innovations, and applications of AI across various industries. Stay
            informed on AI and its transformative impact on technology
          </span>
        </div>

        <div className=" ">
          <img
            src={other}
            alt="Descriptive Alt Text"
            className="md:w-auto md:h-64 -translate-x-16 md:-translate-x-0 h-60 py-3 md:py-0 md:pb-10 lg:pb-0 lg:h-72  "

          />
        </div>
      </div>


      <div className="  2xl:px-10 md:pt-5 pt-5 lg:pt-20 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:w-full lg:gap-16  md:gap-3 w-auto gap-5 ">
        <Link
          to="/blogs"
          className="bg-white bg-opacity-25 bg-white/50  text-white text-xs rounded-2xl lg:text-base flex items-center justify-center  w-full"

        >
          Home Page
        </Link>
        {Object.keys(groupedBlogs)
          .filter((cat) => cat !== category)
          .map((cat) => (
            <Link
              key={cat}
              to={`/blogcategory/${cat}`}

              className="bg-white bg-opacity-25 lg:text-nowrap bg-white/50 text-white text-xs rounded-2xl lg:text-base flex items-center justify-center py-4 px-5 md:px-6 md:py-4 lg:py-5 lg:px-3 2xl:py-5 2xl:px-2 w-full "

            >
              {cat}
            </Link>
          ))}
      </div>
    </div>
  );



  const renderCategoryHeader = () => {
    switch (category) {
      case "KMS AND RAG":
        return renderKMSHeader();
      case "Computer Vision":
        return renderComputerVisionHeader();
      case "AI Training and AI Readiness":
        return renderAITrainingHeader();
      case "Others":

        return renderOthersHeader();
      default:
        return null;
    }
  };

  return (
    <>
      {renderCategoryHeader()}

      <div className="w-full p-5 flex justify-center">
        <div className="max-w-[1000px] mx-auto pb-10">
          {/* Latest Blog */}
          <div className="flex justify-center text-[#666666] items-center">
            <div className="mb-8 p-4 w-full">
              {latestBlog && (
                <Link
                  key={latestBlog.id}
                  to={`/blogs/${latestBlog.id}`}
                  className="block"
                >
                  <div className="bg-white rounded-lg overflow-hidden drop-shadow-xl w-full md:h-80 md:flex relative">
                
                    <img
                      className=" md:w-7/12 h-full" // Set the height and width here
                      src={`${latestBlog.coverImg.url}`}
                      alt={latestBlog.blogTitle}
                    />
                    <div className="p-4 relative flex-grow">
                      <h3 className="text-lg font-display font-bold  my-5 md:my-0 lg:my-5">
                        {latestBlog.blogTitle}
                      </h3>
                      <div
                        className="text-[#666666] font-display text-sm my-5 overflow-hidden"
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 7,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {latestBlog.blogDesc}
                      </div>
                     
                    </div>
                    <img
                      src={rightarrow}
                      alt="Right Arrow"
                      className="absolute bottom-3 right-2 w-4 h-4"
                    />

                  </div>
                </Link>
              )}
            </div>
          </div>


          {/* Other blogs */}
          <div className="category-container">
            <div className="card-grid">
              {otherBlogs.map((blog, index) => {
                const cardClass = isCardSmall(index)
                  ? "card-small"
                  : "card-big";


                return (
                  <div key={blog.id} className={cardClass}>
                    <Link
                      to={`/blogs/${blog.id}`}

                      className="bg-white rounded-xl overflow-hidden drop-shadow-xl h-[100%] flex flex-col relative"
                    >
                      <img
                        className="object-cover h-52"
                        src={`${blog.coverImg.url}`}
                        alt={blog.blogTitle}
                      />
                      {isCardSmall(index) ? (
                        <div className="absolute top-0 left-0 bg-black bg-opacity-50 text-white px-2 py-1 rounded-lg font-bold">
                          {blog.blogTitle}
                        </div>
                      ) : null}
                      {isCardSmall(index) ? null : (
                        <div className="p-4">
                          <h3 className="text-lg text-[#666666] font-display font-bold my-3">
                            {blog.blogTitle}
                          </h3>
                          <p
                            className="text-[#666666] font-display text-sm my-3 overflow-hidden"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 5,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {blog.blogDesc}

                          </p>
                        </div>
                      )}
                      <img

                        src={isCardSmall(index) ? white : rightarrow}
                        alt="Right Arrow"
                        className="absolute bottom-3 right-3 w-4 h-4"

                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div

        className="text-[#666666] min-h-[140px] md:min-h-[230px] relative bg-no-repeat bg-cover"
        style={{
          background: "linear-gradient(100deg , #3E59B7 90%, #A6BAFF 110%)",

        }}
      >
        <div className='absolute bottom-0 right-0'>
          <img src={recright} alt='Additional' className='lg:w-40 lg:h-auto w-24 h-auto md:w-40 md:h-full' />
        </div>

        <div className="mt-20">

          <Popup />
        </div>
      </div>
    </>



  );
};
