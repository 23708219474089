
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import homebg from "../assets/Home/homebg.png";
import home1 from "../assets/Home/home1.png";
import home2 from "../assets/Home/home2.png";
import home3 from "../assets/Home/home3.png";
import homebg3 from "../assets/Home/homebg3.png";
import homebg4 from "../assets/Home/homebg4.png";
import homebg4img from "../assets/Home/homebg4img.png";
import homebg4img2 from "../assets/Home/homebg4img2.png";
import home4bubble from "../assets/Home/home4bubble.png";
import home6bubble from "../assets/Home/home6bubble.png";
import homebg5 from "../assets/Home/homebg5.png";
import homebg5img1 from "../assets/Home/homebg5img1.png";
import homebg5img from "../assets/Home/homebg5img.png";
import home5bubble from "../assets/Home/home5bubble.png";
import homebg6 from "../assets/Home/homebg6.png";
import homebg6img from "../assets/Home/homebg6img.png";
import homebg6img2 from "../assets/Home/homebg6img2.png";
import homebg7 from "../assets/Home/homebg7.png";
import bg1 from "../assets/Home/bg1.png";
import bg2 from "../assets/Home/bg2.png";
import bg3 from "../assets/Home/bg3.png";
import bg5 from "../assets/Home/bg5.png";
import rightsideimg from "../assets/Home/rightsideimg.png";
import leftsideimg from "../assets/Home/leftsideimg.png";
import rightsideimg2 from "../assets/Home/rightsideimg2.png";
import homeflowmobile2 from "../assets/Home/homeflowmobile2.png";
import bg from "../assets/Home/bg.png";
import recright from "../assets/Home/recright.png";
import homeflow from "../assets/Home/homeflow.png";
import yelloww1 from "../assets/Home/yellow1.png";
import yelloww2 from "../assets/Home/yellow2.png";
import yelloww3 from "../assets/Home/yellow3.png";
import yelloww4 from "../assets/Home/yellow4.png";


import yelloww5 from "../assets/Home/yellow5.png";
import { Client, Databases, ID } from "appwrite";

// import home from "../assets/Home/home.gif";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import homee from '../assets/Home/home2.svg';
import workbg from '../assets/Home/work-bg.png';
import workk14 from '../assets/Home/work14.svg';
import workk15 from '../assets/Home/work15.png';
import workk16 from '../assets/Home/work16.png';
import workk17 from '../assets/Home/work17.png';
import workk18 from '../assets/Home/work18.png';
import workk19 from '../assets/Home/work19.png';
import workbgg from '../assets/Home/work-bg.png';
import workbgg2 from '../assets/Home/work-bg2.png';
import workbgg3 from '../assets/Home/work-bg3.png';
import Popup from '../components/popup.js';
const PartnerPopup = ({ onClose }) => {
  return (
    <div className='popup'>
      <div className='popup-content'>
        <span className='close' onClick={onClose}>
          &times;
        </span>
        <p>Thank you for your interest in becoming a partner!</p>
      </div>
    </div>
  );
};

export const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [isSuccessOpenintegration, setIsSuccessOpenintegration] = useState(
    false
  );
  const [productname, setproductname] = useState("");
  const [businessurl, setbusinessurl] = useState("");
  const [name, setname] = useState("");
  const [aiproduct, setaiproduct] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [businessemail, setbusinessemail] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmId, setUtmId] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmContent, setUtmContent] = useState("");


  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setphonenumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmitcontact = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID5,
        ID.unique(),
        {
          name,
          productname,
          businessurl,
          aiproduct,
          companyname,
          phonenumber,
          businessemail,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setShowPopup(false);
      setIsSuccessOpen(true);
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');

  const [companynameint, setcompanynameint] = useState('');
  const [phonenumberint, setphonenumberint] = useState('');
  const [emailid, setemailid] = useState('');
  const [utmSourceintegration, setUtmSourceintegration] = useState('');
  const [utmMediumintegration, setUtmMediumintegration] = useState('');
  const [utmCampaignintegration, setUtmCampaignintegration] = useState('');
  const [utmIdintegration, setUtmIdintegration] = useState('');
  const [utmTermintegration, setUtmTermintegration] = useState('');
  const [utmContentintegration, setUtmContentintegration] = useState('');

  const [errorsintegration, setErrorsintegration] = useState({});

  const handleButtonClickintegration = () => {
    setIsModalOpen(true);
  };

  const closeModalintegration = () => {
    setIsModalOpen(false);
  };

  const clientintegration = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databasesintegration = new Databases(client);

  const handlePhoneNumberChangeintegration = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setphonenumberint(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSourceintegration(searchParams.get('utm_source') || '');
    setUtmMediumintegration(searchParams.get('utm_medium') || '');
    setUtmCampaignintegration(searchParams.get('utm_campaign') || '');
    setUtmIdintegration(searchParams.get('utm_id') || '');
    setUtmTermintegration(searchParams.get('utm_term') || '');
    setUtmContentintegration(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmitintegration = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID6,
        ID.unique(),
        {
          firstname,

          lastname,
          companynameint,
          phonenumberint,
          emailid,
          utmSourceintegration,
          utmMediumintegration,
          utmCampaignintegration,
          utmIdintegration,
          utmTermintegration,
          utmContentintegration,
        }
      );
      // Close the modal and show success message
      setShowPopup(false);
      setIsSuccessOpenintegration(true);
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModalintegration = () => {
    setIsSuccessOpenintegration(false);
  };

   
  const [blogs, setBlogs] = useState(null);


useEffect(() => {
  fetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/data.json`
  )
    .then((response) => response.json())
    .then((data) => {
      // Check if the dates are valid and sort them
      const sortedBlogs = data.sort((a, b) => {
        const dateA = new Date(a.blogDate); // Use the blogDate field
        const dateB = new Date(b.blogDate); // Use the blogDate field
        return dateB - dateA; // Sort in descending order
      });

      setBlogs(sortedBlogs);
    })
    .catch((error) => console.error('Error fetching blogs:', error));
}, []);



  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('');

  const togglePopup = (type) => {
    setShowPopup(!showPopup);
    setPopupType(type);
  };

  const [imageHeight, setImageHeight] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageHeight(img.height);
    };
    img.src = homeflow;
  }, []);

  const [showExtraContent, setShowExtraContent] = useState(false);
  const toggleExtraContent = () => {
    setShowExtraContent(!showExtraContent);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const toggleDropdown3 = () => {
    setIsOpen3(!isOpen3);
  };

  const toggleDropdown4 = () => {
    setIsOpen4(!isOpen4);
  };

  const toggleDropdown5 = () => {
    setIsOpen5(!isOpen5);
  };

  return (
    <>


      <div className="body font-display cus4:container cus4:mx-auto ">

        {showPopup && (
          <div className='fixed font-display top-0 left-0 w-full h-full flex justify-center items-center z-50'>
            <div className='bg-black bg-opacity-50 absolute inset-0'></div>

            {popupType === 'integration' && (
              <div className='fixed font-display top-0 left-0 w-full h-full flex justify-center items-center z-50'>
                <div className='bg-black bg-opacity-50 absolute inset-0'></div>
                <div className='bg-white p-5 w-full max-w-lg rounded-2xl shadow-lg relative flex flex-col lg:flex-row mx-2'>
                  {popupType === 'integration' && (
                    <div className='flex-1'>
                      <button onClick={() => togglePopup('')} className='absolute top-2 right-2 z-10 text-gray-500'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                        </svg>
                      </button>

                      <p className='font-display font-bold mb-3 text-lg text-center'> Integration Request</p>
                      <div className='border border-gray-300 mb-3 w-full'></div>

                      <form className='flex flex-col items-center' onSubmit={handleSubmitintegration}>
                        <div className='mb-4 w-full'>
                          <label htmlFor='firstName' className='block text-gray-700 text-sm mb-2'>
                            First Name
                          </label>
                          <input

                            type="text"
                           

                            required
                            name='firstName'
                            value={firstname}
                            onChange={(e) => setfirstname(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter First Name'
                          />
                        </div>
                        <div className='mb-4 w-full'>
                          <label htmlFor='lastName' className='block text-gray-700 text-sm mb-2'>
                            Last Name
                          </label>
                          <input

                            type="text"
                           

                            required
                            name='lastName'
                            value={lastname}
                            onChange={(e) => setlastname(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Last Name'
                          />
                        </div>
                        <div className='mb-4 w-full'>
                          <label htmlFor='phoneNumber' className='block text-gray-700 text-sm mb-2'>
                            Phone Number
                          </label>
                          <input
                            type='tel'
                            required
                            name='phonenumberint'
                            value={phonenumberint}

                            onChange={(e) =>
                              handlePhoneNumberChangeintegration(e.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter Phone Number"

                          />
                        </div>
                        <div className='mb-4 w-full'>
                          <label htmlFor='companyName' className='block text-gray-700 text-sm mb-2'>
                            Company Name
                          </label>
                          <input
                            type='text'
                            minLength={5}
                            maxLength={30}
                            required
                            name='companynameint'
                            value={companynameint}
                            onChange={(e) => setcompanynameint(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Company Name'
                          />
                        </div>
                        <div className='mb-4 w-full'>
                          <label className='block text-gray-700 text-sm mb-2'>Business Email</label>
                          <input
                            type='email'
                            required
                            name='emailid'
                            value={emailid}
                            onChange={(e) => setemailid(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Email Address'
                          />
                        </div>
                        <button
                          type='submit'
                          className='bg-gray-500 text-white px-20 py-2 rounded hover:bg-blue-600 w-full max-w-xs'
                        >
                          Send
                        </button>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            )}

            {popupType === 'partner' && (
              <div className='fixed inset-0 flex  font-display items-center justify-center bg-gray-800 bg-opacity-50 p-4 lg:px-40 lg:py-10 '>
                <div className='bg-white border border-gray-300 rounded-lg flex-1 flex flex-col shadow-md p-4  relative w-full lg:w-[80%] lg:h-auto overflow-y-auto lg:overflow-block max-h-[100%]'>
                  <button onClick={togglePopup} className='absolute top-2 right-2 z-10 text-gray-700'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='h-6 w-6'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
                    </svg>
                  </button>
                  <h2 className='text-xl text-center font-bold mb-4'>Become a Partner</h2>
                  <div className='border border-gray-300 mb-3 w-full'></div>

                  <div className='flex flex-col lg:flex-row'>
                    <div className='lg:w-1/2 flex flex-col justify-center items-center mb-4 lg:mb-0'>
                      <div className='flex flex-row items-center mb-4'>
                        <img src={yelloww1} alt='Image 1' className='w-12 h-12 mr-2' />
                        <div className='lg:mr-5 lg:ml-4'>
                          <div className='text-lg font-bold mb-2'>Seamless Integration</div>
                          <div className='text-sm'>
                            Eliminate the complexity and technical challenges associated with integration and focus more
                            on your tool's functionalities.
                          </div>
                        </div>
                      </div>

                      <div className='flex flex-row items-center mb-4'>
                        <img src={yelloww2} alt='Image 2' className='w-12 h-12 mr-2' />
                        <div className='lg:mr-5 lg:ml-4'>
                          <div className='text-lg font-bold mb-2'>Expanded Market Reach</div>
                          <div className='text-sm'>
                            We actively promote and market your tools through our channels including targeted marketing
                            campaigns, events and industry partnerships.
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-row items-center mb-4'>
                        <img src={yelloww3} alt='Image 3' className='w-12 h-12 mr-2' />
                        <div className='lg:mr-5 lg:ml-4'>
                          <div className='text-lg font-bold mb-2'>Business Development Support</div>
                          <div className='text-sm'>
                            Discover a collaborative environment where complementary tools and technologies can be
                            integrated.
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-row items-center mb-4'>
                        <img src={yelloww4} alt='Image 4' className='w-12 h-12 mr-2' />
                        <div className='lg:mr-5 lg:ml-4'>
                          <div className='text-lg font-bold mb-2'>Synergistic Collaboration</div>
                          <div className='text-sm'>
                            Eliminate the complexity and technical challenges associated with integration and focus more
                            on your tool's functionalities.
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-row items-center mb-4'>
                        <img src={yelloww5} alt='Image 5' className='w-12 h-12 mr-2' />
                        <div className='lg:mr-5 lg:ml-4'>
                          <div className='text-lg font-bold mb-2'>Increased Brand Exposure</div>
                          <div className='text-sm'>
                            Random Walk’s platform allows tools to gain exposure to a wider audience, increasing brand
                            recognition.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='lg:w-1/2 flex justify-center items-center'>
                      <form className='w-full lg:w-3/4' onSubmit={handleSubmitcontact}>
                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Product Name</label>
                          <input

                            type="text"
                    

                            required
                            name='productname'
                            value={productname}
                            onChange={(e) => setproductname(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Product Name'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Business URL</label>
                          <input
                            type='url'
                            required
                            name='businessurl'
                            value={businessurl}
                            onChange={(e) => setbusinessurl(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='https://example.com'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>About AI Product</label>
                          <input
                            type='text'
                            required
                            name='aiproduct'
                            value={aiproduct}
                            onChange={(e) => setaiproduct(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter about AI product'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Name</label>
                          <input
                            type='text'
                            required
                            name='name'
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter your name'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Company Name</label>
                          <input
                            type='text'
                            required
                            name='companyname'
                            value={companyname}
                            onChange={(e) => setcompanyname(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Company Name'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Business Email</label>
                          <input
                            type='email'
                            required
                            name='businessemail'
                            value={businessemail}
                            onChange={(e) => setbusinessemail(e.target.value)}
                            className='shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                            placeholder='Enter Business Email'
                          />
                        </div>

                        <div className='mb-4'>
                          <label className='block text-gray-700 text-sm mb-2'>Phone Number</label>
                          <input
                            type='tel'
                            required
                            name='phonenumber'
                            value={phonenumber}

                            onChange={(e) =>
                              handlePhoneNumberChange(e.target.value)
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter Phone Number"

                          />
                        </div>

                        <div className='flex justify-center'>
                          <button
                            type='submit'
                            className='bg-gray-500 text-white lg:px-40 px-20 py-2 rounded hover:bg-blue-600'
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div className=' font-display  bg-[#3E59B7]  md:py-12 lg:py-16 flex flex-col md:flex-row justify-start items-center 2xl:justify-center px-12 md:px-0'>
          <div className=' flex items-center  mt-24 md:mt-10 lg:mt-0 justify-center  text-left md:px-10 lg:px-16 md:text-left'>
            <div className='text-white'>
              <h2 className='text-xl text-left md:text-2xl md:mt-5   lg:text-4xl 2xl:text-6xl font-bold md:mb-6 mb-3'>
                Maximize Your Business Potential With Seamless AI Integration.
              </h2>
              <p className='md:text-xs text-xs lg:text-lg 2xl:text-xl  font-medium'>
                With our Artificial Intelligence consulting services, you now have the ability to choose from an entire
                gamut of AI tools to deliver quick results.
              </p>
            </div>
          </div>

          <div className='max-md:hidden  md:mt-12  md:w-11/12 lg:w-full 2xl:w-10/12  md:mx-16 lg:mx-16'>
            <img src={homee} alt='Home Background 2' className='' />
          </div>

          <div className='block md:hidden max-w-80 pb-4 w-80 md:p-3'>
            <img src={homee} alt='Home Background 2' className='max-w-full' />
          </div>
        </div>

        <div className='font-bold md:hidden text-lg pt-5 md:text-xl lg:text-4xl 2xl:text-4xl text-[#666666] px-12 '>
          Leading The World’s AI Integration
        </div>

        <div className='md:hidden block text-center md:text-left font-display overflow-x-scroll scrollbar-hide mt-5'>
          <div className='w-[200%] h-[100%] inline-block'>
            <img src={homeflowmobile2} alt='Descriptive Alt Text' className='' />
          </div>
        </div>

        <div className='text-center  max-md:hidden md:text-left font-display'>
          <div className='font-bold text-xl md:text-xl lg:text-4xl 2xl:text-4xl text-[#666666] pt-10 md:pt-12 2xl:mt-20 md:px-12 lg:px-24 2xl:px-40'>
            Leading The World’s AI Integration
          </div>

          <div className='flex flex-col md:flex-row justify-center md:justify-between mt-5 lg:mt-8 px-4 md:px-10 lg:px-20 2xl:px-36'>
            <div className='flex-1 text-base md:text-xs lg:text-base 2xl:text-xl lg:text-md text-[#666666] px-2 lg:px-4'>
              AI tools to choose with expert guidance from Random Walk. We collaborate and implement custom solutions
            </div>
            <div className='flex-1 px-2 text-base md:text-xs lg:text-base 2xl:text-xl lg:text-md text-[#666666] lg:px-4 mt-4 md:mt-0'>
              We provide AI integration. Our engineers are experts at fast tracking integration between any software and
              AI tools.
            </div>
            <div className='flex-1 px-2 lg:px-4 text-base lg:text-base 2xl:text-xl md:text-xs lg:text-md text-[#666666] mt-4 md:mt-0'>
              From business functions like marketing, HR and finance to different industries like retail and pharma,
              find the right AI tool.
            </div>
          </div>

          <div className='md:-mt-5 lg:-mt-10 lg:px-5 2xl:px-16  '>
            <img src={home2} alt='Descriptive Alt Text' className='' />
          </div>
        </div>

        <div className='hidden  md:-mt-10 lg:-mt-20  md:block'>
          <div className='  bg-[#EBF7FF] 2xl:-mt-0 md:pb-80  2xl:pb-72 h-max bg-cover bg-no-repeat '>
            <div className='md:flex   2xl:pt-44 2xl:pb-72 md:mb-10 lg:pb-20 flex-row md:flex-row md:pt-10 lg:pt-28  md:gap-10 lg:gap-28 2xl:gap-52 md:px-24 md:justify-center md:items-center md:max-w-full '>
              <div className='md:bg-white   md:p-6 md:rounded-2xl md:px-8 md:w-[100%] md:h-[400px] lg:h-auto lg:scale-110 2xl:w-[500px] 2xl:h-[350px]'>
                <div>
                  <div className='md:text-sm text-[#666666] 2xl:text-base lg:text-base md:font-display md:pb-4 md:pt-7'>
                    If you are an AI tools provider,
                  </div>
                  <div className='lg:text-2xl text-[#C62B6D] md:text-sm md:font-semibold md:pb-5 md:font-display'>
                    Make It Easy To Choose
                    <br /> Your Tools
                  </div>
                  <div className='md:text-sm 2xl:text-base  text-[#666666] md:font-display md:pb-5 2xl:pb-5'>
                    Work with Random Walk to create an integration for your product, and while our customers are
                    building software with us, we can recommend your solution.
                  </div>
                </div>
                <button
                  onClick={() => togglePopup('partner')}
                  className='md:hover:bg-[#C62B6D] md:hover:text-white md:hover:border-[#C62B6D] md:border-[#C62B6D] md:mt-3 md:mb-3 md:font-display md:bg-[#C62B6D] md:text-white md:font-bold md:py-2 md:px-5 md:rounded md:text-xs lg:text-sm'
                >
                  Become a Partner
                </button>
              </div>

              <div className='md:bg-white    md:p-6 md:rounded-2xl lg:ml-4 md:px-8 md:w-[100%] lg:w-[100%] lg:max-w-[1200px] md:h-[400px] lg:h-auto lg:scale-110 2xl:w-[500px] 2xl:h-[350px]'>
                <div className='text-[#666666]'>
                  <div className='md:text-sm text-[#666666]  2xl:text-base lg:text-base md:font-display md:pt-7'>
                    If you are a client looking for AI capabilities,
                  </div>
                  <div className='lg:text-2xl text-[#C62B6D] md:text-sm md:pb-5 md:font-semibold md:font-display md:pt-4'>
                    Begin Your AI Journey
                    <br /> Now
                  </div>
                  <div className='md:text-sm 2xl:text-base text-[#666666] md:font-display md:pb-1'>
                    Let us work with you to integrate niche AI tools into your day-to-day business functions like HR,
                    marketing, and finance. We also cater various industry-specific AI tools to aid operations.
                  </div>
                </div>
                <button
                  onClick={() => togglePopup('integration')}
                  className='md:hover:bg-[#C62B6D] md:text-xs md:hover:text-white md:hover:border-[#C62B6D] md:border-[#C62B6D] md:mt-3 md:mb-3 md:font-display md:bg-[#C62B6D] md:text-white md:font-bold md:py-2 md:px-5 md:rounded lg:text-sm'
                >
                  Create Integration Request
                </button>
              </div>
            </div>
          </div>
          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle3 scale-x-100'></div>
          </div>
          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle4  scale-x-100'></div>
          </div>
          <div className='  bg-[#FFEBF4]   h-max bg-cover bg-no-repeat mt-2 '>
            <div className='absolute flex   2xl:-translate-y-12 flex-wrap items-center justify-center relative md:-top-64 lg:-top-72 '>
              <div className='lg:ml-20 flex  items-center relative  '>
                <div className='lg:w-auto lg:mr-20 md:px-12 2xl:translate-x-32 2xl:w-7/12 lg:-mt-20 2xl:-mt-10 md:w-8/12'>
                  <img src={homebg4img} alt='Description of Image' />
                </div>

                <img
                  src={home6bubble}
                  alt='Bubble Background'
                  className='absolute md:w-5/12 lg:w-6/12  2xl:w-4/12 md:top-32 md:right-0 lg:top-40 lg:right-0'
                />


                <div className="flex bg-white opacity-75 border-2 2xl:ml-80  border-white flex-col 2xl:w-full md:w-full z-40 bg-cover rounded-xl bg-no-repeat relative">
                  <div className="absolute top-0 right-0 ml-6  lg:ml-0 md:w-16 lg:w-28 lg:-mt-16 h-auto">
                    <img src={rightsideimg} alt="Description of Image" />
                  </div>

                  <div className="flex flex-col lg:pl-24 md:p-10 lg:py-16">
                    <div className="text-[#666666] font-display md:text-xl lg:text-3xl  2xl:text-4xl font-semibold md:pb-3 lg:pb-5">
                      AI Integration
                    </div>
                    <div className="text-[#666666] text-xs z-50 font-display md:text-xs  2xl:text-lg lg:text-base md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                      With our extensive experience, we’ve perfected the skill
                      of managing different AI tools and software. Our expertise
                      lies in seamless AI integration, including ChatGPT
                      integration & more, into various systems with precision.
                      Trust us for a smooth AI integration services, which
                      starts with AI consulting services that ensures flawless
                      implementation and deliver evident results.

                    </div>
                  </div>
                  <div className='absolute md:-bottom-3 md:right-10 lg:bottom-0 lg:right-10 m-4 group'>
                    <div className='relative md:w-20 md:h-20 lg:w-32 lg:h-32 overflow-hidden'>
                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                        <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>

                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                        <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' absolute relative text-grayy    pt-32   2xl:translate-y-[0px]  bg-no-repeat bg-cover md:-top-48 lg:-top-52 2xl:ml-40 '>
              <div className='flex flex-wrap items-center justify-center'>
                <div className='flex items-center '>
                  <img
                    src={home5bubble}
                    alt='Bubble Background'
                    className='absolute   md:w-6/12 lg:w-5/12 2xl:w-5/12  md:-top-0 md:left-0 lg:-top-12  2xl:-top-32 lg:left-0'
                  />

                  <div className='relative flex bg-white 2xl:-translate-x-40 opacity-60 z-40 flex-col w-full rounded-xl border-white border-b border-t'>
                    <div className='absolute left-0 top-0 md:w-24 lg:w-40 2xl:w-44 -mt-5 h-auto max-md:hidden'>
                      <img src={leftsideimg} alt='Overlay Image' className='' />
                    </div>


                    <div className="flex flex-col lg:pl-40 2xl:pl-40 md:p-10 lg:py-16">
                      <div className="font-display md:text-xl  text-[#666666] 2xl:text-4xl lg:text-3xl font-semibold md:pb-3 lg:pb-5">
                        Learn Corporate AI <br /> Fundamentals
                      </div>
                      <div className="text-xs font-display md:text-xs text-[#666666]  2xl:text-lg lg:text-sm md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                        Random Walk’s AI learning endeavors to unravel the
                        mysteries surrounding AI, ensuring it’s not a black box.
                        We offer enhanced learning through industry-oriented
                        sessions, employing a seamless mix of learning models.
                        Explore our AI workshops and corporate AI training to
                        empower your employees with valuable skills.

                        <br />
                        <br /> Our AI Training and AI consulting services for executives ensures a straightforward and
                        effective learning experience.
                      </div>

                      <div className='absolute md:bottom-0 md:right-0 lg:bottom-0 lg:right-24 m-4 group'>
                        <div className='relative  md:w-16 md:h-16 lg:w-32 lg:h-32  overflow-hidden'>
                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                            <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>

                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                            <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='lg:w-auto lg:px-20 md:px-12 2xl:w-9/12 md:w-9/12'>
                    <img src={homebg5img} alt='Description of Image' className='' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle3 scale-x-100'></div>
          </div>
          <div className='relative w-full h-0 -translate-y-full '>
            <div className='triangle5  scale-x-100'></div>
          </div>


          <div className="  bg-[#EFECFF]  lg:pb-56 2xl:pb-40 h-max bg-cover bg-no-repeat mt-2 ">
            <div className="text-grayy       bg-no-repeat bg-cover pb-20">
              {/* <div className="flex   flex-wrap items-center justify-center">
                <div className="flex 2xl:gap-0 items-center py-0">
                  <div className="md:px-12  2xl:px-0 md:w-8/12 lg:w-10/12 2xl:w-full 2xl:pr-0  lg:pr-32 ">
                    <img src={homebg6img} alt="Description of Image" />
                  </div>

                  <div className="relative flex opacity-75 z-10  bg-white flex-col bg-cover bg-no-repeat lg:w-full rounded-l-xl border-white border-b border-t   ">

                    <img
                      src={home6bubble}
                      alt='Bubble Background'
                      className='absolute md:w-6/12 lg:w-5/12  2xl:w-7/12 md:top-40 -top-12 lg:top-56 md:right-0 lg:right-20'
                    />


                    <div className="absolute  md:-mt-0 lg:-mt-16 2xl:-mt-10 right-0  ml-6 lg:ml-0 md:w-16 lg:w-28 2xl:w-24 h-auto">
                      <img
                        src={rightsideimg}
                        alt="Description of Image"
                        className=""
                      />
                    </div>
                    <div className="flex flex-col lg:pl-24   md:p-10 lg:py-16">
                      <div className="font-display  text-[#666666] md:text-xl lg:text-3xl 2xl:text-4xl font-semibold md:pb-3 lg:pb-5">
                        AI Managed Services
                      </div>
                      <div className="text-xs font-display text-[#666666]  md:text-xs  2xl:text-lg lg:text-sm max-w-sm md:pb-7 md:text-left">
                        Benefit from our experienced AI professionals, flexible
                        on-demand resources, minimal upfront investment along
                        with rapid development and integration capabilities.

                      </div>
                      <div className='absolute  md:-bottom-5 lg:bottom-0  2xl:right-40 md:right-10 m-4 group'>
                        <div className='relative md:w-20 md:h-20  lg:w-32 lg:h-32 overflow-hidden'>
                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                            <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>

                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                            <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className='absolute flex lg:translate-y-96  2xl:translate-y-96 flex-wrap items-center justify-center relative md:-top-64 lg:-top-72 '>
              <div className='lg:ml-20 flex  items-center relative  '>
                <div className='lg:w-auto lg:mr-20 md:px-12 2xl:translate-x-32 2xl:w-7/12 lg:-mt-20 2xl:-mt-10 md:w-8/12'>
                  <img src={homebg6img} alt='Description of Image' />
                </div>

                <img
                  src={home6bubble}
                  alt='Bubble Background'
                  className='absolute md:w-5/12 lg:w-5/12  2xl:w-4/12 md:top-32 md:right-0 lg:top-40 lg:right-0'
                />


                <div className="flex bg-white opacity-75 border-2 2xl:ml-80  border-white flex-col 2xl:w-full md:w-full z-40 bg-cover rounded-xl bg-no-repeat relative">
                  <div className="absolute top-0 right-0 ml-6  lg:ml-0 md:w-16 lg:w-28 lg:-mt-16 h-auto">
                    <img src={rightsideimg} alt="Description of Image" />
                  </div>

                  <div className="flex flex-col lg:pl-24 md:p-10 lg:py-16">
                    <div className="text-[#666666] font-display md:text-xl lg:text-3xl  2xl:text-4xl font-semibold md:pb-3 lg:pb-5">
                    AI Managed Services
                    </div>
                    <div className="text-[#666666] text-xs z-50 font-display md:text-xs  2xl:text-lg lg:text-base md:max-w-sm 2xl:max-w-2xl md:pb-7 md:text-left">
                    Benefit from our experienced AI professionals, flexible
                        on-demand resources, minimal upfront investment along
                        with rapid development and integration capabilities.

                    </div>
                  </div>
                  <div className='absolute md:-bottom-3 md:right-10 lg:bottom-0 lg:right-10 m-4 group'>
                    <div className='relative md:w-20 md:h-20 lg:w-32 lg:h-32 overflow-hidden'>
                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                        <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>

                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                        <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className='block  -mt-10 md:hidden '>
          <div className='bg-[#EBF7FF] h-max bg-cover bg-no-repeat '>
            <div className='flex overflow-x-auto  scrollbar-hide mb-10 flex-row pt-5 gap-4  px-2  pb-20'>
              <div className='bg-white scale-95  p-6  rounded-lg px-8  w-10/12 h-4/5 lg:h-auto  flex-shrink-0 '>
                <div>
                  <div className='text-sm text-[#666666]  font-display pb-4  pt-7 '>
                    If you are an AI tools provider,
                  </div>
                  <div className=' text-[#C62B6D] text-sm font-bold pb-5 font-display'>
                    Make It Easy To Choose
                    <br /> Your Tools
                  </div>
                  <div className='text-sm text-[#666666] font-display pb-14 '>
                    Work with Random Walk to create an integration for your product, and while our customers are
                    building software with us, we can recommend your solution.
                  </div>
                </div>
                <button
                  onClick={() => togglePopup('partner')}
                  className='hover:bg-[#C62B6D] hover:text-white hover:border-[#C62B6D] border-[#C62B6D]  mt-3 mb-3 font-display bg-[#C62B6D] text-white font-bold py-2 px-5 rounded text-xs '
                >
                  Become a Partner
                </button>
              </div>

              <div className='bg-white  p-4 rounded-lg  px-8 w-11/12 h-4/5 lg:h-auto  flex-shrink-0 mt-2.5'>
                <div className='text-[#666666]'>
                  <div className='text-sm text-[#666666]  font-display pt-7'>
                    If you are a client looking for AI capabilities,
                  </div>
                  <div className='lg:text-2xl text-[#C62B6D] text-smfont-bold font-display pt-4'>
                    Begin Your AI Journey
                    <br /> Now
                  </div>
                  <div className='text-sm text-[#666666] font-display pb-5'>
                    Let us work with you to integrate niche AI tools into your day-to-day business functions like HR,
                    marketing, and finance. We also cater various industry-specific AI tools to aid operations.
                  </div>
                </div>
                <button
                  onClick={() => togglePopup('integration')}
                  className='hover:bg-[#C62B6D] text-xs hover:text-white hover:border-[#C62B6D] border-[#C62B6D] mt-10 mb-4 font-display bg-[#C62B6D] text-white font-bold py-2 px-5 rounded text-xs'
                >
                  Create Integration Request
                </button>
              </div>
            </div>

            <div className='absolute flex   pb-20   flex-col relative -top-20 px-4'>
              <div className='lg:ml-20 flex flex-col items-center relative  '>
                <div className='lg:w-auto mr-10 px-12 w-8/12'>
                  <img src={homebg4img} alt='Description of Image' />
                </div>

                <img
                  src={home6bubble}
                  alt='Bubble Background'
                  className='absolute w-8/12 lg:w-6/12 md:top-32 md:right-0 -left-20  top-60 lg:top-40 lg:right-0 '
                />


                <div className="flex bg-white opacity-75  translate-x-20 mr-32 border-2 border-white flex-col w-full z-40 bg-cover rounded-xl  bg-no-repeat relative mt-6">
                  <div className="absolute -top-52 right-0 ml-6 lg:ml-0 w-20 lg:w-28 lg:-mt-16 h-auto">
                    <img src={rightsideimg} alt="Description of Image" />
                  </div>

                  <div className="flex flex-col lg:pl-24 p-5">
                    <div className="text-[#666666] font-display md:text-xl lg:text-3xl font-bold pb-3 lg:pb-5">
                      AI Integration
                    </div>
                    <div className="text-[#666666] text-xs z-50 font-display md:text-xs lg:text-base  md:pb-7 md:text-left">
                      With our extensive experience, we’ve perfected the skill
                      of managing different AI tools and software. Our expertise
                      lies in seamless AI integration, including ChatGPT
                      integration & more, into various systems with precision.
                      Trust us for a smooth AI integration services, which
                      starts with AI consulting services that ensures flawless
                      implementation and deliver evident results.

                    </div>
                  </div>

                  <div className='absolute right-0 -bottom-5 m-4 group'>
                    <div className='relative w-10 h-10 md:w-20 md:h-20 lg:w-32 lg:h-32 overflow-hidden'>
                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                        <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>

                      <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                        <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                        <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                          <path
                            fill-rule='evenodd'
                            d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                            clip-rule='evenodd'
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle3 scale-x-100'></div>
          </div>
          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle4  scale-x-100'></div>
          </div>

          <div className='  bg-[#FFEBF4]  mt-2 h-screen bg-cover bg-no-repeat  '>
            <div className=' relative  text-grayy   2xl:pl-12    2xl:translate-y-[1000px]  bg-no-repeat bg-cover  top-3 px-4'>
              <div className='flex flex-col items-center justify-center'>
                <div className='flex flex-col items-center'>
                  <div className='lg:w-auto -mt-16 lg:mr-10 md:px-12 w-7/12'>
                    <img src={homebg5img} alt='Description of Image' className='' />
                  </div>

     <div className="relative flex bg-white opacity-75  -translate-x-5 z-40 flex-col w-full rounded-xl border-white border-b border-t  mt-5">
                    <div className="absolute left-0 -top-40 w-24 lg:w-40 -mt-5 h-auto ">
                      <img src={leftsideimg} alt="Overlay Image" className="" />

                    </div>
                    <img src={home5bubble} alt='Bubble Background' className='absolute w-11/12 translate-x-16  top-5' />

                    <div className="flex flex-col lg:pl-40 p-5 pl-12 lg:py-16 ">
                      <div className="font-display text-xl  text-[#666666] lg:text-3xl font-bold pb-3 lg:pb-5">
                        Learn Corporate AI Fundamentals
                      </div>
                      <div className="text-xs font-display md:text-xs text-[#666666] lg:text-sm  md:pb-7 md:text-left">
                        Random Walk’s AI learning endeavors to unravel the
                        mysteries surrounding AI, ensuring it’s not a black box.
                        We offer enhanced learning through industry-oriented
                        sessions, employing a seamless mix of learning models.
                        Explore our AI workshops and corporate AI training to
                        empower your employees with valuable skills. Our AI
                        Training and AI consulting services for executives
                        ensures a straightforward and effective learning
                        experience.

                      </div>

                      <div className='absolute -bottom-3 -right-3 m-4 group'>
                        <div className='relative w-10 h-10 2  overflow-hidden'>
                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                            <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>

                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                            <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='relative w-full h-0 -translate-y-full'>
            <div className='triangle3 scale-x-100'></div>
          </div>
          <div className='relative w-full h-0 -translate-y-full '>
            <div className='triangle5  scale-x-100'></div>
          </div>
          <div className='  bg-[#EFECFF]   h-max bg-cover bg-no-repeat mt-2 '>
            <div className='text-grayy    2xl:pl-12  2xl:translate-y-[1400px]  bg-no-repeat bg-cover pb-20'>
              <div className='flex flex-col items-center justify-center'>
                <div className='flex flex-col items-center py-0 px-4'>
                  <div className='px-12   w-11/12 '>
                    <img src={homebg6img} alt='Description of Image' />
                  </div>


                  <div className="relative flex opacity-75 z-20 bg-white  translate-x-4 flex-col bg-cover bg-no-repeat lg:w-full rounded-l-xl border-white border-b border-t mt-20 ">
                    <div className="absolute md:-mt-0 lg:-mt-16 right-0 -top-72  lg:ml-0 w-24 lg:w-28 h-auto">
                      <img
                        src={rightsideimg}
                        alt="Description of Image"
                        className=""
                      />
                    </div>

                    <div className="flex flex-col lg:pl-20 p-5 ">
                      <img
                        src={home6bubble}
                        alt="Bubble Background"
                        className="absolute w-10/12  -left-20 top-10"
                      />
                      <div className="font-display  text-[#666666] text-xl lg:text-3xl font-bold pb-3 lg:pb-5">
                        AI Managed Services
                      </div>
                      <div className="text-xs font-display text-[#666666]  text-xs lg:text-sm max-w-sm pb-7 text-left">
                        Benefit from our experienced AI professionals, flexible
                        on-demand resources, minimal upfront investment along
                        with rapid development and integration capabilities.
        </div>
                      <div className='absolute -bottom-3 lg:-right-3 2xl:right-1 m-4 group'>
                        <div className='relative w-10 h-10 2  overflow-hidden'>
                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                            <svg className='w-10 h-10 text-[#515050] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#515050] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>

                          <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                            <svg className='w-10 h-10 text-[#C62B6D] -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                            <svg className='w-10 h-10 text-[#C62B6D] -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                              <path
                                fill-rule='evenodd'
                                d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                                clip-rule='evenodd'
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='lg:text-4xl  md:text-3xl font-bold  text-3xl  py-10 px-5 md:py-10 md:px-10 text-[#666666]'>
          Products
        </div>

        <div className='hidden md:flex space-x-4 md:px-10 md:pb-5 '>
          <div className='w-1/3 p-10 relative text-white bg-gradient_background7 rounded-xl text-left'>
            <p className='lg:text-2xl md:text-xl font-semibold pb-3'>
              AI Readiness and <br />
              Digital Maturity
              <br /> Index
            </p>
            <p className='md:text-sm '>
              Identify gaps between your AI aspirations and current digital capabilities. Get actionable insights with a
              prioritized roadmap to bridge those gaps. Empower leaders to make data-driven decisions on AI adoption and
              digital transformation. <br />
              <br />
              Discover your AI readiness in just 15 minutes with our AI Readiness & Digital Maturity Assessment tool.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='w-1/3 p-10 relative bg-gradient_background7 text-white rounded-xl text-left'>
            <p className='lg:text-2xl md:text-xl lg:pb-[70px] font-semibold md:pb-2'>BrandCut</p>
            <p className='md:text-sm'>
              Brand Sponsorship Analytics Platform powered by AI, to measure the value you are getting from your
              sponsorship spend. Analyze relevant metrics of brand logo visibility and strategize your marketing spend
              in three simple steps in 5 minutes.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='w-1/3 p-10 relative bg-gradient_background7 text-white rounded-xl text-left'>
            <p className='lg:text-2xl md:text-xl lg:pb-[70px] font-semibold md:pb-2'>AI Fortune Cookie</p>
            <p className='md:text-sm'>
              Fortune Cookie is a cutting-edge Secure Knowledge Model reshaping organizational data handling. By
              seamlessly integrating unstructured and structured data, it empowers efficient data organization,
              retrieval, and insightful decision-making.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='  md:hidden flex scrollbar-hide overflow-x-auto px-5 gap-4'>
          <div className='w-11/12 p-5 relative text-white bg-gradient_background7 rounded-xl text-left flex-shrink-0'>
            <p className='lg:text-3xl  text-xl pb-3  '>AI Readiness and Digital Maturity Index</p>
            <p className='text-sm'>
              Identify gaps between your AI aspirations and current digital capabilities. Get actionable insights with a
              prioritized roadmap to bridge those gaps. Empower leaders to make data-driven decisions on AI adoption and
              digital transformation. <br />
              <br />
              Discover your AI readiness in just 15 minutes with our AI Readiness & Digital Maturity Assessment tool.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>

                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='w-11/12 p-5 relative bg-gradient_background7 text-white rounded-xl text-left flex-shrink-0'>
            <p className='lg:text-3xl text-xl pb-3  lg:pb-[70px]'>BrandCut</p>
            <p className='text-sm '>
              Brand Sponsorship Analytics Platform powered by AI, to measure the value you are getting from your
              sponsorship spend. Analyze relevant metrics of brand logo visibility and strategize your marketing spend
              in three simple steps in 5 minutes.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>

                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='w-11/12 p-5 relative bg-gradient_background7 text-white rounded-xl text-left flex-shrink-0'>
            <p className='lg:text-3xl text-xl pb-3 lg:pb-[70px]'>AI Fortune Cookie</p>
            <p className='text-sm '>
              Fortune Cookies is a cutting-edge Secure Knowledge Model reshaping organizational data handling. By
              seamlessly integrating unstructured and structured data, it empowers efficient data organization,
              retrieval, and insightful decision-making.
            </p>

            <div className='absolute md:-bottom-10 md:-right-8 m-4 group'>
              <div className='relative md:w-28 md:h-28 overflow-hidden'>
                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform group-hover:translate-x-full'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>

                <div className='absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out transform -translate-x-full group-hover:translate-x-0'>
                  <svg className='w-10 h-10 text-white -mr-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                  <svg className='w-10 h-10 text-white -ml-3' fill='currentColor' viewBox='0 0 20 20'>
                    <path
                      fill-rule='evenodd'
                      d='M7.293 14.707a1 1 0 010-1.414L11.586 9 7.293 4.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z'
                      clip-rule='evenodd'
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isSuccessOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">
                Thank you for your interest in becoming a partner!
              </p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {isSuccessOpenintegration && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModalintegration}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModalintegration}
              >
                Close
              </button>
            </div>
          </div>
        )}
        <div className="lg:text-4xl  md:text-2xl  d font-bold md:my-5 py-10 md:py-0 px-5 text-3xl  md:px-10  md:pb-10 text-[#666666]">
          Our Blogs
        </div>

      




<div className="grid max-md:hidden text-[#666666] lg:grid-cols-5 md:px-10 md:grid-cols-5 sm:grid-cols-2 ss:grid-cols-1 gap-5 px-4 h-[28rem] overflow-hidden md:pt-32 -translate-y-1/3 md:mt-10">
  {blogs && blogs.length > 0 ? (
    blogs.map((blog, index) => (
      <Link
        key={blog.id}
        to={`/blogs/${blog.id}`}
        className="group hover:-translate-y-1 transform transition-all duration-300"
      >
        <div
          className={`group-hover:-translate-y-12 transform w-full relative rounded-xl overflow-hidden drop-shadow-md flex flex-col justify-between transition-all duration-300 ease-in-out h-80 
          ${
            index === 1
              ? "bg-[#EBF7FF]"
              : index < 1
              ? "bg-[#a9b4df]"
              : "bg-[#FFEBF4]"
          }
        `}
        >
          <div className="p-5 transition-transform duration-300 ease-in-out transform">
            <div className="font-bold md:text-xs lg:line-clamp-3 pb-5">
              {blog.blogTitle}
            </div>

           

            <div className="text-gray-600 text-sm md:line-clamp-5 lg:line-clamp-10">
              {blog.blogDesc}
            </div>
          </div>
        </div>
        <div className='overflow-hidden rounded-lg transform transition-all group-hover:-translate-y-full'>
          <img
            className="w-full object-cover h-40"
            src={`${blog.coverImg.url}`}
            alt={blog.blogTitle}
          />
        </div>
      </Link>
    ))
  ) : (
    <p>Loading...</p>
  )}
</div>


<div className="flex md:hidden scroll-hide gap-5 overflow-x-auto px-4 text-black h-[28rem] md:pt-32">
  {blogs && blogs.length > 0 ? (
    blogs
      .slice(0, 5) // Limit to 5 recent blogs
      .map((blog, index) => (
        <Link
          key={blog.id}
          to={`/blogs/${blog.id}`}
          className="min-w-[16rem] md:min-w-[18rem]"
        >
          <div
            className={`w-full relative rounded-xl overflow-hidden drop-shadow-md flex flex-col justify-between h-80 
            ${
              index === 1
                ? "bg-[#EBF7FF]"
                : index < 1
                ? "bg-[#a9b4df]"
                : "bg-[#FFEBF4]"
            }
          `}
          >
            <div className='rounded-t-xl overflow-hidden'>
              <img
                className="w-full object-cover h-40"
                src={`${blog.coverImg.url}`}
                alt={blog.blogTitle}
              />
            </div>

            <div className="p-5">
              <div className="font-bold text-xs md:text-sm line-clamp-3 pb-2">
                {blog.blogTitle}
              </div>

           

              <div className="text-gray-600 text-xs md:text-sm line-clamp-3">
                {blog.blogDesc}
              </div>
            </div>
          </div>
        </Link>
      ))
  ) : (
    <p>Loading...</p>
  )}
</div>

        <Popup />
      </div>
    </>
  );
};
