import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/SectionSix.module.css";
import img1 from "../assets/d1.png";
import img2 from "../assets/d2.png";
import img3 from "../assets/d3.png";
import img4 from "../assets/d4.png";
import img5 from "../assets/d5.png";
import img6 from "../assets/d6.png";

const cardData = [
  {
    imgSrc: img1,
    descHeading: "Brand Visibility Index",
    description: "Proportion of brand logo visibility in the\nvideo duration.",
  },
  {
    imgSrc: img2,
    descHeading: "Competitor Visibility Index",
    description:
      "Comparison of brand logo visibility with\ncompetitors' logos in the entire video.",
  },
  {
    imgSrc: img3,
    descHeading: "Simultaneous Competitor Broadcast Indicator",
    description:
      "Comparison of brand logo visibility with\ncompetitors' logos in the same video frames.",
  },
  {
    imgSrc: img4,
    descHeading: "Size Comparison Index",
    description: "Analysis of logo size relative to the\nvisible screen size.",
    specialClass: styles.specialMargin
  },
  {
    imgSrc: img5,
    descHeading: "Instance Analysis",
    description:
      "Analysis of total instances where brand logo\nis visible on screen for different durations.",
  },
  {
    imgSrc: img6,
    descHeading: "Quadrant Analysis",
    description:
      "Analysis of brand logo visibility duration in\ndifferent screen quadrants.",
  },
];

const SectionSix = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollLeft = containerRef.current.scrollLeft;
      const cardWidth = containerRef.current.clientWidth;
      const index = Math.round(scrollLeft / cardWidth);
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, []);

  return (
    <section className={styles.section}>
      <h2 className={styles.heading}>
        <span className={styles.highlight}>
          Use AI to Enhance Your Brand Visibility
        </span>
        <span className={styles.highlight2}>
          Use AI to Enhance Your <br /> Brand Visibility
        </span>
      </h2>
      <h3 className={styles.subHeading}>Dashboard Metrics</h3>
      <div className={styles.imageGrid} ref={containerRef}>
        {cardData.map((card, index) => (
          <div className={styles.outerCard} key={index}>
            <div className={styles.imageCard}>
              <img
                className={styles.image}
                src={card.imgSrc}
                alt={`Image ${index + 1}`}
              />
            </div>
            <h3
              className={`${styles.descHeading} ${card.specialClass ? card.specialClass : ''}`}
            >
              {card.descHeading}
            </h3>
            <p className={styles.description}>
              {card.description.split("\n").map((line, idx) => (
                <React.Fragment key={idx}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        ))}
      </div>
      <div className={styles.dotsContainer}>
        {cardData.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              activeIndex === index ? styles.active : ""
            }`}
            onClick={() => {
              if (containerRef.current) {
                containerRef.current.scrollTo({
                  left: index * containerRef.current.clientWidth,
                  behavior: "smooth",
                });
              }
            }}
          ></span>
        ))}
      </div>
    </section>
  );
};

export default SectionSix;
