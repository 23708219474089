


import React from 'react'
import { BlogContent } from "./BlogContent";




export const BlogContentPage = ({blogs}) => {
console.log(blogs)


    return (
        <div>





            <BlogContent blogs={blogs} />

             


        </div>
    )
}
