import career1 from '../assets/Careers/career1.png';
import './home.css';
import careerr3 from '../assets/Careers/career3.png';
import Footer from '../components/Footer';
import hang from '../assets/Managed/hanging.png';
import career2 from '../assets/Careers/career2.png';
import left from '../assets/Careers/left.png';
import { NavLink } from 'react-router-dom';
import recright from '../assets/Integration/recright.png';
import right from '../assets/Careers/right.png';
import React, { useState, useEffect } from 'react';
export const Career = () => {
  const handleMailButtonClick = () => {
    window.location.href = 'mailto:enquiry@randomwalk.ai?subject=Subject&body=Body';
  };
  const sentences = [
    'Fostering a vibrant AI community.',
    'Events that unite experts from diverse backgrounds.',
    'Ethical and responsible AI use.',
  ];

  const [index, setIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(-1);

  useEffect(() => {
    const currentSentence = sentences[index];
    const letterCount = currentSentence.length;
    let letterIndex = 0;

    const letterIntervalId = setInterval(() => {
      if (letterIndex < letterCount) {
        setCurrentLetterIndex(letterIndex);
        letterIndex++;
      } else {
        clearInterval(letterIntervalId);

        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
          setCurrentLetterIndex(-1);
        }, 2000);
      }
    }, 70);

    return () => clearInterval(letterIntervalId);
  }, [index]);
  return (
    <div className='Main2 font-display '>
      <div className='max-lg:hidden flex justify-center items-center md:mt-20 md:pb-10 lg:mt-24'>
        <img src={career1} alt='AI Integration' className='md:w-8/12 lg:w-10/12 2xl:w-10/12' />


        <div className="absolute md:left-24 2xl:left-10 flex flex-col justify-center items-start">
          <div className="text-[#666666] lg:text-5xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-0 2xl:-translate-y-4 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </div>

          <div className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto lg:text-sm md:translate-y-5 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24 mt-5">
            <span className="text-[#666666] lg:text-5xl md:text-2xl 2xl:text-6xl font-bold ">

              Growing Team <br />
            </span>

            <span className='text-[#666666] 2xl:text-base mt-6 block'>
              With a product designed to fuel creativity and make meetings <br /> fun, working at Random Walk is as good
              as you think — maybe
              <br /> even better
            </span>
          </div>
        </div>
      </div>

      <div className='flex lg:hidden max-md:hidden  justify-center items-center md:mt-20 md:pb-10 lg:mt-24'>
        <img src={career1} alt='AI Integration' className='pl-5 md:w-9/12 lg:w-9/12 2xl:w-9/12' />


        <div className="absolute md:left-24  flex flex-col justify-center items-start">
          <div className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-5 2xl:-translate-y-10 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </div>

          <div className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto lg:text-sm md:translate-y-4 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24">
            <span className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl font-bold ">

              Growing Team <br />
            </span>
            <div className='md:max-w-72'>
              <span className=' 2xl:text-base text-[#666666]  '>
                With a product designed to fuel creativity and make meetings fun, working at Random Walk is as good as
                you think — maybe even better
              </span>
            </div>
          </div>
        </div>
      </div>


      <div className="flex md:hidden pt-28 lg:hidden flex-col justify-center items-center md:mt-20 md:pb-10 lg:mt-24">
        <div className="relative flex flex-col justify-center items-start md:left-24">
          <div className="text-[#666666] text-3xl pl-5 lg:text-6xl md:text-2xl 2xl:text-6xl font-bold md:translate-y-2 lg:-translate-y-5 2xl:-translate-y-10 md:translate-x-0 2xl:translate-x-24">
            Join our fast
          </div>

          <div className="text-[#666666] md:mb-5 lg:mb-0 md:text-xs lg:max-w-auto pl-4 lg:text-sm md:translate-y-4 lg:translate-y-0 2xl:-translate-y-3 2xl:translate-x-24">
            <div className="text-[#666666] lg:text-6xl md:text-2xl 2xl:text-6xl text-3xl font-bold ">

              Growing Team <br />
            </div>
            <div className='md:max-w-72'>
              <div className='text-xs pt-3 2xl:text-base text-[#666666] '>
                With a product designed to fuel creativity and make meetings fun, working at Random Walk is as good as
                you think — maybe even better
              </div>
            </div>
          </div>
        </div>

        <img src={career1} alt='AI Integration' className='md:w-9/12 lg:w-9/12 2xl:w-9/12 mt-4 md:mt-0' />
      </div>

      <div className='lg:mt-10'>
        <img
          src={hang}
          alt='hi'
          className='pt-5 md:pt-0 w-screen lg:w-full lg:h-32 absolute left-0 right-0 -ml-screen mr-screen'
        />
      </div>

      <div className='flex md:hidden flex-col-reverse lg:flex-row lg:items-center 2xl:pb-20 md:mt-0 lg:mt-24'>
        <div className='block lg:hidden translate-y-32 text-left w-full px-2'>
          <div className='relative overflow-hidden flex justify-center items-center h-40 w-full text-center'>
            {sentences.map((sentence, i) => (
              <div
                key={i}

                className={`absolute w-full text-[#666666]  py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"

                }`}
              >
                {sentence.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-sm text-nowrap font-bold ${char === ' ' ? 'mr-0' : ''}`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: 'opacity 0.1s ease-in-out',
                      width: char === ' ' ? '0.3em' : 'auto',
                    }}
                  >
                    {char}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className='relative mt-12 flex-1 flex items-start'>
          <div className='absolute md:left-0 w-5/12  md:w-9/12 lg:w-full lg:left-20 2xl:top-4 top-0'>
            <img src={career2} alt='AI Integration' />
          </div>


          <div className="absolute pl-5 md:left-0 left-10 translate-y-10 md:translate-x-16 lg:-translate-y-0 md:-translate-y-2 lg:translate-x-10 2xl:-translate-y-16 lg:left-32 flex flex-col justify-center items-start">
            <div className="text-[#666666] text-2xl md:ml-0 md:mt-16 md:text-4xl lg:text-5xl lg:pt-0 2xl:pt-20 font-bold">

              Our
              <br />
              Values
            </div>

            <div className='text-[#6666666]  text-xs pt-5 md:ml-0 md:text-lg lg:text-base md:pt-8 lg:pt-10 2xl:pt-10 2xl:text-xl'>
              What we love we stand for everyday
            </div>
          </div>
        </div>

        <div className='hidden lg:block text-left w-7/12 pl-10 md:translate-y-20 lg:translate-y-28 lg:-translate-x-12 2xl:-translate-x-80'>
          <div className='relative overflow-hidden flex justify-center items-center h-40 w-full text-center'>
            {sentences.map((sentence, i) => (
              <div
                key={i}

                className={`absolute w-full text-[#666666] py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"

                }`}
              >
                {sentence.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-nowrap font-bold ${char === ' ' ? 'mr-0' : ''}`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: 'opacity 0.1s ease-in-out',
                      width: char === ' ' ? '0.3em' : 'auto',
                    }}
                  >
                    {char}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='flex  max-md:hidden 2xl:pb-20 items-center md:mt-0 lg:mt-24'>
        <div className='relative flex-1 flex  items-start'>
          <div className='absolute md:left-0 md:w-9/12 lg:w-full lg:left-20 2xl:top-4 top-0'>
            <img src={career2} alt='AI Integration' />
          </div>


          <div className="absolute pl-5 md:left-0 left-10 translate-y-10 md:translate-x-16 lg:-translate-y-0 md:-translate-y-2 lg:translate-x-0 2xl:-translate-y-16 lg:left-32 flex flex-col justify-center items-start">
            <div className="text-[#666666] text-2xl md:ml-0 md:mt-16  md:text-4xl lg:text-5xl lg:pt-0 2xl:pt-20 font-bold">

              Our
              <br />
              Values
            </div>

            <div className='text-[#666666]  text-xs pt-3 md:ml-0 md:text-lg lg:text-base md:pt-8 lg:pt-10 2xl:pt-10  2xl:text-xl '>
              What we love we stand for everyday
            </div>
          </div>
        </div>

        <div className='flex text-left    w-7/12 pl-10   md:translate-y-20 lg:translate-y-28 lg:-translate-x-12 2xl:-translate-x-80'>
          <div className='relative overflow-hidden flex justify-center  items-center h-40 w-full text-center'>
            {sentences.map((sentence, i) => (
              <div
                key={i}

                className={`absolute w-full text-[#666666]  py-10 text-center transition-opacity duration-1000 ease-in-out ${
                  i === index ? "opacity-100" : "opacity-0"

                }`}
              >
                {sentence.split('').map((char, charIndex) => (
                  <span
                    key={charIndex}
                    className={`inline-block lg:text-2xl text-nowrap font-bold ${char === ' ' ? 'mr-0' : ''}`}
                    style={{
                      opacity: charIndex <= currentLetterIndex ? 1 : 0,
                      transition: 'opacity 0.1s ease-in-out',
                      width: char === ' ' ? '0.3em' : 'auto',
                    }}
                  >
                    {char}
                  </span>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className="bg-gray-50 py-5 px-5 mt-32 pt-10  lg:mt-48 md:mt-60 lg:pb-10 ">
        <div className="text-[#666666]  text-2xl   lg:pb-2 lg:text-4xl lg:pt-5 font-bold text-center ">
          Our Team
        </div>
        <div className="font-display text-[#666666]  text-center pt-2 md:pt-0 lg:text-base text-xs">

          We are a tight-knit team distributed all around the globe
        </div>
        <div className=''>
          <img src={careerr3} alt='Our Team' className='  rounded-3xl  md:w-6/12 lg:w-7/12 md:mt-6  mx-auto p-3' />
        </div>
      </div>


      <div className="bg-white text-black text-center font-display rounded mb-10">
        <div className=" lg:mt-5 text-[#666666] pt-10 text-3xl lg:font-display font-bold lg:text-4xl lg:pt-10">
          Open Roles
        </div>


        <div className=' text-xs text-[#666666]   pt-3 lg:pt-2 font-display lg:text-sm'>
          Help us empower the service led growth economy
        </div>
        <div className='flex justify-center'>
          <button
            onClick={handleMailButtonClick}

            className=" text-[#666666]  bg-gradient-to-r from-[#efbcff] to-[#f9dae7]  mt-10 border-none border lg:text-xl font-display font-bold p-5 md:py-8 md:px-8 rounded-2xl"

          >
            enquiry@randomwalk.ai
          </button>
        </div>
      </div>
    </div>
  );
};
