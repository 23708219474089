import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { BlogContentPage } from "./pages/BlogContentPage.js";
import { Blogs } from "./pages/Blogs";
import useFetch from "./hooks/useFetch";
import Footer from "./components/Footer.js";
import Popup from "./components/popup.js";
import { ThankYouMessage } from "./components/ThankYouMessage";
import { RightButtonPopup } from "./components/RightButtonPopup";
import Appjs from "../src/Ai-read/src/pages/Homepage.js";
import SuccessPage from "../src/Ai-read/src/pages/SuccessPage";
import CanceledPage from "../src/Ai-read/src/pages/CanceledPage";
import ScrollToTop from "./pages/ScrollToTop.js";
import styles from "../src/Brandcut/styles/App.module.css";
import demoStyles from "../src/Brandcut/styles/demo.module.css";
import HeroSection from "../src/Brandcut/components/HeroSection.js";
import SectionTwo from "../src/Brandcut/components/SectionTwo.js";
import SectionThree from "../src/Brandcut/components/SectionThree.js";
import SectionFour from "../src/Brandcut/components/SectionFour.js";
import SectionFive from "../src/Brandcut/components/SectionFive.js";
import SectionSix from "../src/Brandcut/components/SectionSix.js";
import ContactSection from "../src/Brandcut/components/ContactSection.js";

import TagManager from "react-gtm-module";
import LeadForm from "../src/Brandcut/components/LeadForm.js";
import MascotChatIcon from "../src/Brandcut/components/MascotChatIcon.js";
import ThankYouPage from "../src/Brandcut/components/ThankYouSection.js";
import LeadFormB from "../src/Brandcut/components/LeadFormB.js";
import ContactSectionB from "../src/Brandcut/components/ContactSectionB.js";

import {
  About,
  Integration,
  Home,
  Managed,
  Workshop,
  Career,
  BlogContent,
  BlogCategory,
  Brochures,
  Contact,
  Terms,
  Privacy,
  Fortunecookie,
  Webinar,
  Podcast,
} from "./pages";
import { Events } from "./pages/Events";



export default function App() {
  const [showThankYou, setShowThankYou] = useState(false);
  const [isLeadFormVisible, setIsLeadFormVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();

  const [showRightButtonPopup, setShowRightButtonPopup] = useState(false);

  const handleRightButtonPopupClick = () => {
    setShowRightButtonPopup(true);
  };

  const handleCloseAllPopups = () => {
    setShowRightButtonPopup(false);
  };

  useEffect(() => {
    const callChateleonCustom = () => {
      if (window.chateleonCustom) {
        window.chateleonCustom("OlJu7W9bKC7BjYQKoV4VE6kWPGrkYs7s6Z3kZIGq");
      }
    };

    const loadCustomScript = (src, id, callback) => {
      if (document.getElementById(id)) return;
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      script.onload = () => {
        callback();
      };
      document.body.appendChild(script);
    };

    const loadScript = (src, id) => {
      if (document.getElementById(id)) return;
      const script = document.createElement("script");
      script.src = src;
      script.id = id;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        loadCustomScript(
          "https://integration-kit.chateleon.com/v0/randomwalk-ai/custom.js",
          "chateleon-custom-script",
          callChateleonCustom
        );
      };
    };

    loadScript(
      "https://integration-kit.chateleon.com/v0/sdk_v0.0.5.js",
      "chateleon-sdk"
    );
  }, []);

  const openLeadForm = () => setIsLeadFormVisible(true);

  const {
    loading: blogsLoading,
    data: blogsData,
    error: blogsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/data.json`
  );

  const {
    loading: brochuresLoading,
    data: brochuresData,
    error: brochuresError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/brochure/data.json`
  );

  const {
    loading: eventsLoading,
    data: eventsData,
    error: eventsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/event/data.json`
  );

  const {
    loading: speakersLoading,
    data: speakersData,
    error: speakersError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/speaker/data.json`
  );

  const {
    loading: webinarsLoading,
    data: webinarsData,
    error: webinarsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/webinar/data.json`
  );

  const {
    loading: pastwebinarsLoading,
    data: pastwebinarsData,
    error: pastwebinarsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/pastwebinar/data.json`
  );

  const {
    loading: podcastsLoading,
    data: podcastsData,
    error: podcastsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/podcast/data.json`
  );

  const {
    loading: pastpodcastsLoading,
    data: pastpodcastsData,
    error: pastpodcastsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/pastpodcast/data.json`
  );


  if (
    blogsLoading ||
    brochuresLoading ||
    eventsLoading ||
    speakersLoading ||
    webinarsLoading ||
    pastwebinarsLoading ||
    pastpodcastsLoading ||
    podcastsLoading
  )
    return <p>Loading...</p>;
  if (
    blogsError ||
    brochuresError ||
    eventsError ||
    speakersError ||
    webinarsError ||
    pastwebinarsError ||
    pastpodcastsError ||
    podcastsError
  )
    return <p>Error!</p>;

  const handleThankYouClick = () => {
    setShowThankYou(true);
  };

  return (
    <>
      <Navbar />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home blogs={blogsData ? blogsData : ""} />} />

        <Route
          path="/blogs"
          element={<Blogs blogs={blogsData ? blogsData : ""} />}
        />
        <Route
          path="/blogs/:id"
          element={<BlogContentPage blogs={blogsData ? blogsData : ""} />}
        />
        <Route path="/blogcontentpage" element={<BlogContentPage />} />
        <Route path="/blogcontent" element={<BlogContent />} />

        <Route
          path="/brochures"
          element={<Brochures brochures={brochuresData ? brochuresData : ""} />}
        />

        <Route
          path="/events"
          element={
            <Events
              events={eventsData ? eventsData : ""}
              speakers={speakersData ? speakersData : ""}
            />
          }
        />

        <Route path="/ai-readiness" element={<Appjs />} />

        <Route
          path="/webinars"
          element={
            <Webinar
              webinars={webinarsData}
              pastwebinars={pastwebinarsData ? pastwebinarsData : ""}
            />
          }
        />

     

        <Route
          path="/webinar"
          element={
            <Webinar
              webinars={webinarsData}
              pastwebinars={pastwebinarsData ? pastwebinarsData : ""}
            />
          }
        />

        <Route
          path="/podcasts"
          element={
            <Podcast podcasts={podcastsData} 
            pastpodcasts={pastpodcastsData ? pastpodcastsData: ""} />
          }
        />

        <Route path="/ai-integration" element={<Integration />} />
        <Route path="/ai-managed-services" element={<Managed />} />
        <Route path="/ai-workshop-for-corporates" element={<Workshop />} />

        <Route path="/about-us" element={<About />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/terms-of-service" element={<Terms />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route
          path="/fortunecookie"
          element={<Fortunecookie blogs={blogsData} />}
        />

        <Route
          path="/blogcategory/:category"
          element={<BlogCategory blogs={blogsData ? blogsData : ""} />}
        />
      
        <Route
          path="/brandcut"
          element={
            <div>
              <div className={styles.section}>
                <HeroSection onOpenLeadForm={openLeadForm} />
              </div>
              <SectionTwo onOpenLeadForm={openLeadForm} />
              <div className={styles.section2}>
                <SectionThree />
              </div>
              <SectionFour />
              <SectionFive onOpenLeadForm={openLeadForm} />
              <div className={styles.section3}>
                <SectionSix />
              </div>
              <div className={styles.section4}>
                <ContactSection
                  visible={isLeadFormVisible}
                  onClose={() => setIsLeadFormVisible(false)}
                  onSubmit={() => setIsSubmitted(true)}
                />
              </div>

              <LeadForm
                visible={isLeadFormVisible}
                onClose={() => setIsLeadFormVisible(false)}
                onSubmit={() => setIsSubmitted(true)}
              />
              {/* <MascotChatIcon onClick={() => setIsLeadFormVisible(true)} /> */}
            </div>
          }
        />
        <Route
          path="/business-enquiry"
          element={
            <div>
              <div className={styles.section}>
                <HeroSection onOpenLeadForm={openLeadForm} />
              </div>
              <SectionTwo onOpenLeadForm={openLeadForm} />
              <div className={styles.section2}>
                <SectionThree />
              </div>
              <SectionFour />
              <SectionFive onOpenLeadForm={openLeadForm} />
              <div className={styles.section3}>
                <SectionSix />
              </div>
              <div className={styles.section4}>
                <ContactSectionB
                  visible={isLeadFormVisible}
                  onClose={() => setIsLeadFormVisible(false)}
                  onSubmit={() => setIsSubmitted(true)}
                />
              </div>

              <MascotChatIcon onClick={() => setIsLeadFormVisible(true)} />
            </div>
          }
        />
        <Route
          path="/thank-you"
          element={isSubmitted ? <ThankYouPage /> : <Navigate to="/" />}
        />
      </Routes>

      <Footer />
    </>
  );
}
