import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import CustomMarkdownRenderer from "../components/Markdown.js";
import podcast1 from "../assets/Podcast/podcast1.png";
import share from "../assets/Podcast/share.png";
import recright from "../assets/Integration/recright.png";
import listen from "../assets/Podcast/listen.png";
import transcript from "../assets/Podcast/transcript.png";
import audioFile from "../assets/Podcast/gorgeous.mp3";
import whatsapp from "../assets/Podcast/whatsapp.png";
import mail from "../assets/Podcast/email.png";
import { NavLink } from "react-router-dom";
import instagram from "../assets/Podcast/instagram.png";
import { Client, Databases, ID } from "appwrite";
import Popup from "../components/popup.js";
export const Podcast = ({ podcasts, pastpodcasts }) => {
  console.log("podcast", pastpodcasts);

  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [fullname, setfullname] = useState("");
  const [leavenote, setleavenote] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmId, setUtmId] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmContent, setUtmContent] = useState("");

  const [errors, setErrors] = useState({});

  // const handleButtonClick = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  // const handlePhoneNumberChange = (value) => {

  //   const cleanedValue = value.replace(/[^0-9+]/g, '');
  //   setphonenumber(cleanedValue);
  // };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get("utm_source") || "");
    setUtmMedium(searchParams.get("utm_medium") || "");
    setUtmCampaign(searchParams.get("utm_campaign") || "");
    setUtmId(searchParams.get("utm_id") || "");
    setUtmTerm(searchParams.get("utm_term") || "");
    setUtmContent(searchParams.get("utm_content") || "");
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID8,
        ID.unique(),
        {
          fullname,
          leavenote,

          phonenumber,
          email,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      // setIsModalOpen(false);

      setIsSuccessOpen(true);

      // Navigate to a FC App in new tab with query params email and name
      // and set cookie access-token with eY....
      // document.cookie = `access-token=${process.env.REACT_APP_JWT_TOKEN}`;
      // const redirectUrl = `${process.env.REACT_APP_FORM_REDIRECT_URL}/?name=${name}&email=${email}`;
      // window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const podcastData = podcasts?.[0] || {};
  const pastpodcastData = pastpodcasts || []; // Fetch all past podcasts, not just the first one
  // console.log("PAST DAW: ", pastpodcastData);

  const upcomingimages = Array.isArray(podcastData?.upcoming_images)
    ? podcastData.upcoming_images
    : [];

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [showShareOptions2, setShowShareOptions2] = useState(false);
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [showTranscriptModal2, setShowTranscriptModal2] = useState(null); // Set modal index for transcript

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showVideoModal2, setShowVideoModal2] = useState(null); // Set modal index for video

  const getBackgroundColor = (index) => {
    const imageCount = upcomingimages.length;

    if (imageCount === 1) {
      return podcastData.onespeaker_color1 || "#FDE9FF";
    } else if (imageCount === 2) {
      return index === 0
        ? podcastData.twospeaker_color1
        : podcastData.twospeaker_color2 || "#FDE9FF";
    } else if (imageCount === 3) {
      if (index === 0) return podcastData.threespeaker_color1 || "#FDE9FF";
      if (index === 1) return podcastData.threespeaker_color2 || "#FDE9FF";
      if (index === 2) return podcastData.threespeaker_color3 || "#FDE9FF";
    } else if (imageCount === 4) {
      if (index === 0) return podcastData.threespeaker_color1 || "#FDE9FF";
      if (index === 1) return podcastData.fourspeaker_color2 || "#FDE9FF";
      if (index === 2) return podcastData.fourspeaker_color3 || "#FDE9FF";
      if (index === 3) return podcastData.fourspeaker_color4 || "#FDE9FF";
    }
    return "#FDE9FF";
  };

  const handleShareClick = () => {
    setShowShareOptions(!showShareOptions);
  };
  const handleShareClick2 = () => {
    setShowShareOptions2(!showShareOptions2);
  };

  const handleTranscriptClick2 = (index) => {
    setShowTranscriptModal2(index); // Open transcript modal for the clicked past podcast
  };

  const closeTranscriptModal2 = () => {
    setShowTranscriptModal2(null); // Close transcript modal
  };

  const closeVideoModal2 = () => {
    setShowVideoModal2(null); // Close video modal
  };

  const getYouTubeEmbedUrl2 = (url) => {
    if (!url) return null;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const getYouTubeThumbnailUrl = (url, quality = "hqdefault") => {
    if (!url) return null;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://img.youtube.com/vi/${match[2]}/${quality}.jpg`;
    }
    return null;
  };

  const upcomingpodcastHeading =
    podcastData.upcoming_title || "No upcoming podcast details available";
  const upcomingpodcastdesc =
    podcastData.upcoming_desc || "No upcoming podcast details available";
  const transcriptContent =
    podcastData.transcript || "Transcript not available.";
  const transcriptContent2 =
    pastpodcastData.past_transcript || "Transcript not available.";
  const formdetails =
    podcastData.form_details || "No upcoming podcast details available";

  const handleTranscriptClick = () => {
    setShowTranscriptModal(true);
  };

  const closeTranscriptModal = () => {
    setShowTranscriptModal(false);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  const youtubeLink = podcastData.youtube_url || "";
  const pastyoutubeLink = pastpodcastData.pastyoutube_url || "";

  const handleListenClick = () => {
    if (youtubeLink) {
      setShowVideoModal(true);
    } else {
      console.error("No YouTube URL provided");
    }
  };

  const handleListenClick2 = () => {
    if (pastyoutubeLink) {
      setShowVideoModal2(true);
    } else {
      console.error("No YouTube URL provided");
    }
  };

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return null;
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const thumbnailUrl2 = getYouTubeThumbnailUrl(pastyoutubeLink, "sddefault");

  return (
    <div className="font-display relative">
      <div className="md:py-40 md:px-10 lg:px-20 pt-24   md:pt-32   p-5 flex flex-col lg:flex-col justify-start items-center relative">
        <div className="w-full flex flex-col md:flex-row justify-between items-center relative">
          <div className="w-full flex flex-col md:flex-row relative z-10">
            <img
              src={podcast1}
              alt="Podcast"
              className=" md:w-6/12 lg:w-10/12   flex    md:mb-0"
            />

            <div className="w-full bg-[#F5ECFA] text-[#666666] rounded-3xl  md:rounded-2xl  md:rounded-tl-none  md:rounded-bl-none  md:rounded-tr-3xl md:rounded-br-3xl p-5  md:p-10 lg:p-16 2xl:p-40 ">
              <div className=" 2xl:scale-125  md:pb-5 lg:text-xl font-bold">
                Latest Episode
              </div>
              <div className="  2xl:scale-125">
                <CustomMarkdownRenderer
                  content={upcomingpodcastHeading}
                  setHeadings={() => {}}
                />
              </div>

              <div className="  2xl:scale-125 lg:text-sm md:text-xs mt-5">
                <CustomMarkdownRenderer
                  content={upcomingpodcastdesc}
                  setHeadings={() => {}}
                />
              </div>

              <div className="md:hidden p-5 gap-10  relative grid grid-cols-1 lg:grid-cols-2 lg:gap-16 md:pt-5 2xl:pt-10">
                {upcomingimages.length > 0 ? (
                  upcomingimages.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex justify-center items-center  h-32 w-32 lg:w-40 mb-10"
                    >
                      <div className="z-0 absolute inset-0 flex items-center justify-center">
                        <div className="w-40 h-40 md:w-56 md:h-56 lg:w-40 lg:h-40 bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-100"></div>
                      </div>

                      <div
                        className="absolute inset-0 rounded-lg z-10"
                        style={{ backgroundColor: getBackgroundColor(index) }}
                      ></div>

                      <img
                        src={image.url}
                        alt={`Podcast speaker ${index + 1}`}
                        className="relative rounded-lg z-20 "
                      />

                      <div className="absolute -top-8 -right-10 h-20 w-20 md:top-0 md:right-0 md:h-28 md:w-28 border-2 border-white rounded-lg  z-0 md:z-30"></div>
                    </div>
                  ))
                ) : (
                  <div>No speaker images available.</div>
                )}
              </div>

              <div className="max-md:hidden relative grid grid-cols-1 lg:grid-cols-2 lg:gap-16 md:gap-16  md:pt-5 2xl:pt-10">
                {upcomingimages.length > 0 ? (
                  upcomingimages.map((image, index) => (
                    <div
                      key={index}
                      className="relative flex justify-center items-center w-full lg:w-40"
                    >
                      <div className="z-0 absolute inset-0 flex items-center justify-center">
                        <div className="md:w-40 md:h-40 lg:w-40 lg:h-40 bg-gradient-to-t from-[#D2A4E1] to-[#ECCEF1] rounded-full absolute opacity-100"></div>
                      </div>

                      <div
                        className="absolute md:w-32 md:h-32 lg:h-32 lg:w-32 rounded-lg  z-10"
                        style={{ backgroundColor: getBackgroundColor(index) }}
                      ></div>

                      <img
                        src={image.url}
                        alt={`Podcast speaker ${index + 1}`}
                        className="relative h-32 w-32 rounded-lg z-20 "
                      />

                      <div className="absolute w-28 h-28 border-2 border-white 2xl:left-28 2xl:-top-10 md:-top-8 2xl:-translate-x-5 md:translate-x-12 rounded-l-none rounded-tl-3xl rounded-tr-3xl z-0"></div>
                    </div>
                  ))
                ) : (
                  <div>No speaker images available.</div>
                )}
              </div>

              <div className="flex flex-col md:mt-10 relative">
                <div className="flex justify-center items-center mb-3">
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={handleShareClick}
                  >
                    <img src={share} alt="Share" className="w-5 h-5" />
                    <div className="ml-2">Share</div>
                    {showShareOptions && (
                      <div className="absolute md:top-6 top-5 left-16 lg:top-8 2xl:top-10 md:left-0 md:ml-5  lg:ml-24 2xl:ml-60 p-3 shadow-lg z-50">
                        <div className="flex items-center space-x-4">
                          <a
                            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              "Check out this podcast: " +
                                upcomingpodcastHeading
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={whatsapp}
                              alt="WhatsApp"
                              className="w-5 h-5"
                            />
                          </a>

                          <a
                            href={`mailto:?subject=${encodeURIComponent(
                              "Check out this podcast"
                            )}&body=${encodeURIComponent(
                              "I found this podcast interesting: " +
                                upcomingpodcastHeading
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={mail} alt="Email" className="w-5 h-5" />
                          </a>

                          <img
                            src={instagram}
                            alt="Instagram"
                            className="w-5 h-5 cursor-pointer"
                            onClick={() =>
                              alert(
                                "To share on Instagram, please copy the link and share it manually."
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mx-3">|</div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={handleTranscriptClick}
                  >
                    <img
                      src={transcript}
                      alt="Transcript"
                      className="w-5 h-5"
                    />
                    <div className="ml-2">Transcript</div>
                  </div>
                </div>

                <div
                  className="flex items-left justify-start md:pt-10 cursor-pointer"
                  onClick={handleListenClick}
                >
                  <img src={listen} alt="Listen" className="w-5 h-5" />
                  <div className="ml-2">Listen</div>
                </div>

                {showVideoModal && (
                  <div className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex justify-center items-center">
                    <div className="relative w-full h-full">
                      <h2 className="text-white text-lg font-bold mb-4 text-center">
                        Listen to Podcast
                      </h2>
                      <div className="w-full h-full relative">
                        <iframe
                          src={getYouTubeEmbedUrl(youtubeLink)}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="w-full h-full px-20 py-40 md:px-28 md:pb-16 md:pt-10"
                        ></iframe>

                        <button
                          className="absolute top-10 right-10 bg-gray-700 text-white rounded-full w-10 h-10 flex items-center justify-center"
                          onClick={closeVideoModal}
                        >
                          <span className="text-2xl">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-[0.5px] bg-black mt-10"></div>
        <div className="w-full text-[#666666] mt-5 flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 p-5">
            <CustomMarkdownRenderer
              content={formdetails}
              setHeadings={() => {}}
            />
          </div>

          <div className="w-full md:w-1/2 p-5">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="fullname"
                  value={fullname}
                  onChange={(e) => setfullname(e.target.value)}
                  placeholder="  Full Name"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.fullname && (
                  <p classfullname="text-red-500 text-sm ">{errors.fullname}</p>
                )}
              </div>

              <div>
                <input
                  type="email"
                  name=" email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder=" Email"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.email && (
                  <p classemail="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div>
                <input
                  type="tel"
                  name="phonenumber"
                  value={phonenumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow digits
                    if (/^\d*$/.test(value)) {
                      setphonenumber(value);
                    }
                  }}
                  placeholder="  Phone Number"
                  required
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.phonenumber && (
                  <p className="text-red-500 text-sm">{errors.phonenumber}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="leavenote"
                  value={leavenote}
                  onChange={(e) => setleavenote(e.target.value)}
                  placeholder="  Leave us a note"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.leavenote && (
                  <p classleavenote="text-red-500 text-sm">
                    {errors.leavenote}
                  </p>
                )}
              </div>

              <div>
                <button
                  type="submit"
                  className="bg-white rounded-xl border border-[#969696] text-[#666666] font-semibold px-16 py-2 mt-4 font-display"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full h-[0.5px] bg-black mt-5"></div>

        {isSuccessOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <div className="w-full text-[#666666] mt-10">
          <div className="lg:text-lg font-bold pb-5">Recent Episodes</div>

          {pastpodcastData?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
              {pastpodcastData.map((pastpodcast, index) => {
                const pastDetails =
                  pastpodcast.past_details || "No past details available.";
                const pastyoutubeLink = pastpodcast.pastyoutube_url || "";
                const thumbnailUrl2 = getYouTubeThumbnailUrl(
                  pastyoutubeLink,
                  "sddefault"
                );

                return (
                  <div
                    key={index}
                    className="bg-[#F5ECFA] rounded-2xl relative mb-10"
                  >
                    <div>
                      {thumbnailUrl2 ? (
                        <div
                          className="relative cursor-pointer"
                          onClick={() => setShowVideoModal2(index)}
                        >
                          <img
                            src={thumbnailUrl2}
                            alt="YouTube thumbnail"
                            className="w-full p-5 flex rounded-lg"
                          />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="p-4">
                              <svg
                                className="w-16 h-16 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <circle
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="white"
                                  strokeWidth="2"
                                  fill="transparent"
                                />
                                <path d="M10 8.27L15 12 10 15.73V8.27z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div>No past images available.</div>
                      )}
                    </div>

                    {showVideoModal2 === index && (
                      <div className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex justify-center items-center">
                        <div className="w-full h-full">
                          <h2 className="text-white text-lg font-bold mb-4 pt-5 text-center">
                            Listen to Podcast
                          </h2>
                          <div className="w-full h-full">
                            <iframe
                              src={getYouTubeEmbedUrl2(pastyoutubeLink)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              className="w-full md:w-full md:h-full px-20 py-40 md:px-28 md:pb-20 md:pt-0"
                            ></iframe>
                          </div>
                          <button
                            className="absolute top-10 right-10 bg-gray-700 text-white rounded-full w-10 h-10 flex items-center justify-center"
                            onClick={closeVideoModal2}
                          >
                            <span className="text-2xl">&times;</span>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="px-5 text-sm pb-5 pt-3 bg-[#F5ECFA] w-full">
                      <CustomMarkdownRenderer
                        content={pastDetails}
                        setHeadings={() => {}}
                      />
                    </div>

                    <div className="flex justify-center items-center pb-10">
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={handleShareClick2}
                      >
                        <img src={share} alt="Share" className="w-5 h-5" />
                        <div className="ml-2">Share</div>
                        {showShareOptions2 && (
                          <div className="absolute bottom-0 left-20 md:left-0 md:ml-12 lg:ml-12 2xl:ml-40 p-3 shadow-lg z-50">
                            <div className="flex items-center space-x-4">
                              <a
                                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                                  "Check out this podcast: " +
                                    upcomingpodcastHeading
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={whatsapp}
                                  alt="WhatsApp"
                                  className="w-5 h-5"
                                />
                              </a>

                              <a
                                href={`mailto:?subject=${encodeURIComponent(
                                  "Check out this podcast"
                                )}&body=${encodeURIComponent(
                                  "I found this podcast interesting: " +
                                    upcomingpodcastHeading
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={mail}
                                  alt="Email"
                                  className="w-5 h-5"
                                />
                              </a>

                              <img
                                src={instagram}
                                alt="Instagram"
                                className="w-5 h-5 cursor-pointer"
                                onClick={() =>
                                  alert(
                                    "To share on Instagram, please copy the link and share it manually."
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="mx-3">|</div>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => handleTranscriptClick2(index)}
                      >
                        <img
                          src={transcript}
                          alt="Transcript"
                          className="w-5 h-5"
                        />
                        <div className="ml-2">Transcript</div>
                      </div>
                    </div>

                    {showTranscriptModal2 === index && (
                      <div className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex justify-center items-center">
                        <div className="bg-white p-8 rounded-lg max-w-lg mx-auto">
                          <h2 className="text-lg font-bold mb-4">
                            Podcast Transcript
                          </h2>
                          <div className="text-sm">
                            <CustomMarkdownRenderer
                              content={
                                pastpodcast.past_transcript ||
                                "Transcript not available."
                              }
                              setHeadings={() => {}}
                            />
                          </div>
                          <button
                            className="bg-gray-700 text-white py-2 px-4 rounded-lg mt-4"
                            onClick={closeTranscriptModal2}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No past podcasts available.</div>
          )}
        </div>
      </div>

      {showTranscriptModal && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg max-w-lg mx-auto">
            <h2 className="text-lg font-bold mb-4">Podcast Transcript</h2>
            <div className="text-sm">
              <CustomMarkdownRenderer
                content={transcriptContent}
                setHeadings={() => {}}
              />
            </div>
            <button
              className="bg-gray-700 text-white py-2 px-4 rounded-lg mt-4"
              onClick={closeTranscriptModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {showTranscriptModal2 && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg max-w-lg mx-auto">
            <h2 className="text-lg font-bold mb-4">Podcast Transcript</h2>
            <div className="text-sm">
              <CustomMarkdownRenderer
                content={transcriptContent2}
                setHeadings={() => {}}
              />
            </div>
            <button
              className="bg-gray-700 text-white py-2 px-4 rounded-lg mt-4"
              onClick={closeTranscriptModal2}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className=" mt-20 lg:mt-0">
        <Popup />
      </div>
    </div>
  );
};

export default Podcast;
