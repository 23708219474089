import styles from "../styles/MascotChatIcon.module.css";
import chatIcon from "../assets/Chatbot.svg";
import React from 'react';

// interface MascotChatIconProps {
//   onClick: () => void;
// }

const MascotChatIcon = ({ onClick }) => {
  return (
    <div className={styles.chatIcon} id="chatIconLocal" onClick={onClick}>
      <img src={chatIcon} className={styles.iconImage} id="chatIconLocalImg" />
    </div>
  );
};

export default MascotChatIcon;
