import styles from "../styles/ThankYouSection.module.css";
import thankYouImage from "../assets/thankyou.svg";
import mockUp from "../assets/mockup.png";
import demo from "../assets/demo2.mp4";
import box1 from "../assets/box1.svg";

const ThankYouPage = () => {
  return (
    <div className={styles.thankYouContainer}>
      <img src={box1} alt="Box" className={styles.boxImage} />
      <div className={styles.messageContainer}>
        <img
          src={thankYouImage}
          alt="Thank You"
          className={styles.thankYouImage}
        />
        <h1 className={styles.text}>Thank You</h1>
        <p className={styles.desc}>
          Your submission has been received. <br />
          We'll reach out shortly.
        </p>
      </div>
      <div className={styles.demoContainer}>
        <h2 className={styles.demoText}>Demo</h2>
        <div className={styles.mockUpWrapper}>
          <img src={mockUp} alt="Demo" className={styles.demoImage} />
          <video className={styles.demoVideo} src={demo} autoPlay loop muted />
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
