
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import hang from "../assets/Managed/hanging.png";
import mann1 from "../assets/Managed/man1.png";
import mann2 from "../assets/Managed/man2.png";
import mann3 from "../assets/Managed/man3.png";
import mann4 from "../assets/Managed/man4.png";
import man7 from "../assets/Managed/man7.png";
import mann6 from "../assets/Managed/man6.png";
import cubes from "../assets/Managed/cubes.png";
import pink from "../assets/Managed/pink.png";
import lap from "../assets/Managed/lap.png";
import bubble1 from "../assets/Managed/bubble1.png";
import line from "../assets/Managed/line.png";
import triangle from "../assets/Managed/triangle.png";
import contact from "../assets/Managed/contact.png";
import recright from "../assets/Integration/recright.png";
import "./Managed.css";

import Popup from "../components/popup.js";

export const Managed = () => {
  const container = useRef();

  return (

    <div className="Main2 font-display mt-16  md:mt-0">
        
      <div className="bg-custom-manage h-72 md:h-screen flex justify-center items-center">
        <div className="flex flex-row md:flex-row items-center  text-md font-bold px-8 md:px-0 md:text-4xl lg:text-5xl 2xl:text-6xl lg:pl-10 lg:max-w-screen-2xl ">
          <div className="md:text-left font-bold  text-[#666666] md:ml-8  md:w-1/2  2xl:w-3/4">

            Your Developers Our AI Developers.
            <br />
            <span className='text-[#C432F9]'>Integrated.</span>
          </div>
          <img src={cubes} alt='Main SVG' className='w-44 md:w-5/12 2xl:w-9/12 lg:w-6/12 md:py-32 lg:px-20' />
        </div>
      </div>

      <div className=' flex lg:justify-center  '>
        <div className=' w-full lg:max-w-screen-lg 2xl:max-w-screen-2xl mr-0 lg:mr-12 '>
          <div class='lg:flex justify-center items-center '>
            <div class='flex flex-col md:flex-row items-center'>
              <div class='font-display text-xs lg:text-xs lg:pt-10   '>
                <img
                  src={pink}
                  alt='Main SVG'
                  class='  absolute -left-8 lg:-left-10 2xl:-left-12 w-20 2xl:w-32  md:w-fit lg:w-fit  2xl:w-fit'
                />
                <img
                  src={lap}
                  alt='AI Integration'
                  class='block md:hidden rounded-tl-none rounded-2xl w-9/12 h-auto mx-auto mt-10'
                />


                <div className="md:px-5  md:mt-10 mt-10 px-10  pr-10 md:pl-16 ">
                  <div className="relative text-[#666666]  font-display font-bold text-sm lg:text-3xl md:text-lg text-left lg:font-bold pb-2 lg:pb-5  ">
                    AI Managed Services
                    <br />
                  </div>
                  <div className="md:pb-3  lg:text-base md:text-xs text-xs  2xl:text-lg text-[#666666]  pb-2">
                    As a business testing out the waters, you may not have the
                    inclination to deploy and invest in a full-fledged AI team.
                    In other cases, a small AI team at your end many need more
                    resources to bring an integration project to fruition in a
                    time-bound manner.
                  </div>
                  <div className="md:pb-3 lg:text-base md:text-xs text-xs 2xl:text-lg text-[#666666]  pb-2 ">
                    Random Walk provides tenured AI professionals who bring vast
                    experience and talent into each engagement. The cost savings
                    that this model allows mitigates risk arising from exploring
                    an uncharted territory.
                  </div>
                  <div className="md:pb-3 lg:text-base md:text-xs text-xs  2xl:text-lg text-[#666666] pb-2 ">
                    Having worked with businesses similar to yours, we have
                    imbibed the best practices and learnings from integrating AI
                    tools into applications. Random Walk’s expert engineers will
                    help you build a case around new situations and implement AI
                    integration quickly.
                  </div>
                  <div className="md:pb-3 lg:text-base md:text-xs text-xs   2xl:text-lg text-[#666666]  pb-2 ">
                    Discover the benefits of AI Managed Services with Random
                    Walk – your trusted partner in navigating the complexities
                    of AI integration.

                  </div>
                </div>
              </div>

              <img
                src={lap}
                alt='AI Integration'
                class=' hidden md:block rounded-tl-none  rounded-2xl lg:scale-125 2xl:scale-110 w-44 h-56  md:h-72 lg:h-64 mt-10 md:w-5/12  lg:w-4/12 lg:mt-20  2xl:ml-20 lg:ml-20 '
              />
            </div>
          </div>
        </div>
      </div>

      <img src={hang} alt='hi' className='lg:mt-32  absolute left-0 right-0 -ml-screen mr-screen mt-12 w-full' />

      <div className='  mt-24 md:mt-20 lg:mt-56'>
        <div className='flex  flex-col md:flex-row'>
          <div className='flex md:hidden justify-center items-center'>
            <img src={mann1} alt='AI Integration' className='w-6/12' />
          </div>

          <div className='w-full lg:mt-10 md:mt-1 lg:w-1/2 bg-white md:px-10 lg:px-16'>
            <div className='relative p-5 rounded-md bg-[#ffffff]  z-10'>
              <div className='absolute  w-7/12 right-0 -top-10 md:w-9/12 lg:w-8/12 md:-top-20 md:left-0 md:max-w-md lg:max-w-lg'>
                <img src={bubble1} alt='Bubble Background' />
              </div>

              <div className='md:text-lg lg:text-3xl text-[#666666] font-semibold 2xl:text-4xl z-10 py-6 lg:py-12 md:py-10 px-2 md:px-0'>
                Quick Mobilization
              </div>
              <div className='text-xs font-display lg:text-base 2xl:text-xl text-[#666666] px-2 md:px-0'>
                Random Walk’s AI Managed Services eliminates the need for in-house AI resources. This not only trims
                overhead costs but also maximizes your ROI.
                <br />
                <br />
                Our adept AI developers, architects, NLP engineers, AI ML engineers, data engineers, and data scientists
                form a fully managed team. They ensure continual monitoring, optimization, and enhancement of integrated
                AI systems, ensuring peak performance that adapts to your evolving needs.
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className='hidden  md:block  flex lg:w-3/12 md:w-5/12  2xl:w-3/12 2xl:mt-20  md:mt-20 lg:mt-32 lg:ml-44'>
            <img src={mann1} alt='AI Integration' className='' />
          </div>
        </div>

        <div className='flex flex-col md:flex-row-reverse relative  md:mt-20  lg:mb-32 '>
          <div className='flex md:hidden justify-center items-center'>
            <img src={mann2} alt='AI Integration' className='w-7/12' />
          </div>
          <div className='hidden md:block absolute md:w-12 lg:w-24 2xl:w-44 bottom-44 right-0 z-0'>
            <img src={line} alt='Bubble Background' className='relative' />
          </div>

          <div className='lg:w-full lg:ml-20 lg:mt-28'>
            <div className='ml-3 relative bg-white z-10 p-5 rounded-md bg-[#ffffff]  '>
              <div className='absolute w-7/12  md:w-8/12 lg:w-9/12 bottom-44 -left-20 md:top-44 md:right-10 lg:-bottom-44 lg:left-32 z-0'>
                <img src={bubble1} alt='Bubble Background' className='relative' />
              </div>

              <div className='md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-0'>
                Managed AI Integration
              </div>
              <div className='text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl 2xl:max-w-4xl lg:max-w-lg px-2 md:px-0'>
                Offload the burdens of recruiting, onboarding, and managing resources by leveraging Random Walk’s AI
                integrators, allowing your HR team to focus on core activities.
                <br />
                Scale up your workforce up or down rapidly based on project requirements, eliminating the need for
                long-term commitments while expediting project timelines and meeting critical deadlines using Random
                Walk’s AI managed services.
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className='hidden md:block  lg:w-full lg:mt-16 md:mt-16'>
            <img src={mann2} alt='AI Integration' className='lg:w-9/12 2xl:w-8/12 md:w-9/12 ml-10 2xl:ml-44' />
          </div>
        </div>

        <div className='flex flex-col  md:flex-row relative  md:mt-20 lg:mt-0'>
          <div className='flex md:hidden justify-center items-center'>
            <img src={mann3} alt='AI Integration' className='w-7/12' />
          </div>
          <div className='hidden md:block absolute md:w-20  2xl:w-56 lg:w-44 md:bottom-44   lg:bottom-20 lg:left-0 z-0'>
            <img src={triangle} alt='Bubble Background' className='relative' />
          </div>
          <div className='w-full lg:mt-10 lg:w-7/12   z-20 relative p-5 rounded-md bg-[#ffffff]  '>
            <div className='absolute md:w-8/12 lg:w-8/12 w-7/12 -bottom-20 -right-0 md:-bottom-44 md:-left-36  2xl:-bottom-56 z-0'>
              <img src={bubble1} alt='Bubble Background' className='' />
            </div>

            <div className='md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-6 lg:px-10'>
              Integrate With Existing Team
            </div>
            <div className='text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl md:max-w-base 2xl:max-w-4xl lg:max-w-2xl px-2 md:px-6 lg:px-10'>
              Bolster your team’s capabilities with specialized AI expertise and resources to tackle AI integration
              project and challenges
              <br />
              <br />
              Your existing IT teams may or may not be actively involved in AI related projects. It could also be that
              AI is not the key focus of your IT team at the moment. In this case, your existing team could continue to
              expend their efforts on business critical projects, whilst having Random Walk’s professionals make sure
              you do not miss out on the AI bandwagon.
              <br />
              <br />
            </div>
          </div>

          <div className='hidden md:block flex justify-center items-center w-full md:w-7/12 lg:w-6/12 mt-4 md:mt-32 lg:ml-10'>
            <img src={mann3} alt='AI Integration' className='w-full lg:w-9/12  md:w-11/12' />
          </div>
        </div>

        <div className='flex flex-col md:flex-row-reverse relative  md:mt-20 lg:mt-28 lg:mb-32 pt-1 '>
          <div className='flex md:hidden justify-center items-center'>
            <img src={mann4} alt='AI Integration' className='w-6/12' />
          </div>
          <div className='hidden md:block absolute md:w-16 lg:w-24 2xl:w-44 bottom-44 right-0 z-0'>
            <img src={line} alt='Bubble Background' className='relative' />
          </div>

          <div className='lg:w-full lg:ml-20 lg:mt-28'>
            <div className='ml-3 relative bg-white z-20 p-5 rounded-md bg-[#ffffff]  '>
              <div className='absolute md:w-8/12 lg:w-9/12 w-7/12  md:-bottom-44  lg:-bottom-44  md:right-0 lg:left-32 lg:top-72 md:top-56 left-0 z-0'>
                <img src={bubble1} alt='Bubble Background' className='relative' />
              </div>

              <div className='md:text-lg text-[#666666] lg:text-3xl font-semibold  2xl:text-4xl py-6  lg:py-9'>
                Lower Operational Cost
              </div>
              <div className='text-xs lg:font-display text-[#666666] lg:text-base 2xl:text-xl  md:max-w-4xl  '>
                At Random Walk, our AI managed services pricing models are designed to provide flexibility for the
                unique needs of each client. For long-term partnerships, we offer fixed pricing to enable predictability
                and cost control. For short-term projects, our time and materials billing allows for adjustment as
                requirements evolve. <br />
                <br />
                We also provide the option to cap costs on T&M engagements, to reduce risk while maintaining agility.
                Our transparent pricing structure aims to facilitate an open dialogue on the best approach, aligned to
                the value we deliver for AI managed services.
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className='max-md:hidden lg:w-full lg:mt-10 2xl:mt-8 md:mt-20'>
            <img src={mann4} alt='AI Integration' className='lg:w-9/12 2xl:w-7/12 md:w-7/12 md:ml-20 lg:ml-20 ' />
          </div>
        </div>

        <div className='flex flex-col  md:flex-row relative md:mt-16 lg:mt-0'>
          <div className='flex md:hidden justify-center items-center'>
            <img src={mann6} alt='AI Integration' className='w-6/12' />
          </div>
          <div className='hidden md:block absolute  md:w-32 2xl:w-64 lg:w-44    bottom-32 left-0 z-0'>
            <img src={triangle} alt='Bubble Background' className='relative' />
          </div>
          <div className='w-full lg:mt-10 lg:w-7/12   z-20 relative p-5 rounded-md bg-[#ffffff]  '>
            <div className='absolute w-7/12 md:w-8/12 lg:w-8/12 md:-bottom-40 md:-left-36  top-40 left-0 z-0'>
              <img src={bubble1} alt='Bubble Background' className='' />
            </div>

            <div className='md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-6 lg:px-10'>
              Subject Matter Expertise
            </div>
            <div className='text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl md:max-w-base 2xl:max-w-4xl lg:max-w-2xl px-2 md:px-6 lg:px-10'>
              Our expert AI engineers have implemented a large number of integrations successfully and will provide you
              the necessary guidance from need assessment to successful integration and monitoring.
              <br />
              <br />
              Random Walk’s AI expertise is backed by deep domain knowledge and a proven track record of delivering
              successful AI projects across industries and various business functions.
            </div>
          </div>

          <div className='hidden  md:block flex justify-center items-center w-full md:w-6/12 lg:w-6/12 lg:mt-20 md:mt-16 md:mr-8 lg:mr-0 lg:ml-32'>
            <img src={mann6} alt='AI Integration' className='w-full lg:w-8/12 2xl:w-7/12' />
          </div>
        </div>

        <div className='flex flex-col md:flex-row-reverse relative  md:mt-20 lg:mt-0 lg:mb-32 md:mb-20 mb-10'>
          <div className='flex md:hidden justify-center items-center'>
            <img src={man7} alt='AI Integration' className='w-6/12' />
          </div>
          <div className='hidden md:block absolute md:w-16 lg:w-24 2xl:w-44 bottom-44 right-0 z-0'>
            <img src={line} alt='Bubble Background' className='relative' />
          </div>


          <div className="lg:w-full lg:ml-20 lg:mt-28">
            <div className="ml-3 relative bg-white z-0 p-5 rounded-md bg-[#ffffff] ">
              <div className="absolute md:w-8/12 lg:w-9/12 w-7/12 -bottom-20  right-0 lg:-bottom-44 lg:right-0  2xl:-bottom-56 z-0">
                <img
                  src={bubble1}
                  alt="Bubble Background"
                  className="relative"
                />

              </div>

              <div className='md:text-lg text-[#666666] lg:text-3xl font-semibold  2xl:text-4xl py-6  lg:py-9'>
                Quality Control
              </div>
              <div className='text-xs lg:font-display text-[#666666] lg:text-base 2xl:text-xl  md:max-w-4xl '>
                Random Walk provides periodic quality review of our engineers and have a broad set of KPIs to track such
                as integration efficiency, lead times, performance improvement and scalability. We also welcome KPIs
                that align with your organization’s goals, objectives, and the engineer’s role within the AI integration
                team
                <br />
                <br />
                We have a pool of engineers to choose from and this supports quality control as there is a broad skill
                set to choose from along with the critical factor of our engineers matching your organizations culture
                and maintaining a good rapport
                <br />
                <br />
              </div>
            </div>
          </div>

          <div className='hidden md:block lg:w-full lg:mt-44 md:mt-20 2xl:mt-32'>
            <img src={man7} alt='AI Integration' className='md:w-8/12 lg:w-9/12 2xl:w-8/12 lg:ml-20 md:ml-10' />
          </div>
        </div>
      </div>

      <div className=' mt-20 '>
        <Popup />
      </div>
    </div>
  );
};
export default Managed;
