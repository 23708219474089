

import Footer from "../components/Footer";
import React, { useState, useEffect, useRef } from "react";
import { Client, Databases, ID } from 'appwrite';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import contact1 from '../assets/Contact/contact1.png';
import contactt2 from '../assets/Contact/contact2.svg';
import contactt3 from '../assets/Contact/twitter.png';
import recright from '../assets/Integration/recright.png';
import { NavLink } from 'react-router-dom';
export const Contact = () => {
 
 
 
 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
 
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [businessemail, setEmailId] = useState('');
    const [projectdetails, setprojectdetails] = useState('');
    const [utmSource, setUtmSource] = useState('');
    const [utmMedium, setUtmMedium] = useState('');
    const [utmCampaign, setUtmCampaign] = useState('');
    const [utmId, setUtmId] = useState('');
    const [utmTerm, setUtmTerm] = useState('');
    const [utmContent, setUtmContent] = useState('');
 
    const [errors, setErrors] = useState({});
 
    const handleButtonClickcontact = () => {
      setIsModalOpen(true);
    };
 
    const closeModal = () => {
      setIsModalOpen(false);
    };
 
    const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);
 
    const databases = new Databases(client);
 
    const handlePhoneNumberChange = (value) => {
      // Remove all non-numeric characters except for leading '+'
      const cleanedValue = value.replace(/[^0-9+]/g, '');
      setPhoneNumber(cleanedValue);
    };
 
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
 
      setUtmSource(searchParams.get('utm_source') || '');
      setUtmMedium(searchParams.get('utm_medium') || '');
      setUtmCampaign(searchParams.get('utm_campaign') || '');
      setUtmId(searchParams.get('utm_id') || '');
      setUtmTerm(searchParams.get('utm_term') || '');
      setUtmContent(searchParams.get('utm_content') || '');
    }, []);
 
    const handleSubmit = async (event) => {
      event.preventDefault();
 
      try {
        await databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID2,
          ID.unique(),
          {
            firstname,
            lastname,
            businessemail,
            companyName,
            phoneNumber,
            projectdetails,
            utmSource,
            utmMedium,
            utmCampaign,
            utmId,
            utmTerm,
            utmContent,
          }
        );
    
        setIsModalOpen(false);
        setIsSuccessOpen(true);
    
      } catch (error) {
        console.error('Error creating document:', error);
      }
    };
 
    const closeSuccessModal = () => {
      setIsSuccessOpen(false);
    };
    const signupRef = useRef(null);
 
    useEffect(() => {
 
      if (window.location.hash === '#signup') {
        signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, []);
 
 
 
 
 
   
 
    const [formData, setFormData] = useState({
        name: '',
        email: '',
 
    });
 
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
 
 
    return (
 
 
<div className='Main2 font-display text-[#666666]'>
    <div className=" lg:py-32 bg-gradient_background5 flex ">
        <div className="flex flex-col md:flex-row w-full ">
            {/* Image Section */}
            <div className="max-md:hidden  mb-4 md:translate-y-52 lg:translate-y-20  md:mb-0 md:mr-4">
                <img
                    src={contact1}
                    alt="Main SVG"
                    className="w-full md:w-auto"
                />
            </div>
           
            {/* Text Section */}
            <div className="flex flex-col pt-24 px-5 md:px-0 md:pt-0 text-[#666666] md:justify-center">
                <p className='md:text-4xl md:pr-28 text-xl font-display'>
                    <span className='font-extrabold text-4xl md:text-4xl  text-[#666666] block mb-2'>
                        Contact
                    </span>
                    <span className='text-indigo-400  text-2xl font-bold md:text-xl block mb-1'>
                        Find Us On
                    </span>
                    <div className=' max-md:hidden md:text-sm text-sm mb-3'>
                        Get in touch with our <br/> team to discuss about<br/> your project.
                    </div>
                    <div className='md:hidden text-base   mb-3'>
                        Get in touch with our team to discuss about  your project.
                    </div>
                    <div className='text-[#666666]  text-base md:text-sm pb-1'>
                        <span className='font-bold mr-1'>
                            Call us:
                        </span>
                         +91 784 575 7355
                    </div> 
                     <div className='text-[#666666]  text-base md:text-sm mb-3'>
                        <span className='font-bold mr-1'>
                         Email: 
                        </span>
                   enquiry@randomwalk.ai
                    </div>
                    
                    <div className='flex  flex-row mb-3 '>
                    <a href="https://www.linkedin.com/company/randomwalk-ai/mycompany/verification/" target="_blank" rel="noopener noreferrer">
                        <img
                            src={contactt2}
                            alt="Contact Icon"
                            className="h-8 mt-[2px]  w-8 mr-4"
                        />
                       </a>
                       <a href="https://x.com/i/flow/login?redirect_after_login=%2Frandomwalk_ai" target="_blank" rel="noopener noreferrer">
                        <img
                            src={contactt3}
                            alt="Contact Icon"
                            className="h-9 w-9"
                        />
                        </a>
                    </div>
                </p>
            </div>
 
            <div className=" lg:pt-10 p-5  md:py-24  md:w-5/12 md:pr-10  z-20 relative ">
            <div className=" max-md:hidden  absolute top-0  md:left-2 lg:left-0 -z-10 md:translate-y-20  md:w-10/12 lg:w-auto lg:translate-x-[350px]">
                  <img src={contact1} alt="Bubble Background" className=" " />
                </div>
                <form    onSubmit={handleSubmit} className="bg-white 2xl:-mt-5  p-5 md:p-4 shadow-md rounded">
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700 text-sm mb-2">First Name</label>
                        <input
                            type="text"
                   
                            name="firstname"
                            value={firstname}
                            onChange={(e) => setfirstname(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter first name"
                            required
                        />
                        {errors.firstname && <p className='text-red-500 text-sm'>{errors.firstname}</p>}
                    </div>
 
                    <div className="mb-4">
                        <label htmlFor="lastName" className="block text-gray-700 text-sm mb-2">Last Name</label>
                        <input
                            type="text"
                   
                            name="lastName"
                            value={lastname
                            }
                            onChange={(e) => setlastname(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter last name"
                            required
                        />
                         {errors.lastname && <p className='text-red-500 text-sm'>{errors.lastname}</p>}
                    </div>
 
                    <div className="mb-4">
                        <label htmlFor="number" className="block text-gray-700 text-sm mb-2">Phone Number</label>
                        <input
                     
                            type="tel"
                       
                            name="number"
                            value={phoneNumber}
                            onChange={(e) =>
                              handlePhoneNumberChange(e.target.value)
                            }
                            inputClass='custom-phone-input2'
                            buttonClass='border rounded'
                            dropdownClass='border rounded'
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter phone number"
                            required
                        />
                          {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="companyName" className="block text-gray-700 text-sm mb-2">Company Name</label>
                        <input
                            type="text"
                     
                            name='companyName'
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter company name"
                            required
                        />
                          {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                    </div>
 
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 text-sm mb-2">Business Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={businessemail}
                            onChange={(e) => setEmailId(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter email address"
                            required
                        />
                          {errors.businessemail && <p className='text-red-500 text-sm'>{errors.businessemail}</p>}
                    </div>
 
                    <div className="mb-4">
                        <label htmlFor="project" className="block text-gray-700 text-sm mb-2">Project Details</label>
                        <input
                            type="text"
                            id="project"
                            name="project"
                            value={projectdetails}
                            onChange={(e) => setprojectdetails(e.target.value)}
                            className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Enter project details"
                            required
                        />
                        {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                    </div>
 
                    <div className="flex items-center justify-center mt-5">
                        <button
                            type="submit"
                            className="bg-gray-500 hover:text-white border hover:border-none border-none hover:bg-gray-500 rounded w-full py-3 text-white font-bold focus:outline-none focus:shadow-outline"
                         
                        >
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
     
    {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
 
   
 
</div>
 
 
 
    );
}