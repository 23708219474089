
import { NavLink } from 'react-router-dom';
import React from 'react';
 import footer from "../assets/Footer/footer.png";
 import linkedinn from '../assets/Footer/linkedin.png';
import twitterr from '../assets/Footer/twitter.png';
 
const Footer = () => {
  return (
    <footer className="bg-gradient_background8 font-display text-white py-10 px-4 ">
      <div className="flex flex-row justify-between items-center ">
        <img src={footer} alt="Logo" className="lg:h-auto lg:w-3/12 md:w-4/12 lg:px-20  md:px-10 w-4/12 " />
        <div className="md:flex md:flex-row mb-3 mt-3 flex flex-row items-center md:px-20 px-10">
  <a href="https://www.linkedin.com/company/randomwalk-ai/mycompany/verification/" target="_blank" rel="noopener noreferrer">
    <img src={linkedinn} alt="LinkedIn" className="lg:h-auto lg:w-6 md:w-6 w-6" />
  </a>
  <a href="https://x.com/i/flow/login?redirect_after_login=%2Frandomwalk_ai" target="_blank" rel="noopener noreferrer">
    <img src={twitterr} alt="Twitter" className="lg:h-auto lg:w-5 ml-4 md:w-5 w-5" />
  </a>
</div>
 
      </div>
     
      <div className="flex flex-wrap justify-between mt-5 gap-2 lg:px-20 md:px-10">
        <div className="w-44 md:w-1/4 lg:w-40 ">
          <h4 className="mb-3 font-bold">Services</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5 text-sm'><NavLink to="/ai-integration" className="hover:text-white">AI Integration</NavLink></div>
            <div className='pb-5 text-sm'><NavLink to="/ai-managed-services" className="hover:text-white">AI Managed Services</NavLink></div>
            <div className='pb-5 text-sm'><NavLink to="/ai-workshop-for-corporates" className="hover:text-white">AI workshop for corporates</NavLink></div>
          </div>
        </div>
       
        <div className="w-44 md:w-1/4 lg:w-40">
          <h4 className="mb-3 font-bold">Products</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/ai-readiness" className="hover:text-white">AI Readiness Index</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/brandcut" className="hover:text-white">BrandCut</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="fortunecookie" className="hover:text-white">AI Fortune Cookie</NavLink></div>
          </div>
        </div>
        <div className="w-44 md:w-44 lg:w-20">
          <h4 className="mb-3 font-bold">Company</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm' ><NavLink to="/about-us" className="hover:text-white">About Us</NavLink></div>
            <div className='pb-5 text-sm '><NavLink to="/careers" className="hover:text-white">Careers</NavLink></div>
       
          </div>
        </div>
   
        <div className="w-44 md:w-1/4 lg:w-20 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Resources</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/blogs" className="hover:text-white">Blogs</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/brochures" className="hover:text-white">Brochures</NavLink></div>
          </div>
        </div>
        <div className="w-44 md:w-1/4 lg:w-20 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Events</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm' ><NavLink to="/podcasts" className="hover:text-white">Podcasts</NavLink></div>
            <div className='pb-5 text-sm '><NavLink to="/webinars" className="hover:text-white">Webinars</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/events" className="hover:text-white">PlayLab</NavLink></div>
           
          </div>
        </div>
       
        <div className="w-44 md:w-1/4 lg:w-56 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Support</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/contact-us" className="hover:text-white">Contact Us</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/terms-of-service" className="hover:text-white">Terms of Service</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/privacy-policy" className="hover:text-white">Privacy Policy</NavLink></div>
          </div>
        </div>
      </div>
     
      {/* Gray line */}
      <div className="border-t w-full my-3 border-gray-300 "></div>
     
      <p className="text-center text-xs">© Random Walk. All Rights Reserved 2024.</p>
    </footer>
  );
}
 
export default Footer;