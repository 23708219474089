
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomMarkdownRenderer from "../components/Markdown.js";
import jobitt from "../assets/BlogContent/jobit.png";
import "./BlogContent.css";
import Popup from "../components/popup.js";
import { NavLink } from "react-router-dom";

import recright from "../assets/Integration/recright.png";

export const BlogContent = ({ blogs }) => {
  const { id } = useParams();
  const [headings, setHeadings] = useState([]);
  const [activeHeading, setActiveHeading] = useState(null);
  const sidebarRef = useRef(null);
  const contentRef = useRef(null);
  const isManualScrollRef = useRef(false);
  let blog = {};

  const NAVBAR_HEIGHT = 110;
  const SCROLL_OFFSET = 100;

  useEffect(() => {
    const updateActiveHeading = () => {
      if (isManualScrollRef.current) {
        return;
      }

      const scrollPosition = window.scrollY + NAVBAR_HEIGHT + SCROLL_OFFSET;

      let activeHeading = null;
      for (let i = headings.length - 1; i >= 0; i--) {
        const element = document.getElementById(headings[i].id);
        if (element && element.offsetTop <= scrollPosition) {
          activeHeading = headings[i].id;
          break;
        }
      }


      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 50
      ) {

        activeHeading = headings[headings.length - 1]?.id;
      }

      if (activeHeading) {
        setActiveHeading(activeHeading);
      }
    };

    const scrollSidebarToActiveHeading = () => {
      if (sidebarRef.current && activeHeading) {

        const activeElement = sidebarRef.current.querySelector(
          `[data-id="${activeHeading}"]`
        );


        if (activeElement) {
          const sidebarTop = sidebarRef.current.offsetTop;
          const activeElementTop = activeElement.offsetTop;
          const sidebarHeight = sidebarRef.current.clientHeight;
          const activeElementHeight = activeElement.clientHeight;


          let targetScrollTop =
            activeElementTop - sidebarHeight / 2 + activeElementHeight / 2;
          targetScrollTop = Math.max(
            0,
            Math.min(
              targetScrollTop,
              sidebarRef.current.scrollHeight - sidebarHeight
            )
          );

          sidebarRef.current.scrollTo({
            top: targetScrollTop,
            behavior: "smooth",

          });
        }
      }
    };

    const handleScroll = () => {
      requestAnimationFrame(() => {
        updateActiveHeading();
        scrollSidebarToActiveHeading();
      });
    };


    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateActiveHeading);

    updateActiveHeading();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateActiveHeading);
    };
  }, [headings, activeHeading]);

  if (blogs) {
    let arr = blogs.filter((blog) => blog.id == id);
    blog = arr[0];
  }

  const handleScrollToHeading = (id) => {
    isManualScrollRef.current = true;
    setActiveHeading(id);

    const element = document.getElementById(id);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;

      const offsetPosition =
        elementPosition + window.pageYOffset - NAVBAR_HEIGHT - SCROLL_OFFSET;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",

      });

      setTimeout(() => {
        isManualScrollRef.current = false;
      }, 1000); // Adjust this delay if needed
    }
  };

  return (
    <>

      <div className="flex flex-col justify-center items-start pt-12">
        <div className="w-full md:w-full px-10 md:px-20 md:pt-10 2xl:pt-10">
          <div className="flex items-start py-3 pt-10">
            <div>
              <div className="text-sm font-bold 2xl:text-2xl lg:text-3xl text-pink-600 font-display">
                The Random Walk Blog
              </div>
              <div className="text-sm lg:text-lg font-display  text-[#666666] font-bold  2xl:text-2xl">
                {blog.blogDate}
              </div>
            </div>
          </div>

          <div className="font-bold 2xl:text-5xl md:text-4xl  text-[#666666] font-display text-xl md:text-left">
            {blog.blogTitle}

          </div>
        </div>

        <img

          className="h-auto w-full object-cover px-10 py-5  md:px-10 lg:px-20 md:py-5 "
          src={`${blog.coverImg?.url}`}
          alt="Blog Cover"
        />

        <div className="flex flex-col md:flex-row md:px-20   text-[#666666]  w-full justify-center">
          <div className="lg:w-1/3 ">
            <div className="text-[#666666]  max-md:hidden sidebar  font-display  sticky 2xl:top-16 2xl:pt-5">
              <div className="sidebar-header  md:translate-y-12 lg:translate-y-16 2xl:translate-y-0 flex items-center ">
                <img
                  src={jobitt}
                  alt="Jobit"
                  className="w-20 rounded-full mr-4"
                />
                <div className="font-bold">Jobit </div>

              </div>

              <div
                ref={sidebarRef}

                className="sidebar-content md:px-2"
                style={{ maxHeight: "calc(100vh - 12rem)", overflowY: "auto" }}
              >
                <ul className="headings-list   md:min-h-[2000px]  ">

                  {headings.map((heading, index) => (
                    <li
                      key={index}
                      data-id={heading.id}

                      className={`heading-item lg:text-[15px] md:text-[10px] 2xl:text-[20px] md:translate-y-12 lg:translate-y-12 2xl:translate-y-0  ${
                        activeHeading === heading.id ? "active" : ""

                      } level-${heading.level}`}
                      onClick={() => handleScrollToHeading(heading.id)}
                    >
                      {heading.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div
            ref={contentRef}
            className="md:w-full mb-20 lg:pr-32 px-10 md:px-5 lg:px-0  lg:pl-10"
          >
            <div className="font-display text-[#666666] md:text-sm 2xl:text-xl">
              {/* <CustomMarkdownRenderer
              content={blog.blogContent}
              setHeadings={setHeadings}
            />  */}

              <CustomMarkdownRenderer
                content={blog.blogContent.replaceAll(
                  "http://localhost:1337/uploads/",
                  "https://devrw.blob.core.windows.net/rw-website/assets/"
                )}
                setHeadings={setHeadings}
              />

            </div>
          </div>
        </div>
      </div>


      <div className="mt-10">

        <Popup />
      </div>
    </>
  );
};
