import React, { useState, useEffect, useRef } from "react";
import { Client, Databases, ID } from 'appwrite';
import { NavLink } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import recright from "../assets/Home/recright.png";
export const Popup = () => {

    
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [businessemail, setEmailId] = useState('');
  const [projectdetails, setprojectdetails] = useState('');
  const [utmSource, setUtmSource] = useState('');
  const [utmMedium, setUtmMedium] = useState('');
  const [utmCampaign, setUtmCampaign] = useState('');
  const [utmId, setUtmId] = useState('');
  const [utmTerm, setUtmTerm] = useState('');
  const [utmContent, setUtmContent] = useState('');

  const [errors, setErrors] = useState({});

  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client().setEndpoint(process.env.REACT_APP_ENDPOINT).setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (value) => {
    // Remove all non-numeric characters except for leading '+'
    const cleanedValue = value.replace(/[^0-9+]/g, '');
    setPhoneNumber(cleanedValue);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmSource(searchParams.get('utm_source') || '');
    setUtmMedium(searchParams.get('utm_medium') || '');
    setUtmCampaign(searchParams.get('utm_campaign') || '');
    setUtmId(searchParams.get('utm_id') || '');
    setUtmTerm(searchParams.get('utm_term') || '');
    setUtmContent(searchParams.get('utm_content') || '');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
   
    if (!phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    }
   
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID2,
        ID.unique(),
        {
          firstname,
          lastname,
          businessemail,
          companyName,
          phoneNumber,
          projectdetails,
          utmSource,
          utmMedium,
          utmCampaign,
          utmId,
          utmTerm,
          utmContent,
        }
      );
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      // Navigate to a FC App in new tab with query params email and name
      // and set cookie access-token with eY....
      // document.cookie = `access-token=${process.env.REACT_APP_JWT_TOKEN}`;
      // const redirectUrl = `${process.env.REACT_APP_FORM_REDIRECT_URL}/?name=${name}&email=${email}`;
      // window.open(redirectUrl, '_blank');
    } catch (error) {
      console.error('Error creating document:', error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };
  const signupRef = useRef(null);

  useEffect(() => {

    if (window.location.hash === '#signup') {
      signupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <>
       <div>
       <div
          className="text-[#666666] min-h-[140px] md:min-h-[230px] relative -mt-20 bg-no-repeat bg-cover"
          style={{
            background: "linear-gradient(100deg , #3E59B7 90%, #A6BAFF 110%)", // Example pink gradient
          }}
        >
          <div className="absolute bottom-0 right-0">
            <img
              src={recright}
              alt="Additional"
              className="lg:w-40 lg:h-auto w-24 h-auto md:w-40 md:h-full"
            />
          </div>
          <div className="absolute  inset-0 flex flex-col justify-center items-center text-center text-white pt-4 md:pt-10 md:mr-32 lg:pt-5 md:ml-20">
            <h2 className="md:text-xl lg:text-2xl text-sm font-bold mb-4 font-display">
              Your Random Walk Towards AI Begins Now
            </h2>
            <button className="md:mt-3 mb-3 rounded-xl border text-white border-white md:py-3 md:px-20 px-8 py-2  transition-all duration-300 ease-in-out bg-transparent hover:bg-white hover:text-[#777676]"onClick={handleButtonClickcontact}>
        
              <div className="flex items-center ">
           
                <span className="text-xs md:text-lg font-bold"  >Contact</span>
              </div>
            </button>
          </div>
        </div>
          {isModalOpen && (
            <div className='fixed z-50 inset-0 md:p-0 lg:px-20 p-12  bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-[#F1F6FF]   p-8 lg:p-6 rounded-lg max-h-screen   max-w-md w-full'>
                <button className='absolute top-2 right-2 text-gray-500 hover:text-gray-700' onClick={closeModal}>
                  <svg
                    className='lg:w-6 lg:h-6 w-4 h-4'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                {/* <div className='text-lg md:text-xl text-center font-bold mb-4'>
                  Experience the Power of Data <br />
                  With
                  <span className='text-[#C62B6D] font-bold'> AI Fortune </span>
                  Cookie
                </div> */}
                <form onSubmit={handleSubmit}>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>First Name</label>
                    <input
                      type='text'
                      name='firstname'
                      value={firstname}
                      onChange={(e) => setfirstname(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.firstname && <p className='text-red-500 text-sm'>{errors.firstname}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Last Name</label>
                    <input
                      type='text'
                      name='lastname'
                      value={lastname}
                      onChange={(e) => setlastname(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.lastname && <p className='text-red-500 text-sm'>{errors.lastname}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Phone Number</label>
                    <PhoneInput
                      country={'in'}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass='custom-phone-input2'
                      buttonClass='border rounded'
                      dropdownClass='border rounded'
                      required
                    />
                    {errors.phoneNumber && <p className='text-red-500 text-sm'>{errors.phoneNumber}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className='text-gray-700'>Company Name</label>
                    <input
                      type='text'
                      name='companyName'
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <label className=' text-gray-700'>Business Email</label>
                    <input
                      type='email'
                      name='businessemail'
                      value={businessemail}
                      onChange={(e) => setEmailId(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.businessemail && <p className='text-red-500 text-sm'>{errors.businessemail}</p>}
                  </div>
                
              

                  <div className='mb-4'>
                    <label className=' text-gray-700'>Project Details</label>
                    <input
                      type='text'
                      name='projectdetails'
                      value={projectdetails}
                      onChange={(e) => setprojectdetails(e.target.value)}
                      className='w-full px-3 py-2 border rounded'
                      required
                    />
                    {errors.companyName && <p className='text-red-500 text-sm'>{errors.companyName}</p>}
                  </div>
                  <div className='mb-4'>
                    <button type='submit' className='w-full px-4 py-2 bg-[#C62B6D] text-white rounded'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {isSuccessOpen && (
            <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center'>
              <div className='relative bg-white p-8 rounded-lg max-w-md w-full text-center'>
                <button
                  className='absolute top-2 right-2 text-gray-500 hover:text-gray-700'
                  onClick={closeSuccessModal}
                >
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
                  </svg>
                </button>
                <h2 className='text-2xl font-bold mb-4'>Success!</h2>
                <p className='mb-4'>Your request has been sent successfully.</p>
                <button className='px-4 py-2 bg-[#C62B6D] text-white rounded' onClick={closeSuccessModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
    
    </>



  );
};
export default Popup;