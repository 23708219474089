import React from 'react';
import dashboardImgMob from '../assets/first-mob.png';
import dashboardImg from '../assets/first.svg';
import pattern from '../assets/pattern.svg';
import { isMobile } from '../config.tsx';
import styles from '../styles/SectionTwo.module.css';

const SectionTwo = ({ onOpenLeadForm }) => {
  const renderOnMobile = isMobile();
  return (
    <section className={styles.section}>
      <h1 className={styles.heading}>
        <span className={styles.highlight}>AI Powered</span> <br />
        Brand Sponsorship Analysis
      </h1>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img className={styles.pattern} src={pattern} alt='Pattern' />
          <img className={styles.dashboard} src={renderOnMobile ? dashboardImgMob : dashboardImg} alt='Dashboard' />
        </div>
        <div className={styles.features}>
          {/* <img className={styles.particle} src={particle1} alt="Particle1" />
          <img className={styles.particle} src={particle2} alt="Particle2" /> */}
          <ol>
            {/* <li>AI Powered brand sponsorship analysis</li> */}
            <li>Innovative AI-driven Solutions for Brand Managers and Sports Event Sponsors</li>
            <li>Cutting-Edge Technology for Real-Time Logo Detection and Analysis</li>
            <li>Comprehensive Metrics and Actionable Insights for Informed Decision-Making</li>
          </ol>
          <button onClick={onOpenLeadForm} className={styles.button}>
            See How It Works
          </button>
        </div>
      </div>
      {/* <div id="testing" style={{
        transform: "translateY(-100%)",
      }}></div> */}
    </section>
  );
};

export default SectionTwo;
